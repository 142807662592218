import React, { useEffect, useState } from "react";
import { PaymentContext } from "./PaymentContext";
import { Hub } from "aws-amplify/utils";
import { useCheckClientInviteAllowance } from "hooks/useCheckClientInviteAllowance";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
import { getCurrentUser } from "aws-amplify/auth";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

export const PaymentProvider = ({ children }) => {
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const { checkClientInviteAllowance } = useCheckClientInviteAllowance();
  const [user, setUser] = useState({});

  const fetchUser = async () => {
    try {
      const userObj = await getCurrentUser();
      setUser(userObj);
    } catch (error) {
      console.log("Error fetching current user:", error);
    }
  };

  useEffect(() => {
    if (!user || !user.userId) {
      fetchUser();
    }
  }, [user]);

  const fetchUserAttributes = async (userID) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-user-attributes`,
        {
          params: { user_id: userID },
        }
      );

      const userAttributesResponse = response.data;

      if (!userAttributesResponse) {
        return;
      }

      const {
        data: userAttributes,
        companyInformation,
        teams,
        myTeams,
      } = userAttributesResponse;

      console.log("user attributes: ", userAttributesResponse);

      // Check if the user is a client with inactive coach
      if (userAttributes?.access_to === "client_portal") {
        const isCoachActiveResponse = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-coach-status`,
          {
            params: {
              user_id: userID,
            },
          }
        );

        const coachStatuses = isCoachActiveResponse.data;
        const hasInactiveCoach = coachStatuses.some((coach) => !coach.is_coach);
        setUserData((prevData) => ({
          ...prevData,
          clientHasInactiveCoach: hasInactiveCoach,
        }));
      }

      setUserData((prevData) => ({
        ...prevData,
        accessTo: userAttributes?.access_to || null,
        isVirtualCoach: userAttributes?.is_virtual_coach || null,
        isClient: userAttributes?.is_client || null,
        isCoach: userAttributes?.is_coach || null,
        isProfessional: userAttributes?.is_professional || null,
        coachingTier: userAttributes?.coaching_tier || null,
        companyInformation: companyInformation || null,
        teamsInformation: teams || null,
        myTeamsInformation: myTeams || null,
      }));

      console.log("user data in PaymentProvider: ", userData);

      return userAttributesResponse;
    } catch (error) {
      console.error("Error fetching user attributes:", error);
    }
  };

  const fetchPayments = async () => {
    try {
      const userObj = await getCurrentUser();
      const userId = userObj.userId;
      const userAttributesResponse = await fetchUserAttributes(userId);

      const {
        companyInformation,
        teams,
        myTeams,
        data: userAttributes,
      } = userAttributesResponse || {};

      let paymentUserId = userId; // Default to current user's ID

      // If user is associated with a company, use the owner's user_id to fetch payments
      if (
        companyInformation &&
        companyInformation.owner &&
        companyInformation.owner.user_id
      ) {
        paymentUserId = companyInformation.owner.user_id;
      }

      console.log("Payment user ID: ", paymentUserId);
      console.log("Company info in fetch payments: ", companyInformation);
      console.log("Teams info in fetch payments: ", teams);
      console.log("My team info in fetch payments: ", myTeams);

      const { data: payments, errors } =
        await resourceClient.models.payments.list({
          filter: {
            user_id: {
              eq: paymentUserId,
            },
          },
          limit: 1000,
          selectionSet: [
            "stripe_customer_id",
            "user_id",
            "coaching_platform",
            "coaching_platform_tier",
            "customer",
            "virtual_coach",
          ],
        });

      console.log("payments: ", payments);

      const clientCounter = await checkClientInviteAllowance(userId);

      if (payments && payments[0]) {
        const payment = payments[0];
        console.log("Payment info: ", payment);
        setUserData((prevData) => ({
          ...prevData,
          paymentInfo: {
            stripeCustomerId: payment?.stripe_customer_id || null,
            allowAccessToPlatform:
              (payment?.coaching_platform && payment?.customer) || null,
            allowAccessToBot:
              (payment?.virtual_coach && payment?.customer) || null,
            isCustomer: payment?.customer || null,
            coachingPlatformTier: payment?.coaching_platform_tier || null,
          },
          isProfessionalTier:
            payment?.coaching_platform_tier === "personal" || null,
          isCoachLevel1: payment?.coaching_platform_tier === "tier1" || null,
          isCoachLevel2: payment?.coaching_platform_tier === "tier2" || null,
          isCoachLevel3: payment?.coaching_platform_tier === "tier3" || null,
          isClientCountLimitReached: clientCounter
            ? clientCounter?.clientCount > clientCounter?.clientTierAllowance
            : false,
        }));
      } else {
        setUserData((prevData) => ({
          ...prevData,
          paymentInfo: {
            allowAccessToBot: false,
            allowAccessToPlatform: false,
          },
          isProfessionalTier: false,
        }));
      }

      console.log("Updated user data in PaymentProvider: ", userData);
    } catch (error) {
      console.log("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    if (user && user.userId) {
      fetchPayments();
    }
  }, [user]);

  useEffect(() => {
    if (
      user &&
      (location.pathname === "/platform/coach" ||
        location.pathname === "/platform/coachee" ||
        location.pathname === "/platform/coach/leadership-center" ||
        location.pathname === "/platform/enterprise/admin-portal" ||
        location.pathname === "/virtualcoach/bot")
    ) {
      fetchPayments();
    }
  }, [user, location.pathname]);

  useEffect(() => {
    const hubListener = (data) => {
      if (
        data.payload.event === "signIn" ||
        data.payload.event === "signInWithRedirect"
      ) {
        fetchPayments();
      } else if (data.payload.event === "signOut") {
        // Clear the context or set to default values
        setUserData({});
      }
    };

    const removeListener = Hub.listen("auth", hubListener);

    return () => {
      // Clean up the listener by calling the returned function
      removeListener();
    };
  }, []);

  return (
    <PaymentContext.Provider value={{ userData }}>
      {children}
    </PaymentContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box } from "@mui/material";
import Lottie from "react-lottie";
import * as animationData from "data/lottieAnimation.json";
import ProductChoose from "components/ProductChoose";
import FailedPayment from "components/FailedPayment";
import { generateClient } from "aws-amplify/data";

const resourceClient = generateClient({ authMode: "userPool" });

const AuthenticatingLoader = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [clientHasInactiveCoach, setClientHasInactiveCoach] = useState(false);
  const [isCustomer, setIsCustomer] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (!user || !user.sub) return;

    console.log("reached here");

    const fetchUserAttributes = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-user-attributes`,
        {
          params: { user_id: user.sub },
        }
      );

      console.log("response data for user attributes: ", response);
      return response.data;
    };

    const fetchPaymentsCall = async (userSub) => {
      try {
        console.log("user id in fetch payments: ", userSub);

        const { data: response, errors } =
          await resourceClient.models.payments.list({
            filter: {
              user_id: {
                eq: userSub,
              },
            },
            limit: 1000,
            selectionSet: [
              "stripe_customer_id",
              "user_id",
              "coaching_platform",
              "coaching_platform_tier",
              "customer",
              "virtual_coach",
            ],
          });

        if (errors) {
          console.error("Errors encountered while fetching payments: ", errors);
          return null;
        }

        console.log("response for payments: ", response);
        return response;
      } catch (error) {
        console.error("Error fetching payments:", error);
        return null;
      }
    };

    const checkClientCoachStatus = async (userSub) => {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-coach-status`,
        {
          params: { user_id: userSub },
        }
      );
      console.log("check client coach status: ", response);
      return response.some((coach) => !coach.is_coach); //change this to when all of the coaches are inactive
    };

    const trackLogin = async (userSub) => {
      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_TRACK_LOGIN}`,
        {
          userID: userSub,
          loginTime: new Date().toISOString(),
        }
      );
    };

    const checkUserAccess = async () => {
      setIsLoading(true);
      try {
        const userSub = user.sub;
        console.log("user sub: ", userSub);
        await trackLogin(userSub);
        const { data: userAttributes, companyInformation } = await fetchUserAttributes();
        setUserData(userAttributes);

        let ownerPayments = null;
        if (companyInformation && companyInformation.owner) {
          // Fetch payments using the owner's user_id
          ownerPayments = await fetchPaymentsCall(companyInformation.owner.user_id);
        }
        console.log("ownerPayments: ", ownerPayments);

        const userPayments = await fetchPaymentsCall(user.sub);
        console.log("userPayments: ", userPayments);

        // Determine access based on user attributes and payments
        if (userAttributes.is_client && userAttributes.access_to === 'client_portal') {
          if (await checkClientCoachStatus(userSub)) {
            setClientHasInactiveCoach(true);
            setIsLoading(false);
            return;
          }
          navigate("/platform/coachee");
          return;
        }

        if (
          userAttributes.access_to === "platform_professional" ||
          userAttributes.access_to === "platform"
        ) {
          let paymentObj = null;
          if (ownerPayments && ownerPayments.length > 0 && ownerPayments[0].customer) {
            paymentObj = ownerPayments[0];
          } else if (userPayments && userPayments.length > 0 && userPayments[0].customer) {
            paymentObj = userPayments[0];
          }

          if (paymentObj) {
            navigate("/platform/coach");
          } else {
            console.log("No valid payment found.");
            setIsCustomer(false);
            setIsLoading(false);
          }
          return;
        }

        if (userPayments && userPayments.length > 0) {
          const payment = userPayments[0];
          setStripeCustomerId(payment.stripe_customer_id || null);
          if (payment.coaching_platform && payment.customer) {
            navigate("/platform/coach");
          } else if (payment.virtual_coach && payment.customer) {
            navigate("/virtualcoach/bot");
          } else {
            setIsCustomer(payment.customer);
            setIsLoading(false);
          }
        } else {
          setIsCustomer(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Failed to check user access or track login:", error);
        setIsCustomer(false);
        setIsLoading(false);
      }
    };

    checkUserAccess();
  }, [user, navigate]);

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#EFF3F6",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "black",
            textAlign: "center",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
            width="50%"
            height="auto"
          />
        </Box>
      </Box>
    );
  }

  return user && !isCustomer && stripeCustomerId ? (
    <FailedPayment
      user={user}
      stripeCustomerId={stripeCustomerId}
      userData={userData}
    />
  ) : (
    <ProductChoose clientHasInactiveCoach={clientHasInactiveCoach} />
  );
};

export default AuthenticatingLoader;

import { DataGrid } from '@mui/x-data-grid';
import { styled } from "@mui/material/styles";

const UsersTable = styled(DataGrid)(({ theme }) => ({
    border: 'none', // Remove the outer border
    boxShadow: 'none', // Remove any shadow
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: 'none',
      borderBottom: '1px solid #F3F3F3', // Add bottom border for column headers
      color: '#B6B6B6',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none', // Remove the border between cells
      color: 'black',
      outline: 'none', // Remove focus outline from cells
      '&:focus': {
        outline: 'none', // Ensure the cell does not show outline when focused
      },
    },
    '& .MuiDataGrid-row': {
      backgroundColor: 'white',
      borderBottom: '1px solid #F3F3F3', // Add a divider between rows
      '&.even': {
        backgroundColor: "transparent", // Background color for even rows
      },
      '&.odd': {
        backgroundColor: theme.palette.grey[100], // Background color for odd rows
      },
      "&:hover": {
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        zIndex: 1,
        color: "#202226",
        cursor: "pointer",
      },
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: 'none', // Remove footer top border
      boxShadow: 'none', // Remove footer shadow
    },
    '& .MuiTablePagination-root': {
      border: 'none', // Remove the pagination border
      color: 'black', // Set the pagination text color to black
      '& .MuiTablePagination-caption': {
        color: 'black', // Customize the text that shows "Rows per page:" and the range
      },
      '& .MuiSelect-select': {
        color: 'black', // Change the color of the select element for page size
      },
      '& .MuiTablePagination-displayedRows': {
        color: 'black', // Customize the displayed rows text, like "1–5 of 100"
      },
    },
    '& .MuiDataGrid-virtualScroller': {
      borderRadius: 0, // Ensure no border radius
    },
    "& .MuiCheckbox-root": {
      display: "none",
    },
    // Remove focus outline from column headers
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none', // Remove focus outline from column headers
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none', // Remove focus outline from column headers on interaction
    },
    '& .MuiIconButton-root': {
      color: 'black', // Set the color of the icons to black
      '&:hover': {
        color: '#B6B6B6', // Optional hover color for icons
      },
    },
    // Customize the overlay for loading and no rows available
    '& .MuiDataGrid-overlay': {
      backgroundColor: 'transparent', // Make the overlay transparent
    },
    '& .MuiDataGrid-noRowsOverlay': {
      backgroundColor: 'transparent', // Make the no rows available overlay transparent
      color: 'black', // Change the text color
    },
    '& .MuiDataGrid-loadingOverlay': {
      backgroundColor: 'transparent', // Make the loading overlay transparent
    },
    // Remove focus outline from checkboxes
    '& .MuiDataGrid-checkboxInput': {
      '&:focus': {
        outline: 'none', // Remove focus outline from the checkbox
        boxShadow: 'none', // Remove the box shadow effect on focus
      },
    },
    '& .MuiCheckbox-root': {
      '&:focus': {
        outline: 'none', // Remove focus outline on the root checkbox
        boxShadow: 'none', // Remove box shadow effect on focus
      },
    },
}));

export default UsersTable;

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TheBigFiveQuestions from "./TheBigFiveQuestions";
import TheBigFiveChoices from "./TheBigFiveChoices";
import { generateClient } from "aws-amplify/data";
import useBigFiveAssessment from "hooks/assessmentHooks/theBigFiveQueries/useBigFiveAssessment";
import { processAnswers } from "./TheBigFiveScoring";  // Import the scoring function here
import { toast } from "react-toastify";
import { Check } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const TheBigFiveAssessment = ({ user, assessmentId, userData, teamId, coachId }) => {
    const { assessment, loading, error } = useBigFiveAssessment({ user, assessmentId, teamId, coachId });
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [responses, setResponses] = useState([]);
    const [hasAnswered, setHasAnswered] = useState(false);
    const [loadingFinish, setLoadingFinish] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [loadingAnalysis, setLoadingAnalysis] = useState(false);
    const [progress, setProgress] = useState(0)
    const navigate = useNavigate();

    const questions = TheBigFiveQuestions;

    const returnToAssessmentPage = () => {
        if(userData.accessTo === 'client_portal'){
            navigate("/platform/coachee/assessments");
        }
        else{
            navigate("/platform/coach/assessments");
        }
    }

    // useEffect(() => {
    //     if (assessmentId) {
    //         const subscription = resourceClient.subscriptions.analyzeAssessmentResultsCompleted({
    //           filter: {
    //             assessment_id: assessmentId,
    //           },
    //         }).subscribe({
    //           next: (event) => {
    //             const data = event.value.data.analyzeAssessmentResultsCompleted;
    //             toast.dismiss(); // Dismiss any ongoing toasts for progress
    //             toast.success("Assessment analysis complete!", {
    //               position: "top-right",
    //               autoClose: 5000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //             });
    //             console.log("Analysis complete: ", data);
    //             setLoadingAnalysis(false);
    //             setProgress(100); // Set progress to 100% on completion
    //           },
    //           error: (error) => {
    //             console.error('Error in subscription: ', error);
    //             toast.dismiss(); // Dismiss progress toasts
    //             toast.error("Error completing assessment analysis.", {
    //               position: "top-right",
    //               autoClose: 5000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //             });
    //             setLoadingAnalysis(false);
    //           },
    //         });
        
    //         return () => subscription.unsubscribe();
    //     }
    // }, [assessmentId]);

      // Start assessment analysis
    //   const startAnalysis = async () => {
    //     try {
    //         setLoadingAnalysis(true);
    //         setProgress(20);
    //         toast.info("Assessment analysis started!", {
    //             position: "top-right",
    //             autoClose: false,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });

    //         // Call the mutation to start the analysis
    //         const { data, errors } = await resourceClient.mutations.analyzeAssessmentResults({
    //             assessment_id: assessmentId,
    //         });

    //         if (errors) {
    //             console.error('Error starting analysis: ', errors);
    //             setLoadingAnalysis(false);
    //             toast.dismiss(); // Dismiss the progress toast
    //             toast.error("Error starting assessment analysis.", {
    //               position: "top-right",
    //               autoClose: 5000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //             });
    //         } else {
    //             // Update progress and wait for the subscription
    //             setProgress(50);
    //             console.log('Analysis started: ', data);
    //         }
    //     } catch (error) {
    //         console.error('Error starting analysis: ', error);
    //         setLoadingAnalysis(false);
    //         toast.dismiss();
    //         toast.error("Unexpected error while starting analysis.", {
    //           position: "top-right",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });
    //     }
    // };
    



    // Load saved responses and resume from the last unanswered question
    useEffect(() => {
        if (assessment?.responses && typeof assessment.responses === "string") {
            const parsedResponses = JSON.parse(assessment.responses);
            setResponses(parsedResponses);

            // Set the current question index to the last unanswered question
            const lastUnansweredIndex = parsedResponses.length;

            if (lastUnansweredIndex < questions.length) {
                setCurrentQuestionIndex(lastUnansweredIndex); // Resume from the last unanswered question
            } else {
                setIsCompleted(true);  // Mark as completed if all questions are answered
            }

            if (parsedResponses[lastUnansweredIndex - 1]) {
                setHasAnswered(true);  // If the last question is answered, enable the button
            }
        }
    }, [assessment, questions.length]);

    // Track responses and whether the current question has been answered
    useEffect(() => {
        const currentAnswer = responses.find(response => response.questionId === questions[currentQuestionIndex]?.id)?.score;
        setHasAnswered(currentAnswer !== undefined);  // Set hasAnswered based on whether there's an answer
    }, [currentQuestionIndex, responses, questions]);

    // Handle user response
    const handleResponse = (choice) => {
        const question = questions[currentQuestionIndex];

        // Create or update the response for the current question
        const newResponses = [...responses];
        newResponses[currentQuestionIndex] = {
            questionId: question.id,
            domain: question.domain,
            facet: question.facet,
            score: choice,
        };

        setResponses(newResponses);  // Update local state
        saveProgress(newResponses);  // Save progress to the backend

        // Move to the next question immediately (optional)
        if (currentQuestionIndex < questions.length - 1) {
            moveToNextQuestion();
        }
    };

    
    

    // Save progress function (save responses as valid JSON)
    const saveProgress = async (formattedResponses) => {
        if (assessment) {
            try {
                // Save the responses as a JSON string
                const { data, errors } = await resourceClient.models.assessments.update({
                    id: assessment.id,
                    responses: JSON.stringify(formattedResponses), // Ensure responses are passed as a valid JSON string
                });
                if (errors) {
                    console.error("Error saving progress:", errors);
                } else {
                    console.log("Progress saved:", data);
                }
            } catch (error) {
                console.error("Error saving data:", error);
            }
        }
    };
    



    const completeAssessment = async (finalResponses) => {
        if (assessment) {
            try {
                // Process the answers and calculate results
                const processedResults = processAnswers(finalResponses); // Trigger scoring logic here
                setLoadingFinish(true);
                setIsCompleted(true);
                toast(`Assessment completed! Click back to view your results. An analysis will be available shortly.`, {
                    hideProgressBar: true,
                    style: {
                      background: "white",
                      boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                      borderRadius: "8px",
                      padding: "16px 28px",
                      color: "#160042",
                    },
                    icon: () => <Check color="success" />,
                  });
                // Save both responses and processed results in the assessment
                const { data, errors } = await resourceClient.models.assessments.update({
                    id: assessment.id,
                    responses: JSON.stringify(finalResponses),     // Save the final responses
                    results: JSON.stringify(processedResults),     // Save the calculated results
                    completed: true,
                    updatedAt: new Date().toISOString(),
                });
                console.log("Assessment completed!", data, errors);

                // startAnalysis();
                const { data: analysisResult, errors: analysisErrors } = await resourceClient.mutations.analyzeAssessmentResults({
                    assessment_id: assessment.id,
                });
                
            } catch (error) {
                console.error("Error completing assessment:", error);
            }finally{
                setLoadingFinish(false);
            }
        }
    };
    

    // Reset hasAnswered when moving to a new question
    const moveToNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            
            // Check if the next question has already been answered and update hasAnswered accordingly
            const nextAnswer = responses[currentQuestionIndex + 1]?.score;
            setHasAnswered(!!nextAnswer); // If there's an answer, set hasAnswered to true
        } else {
            completeAssessment(responses); // If it's the last question, complete the assessment
        }
    };

    const moveToPreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            
            // Check if the previous question has already been answered and update hasAnswered accordingly
            const previousAnswer = responses[currentQuestionIndex - 1]?.score;
            setHasAnswered(!!previousAnswer); // If there's an answer, set hasAnswered to true
        }
    };

    // Ensure hasAnswered is set correctly when navigating between questions
    useEffect(() => {
        if(!questions[currentQuestionIndex])
            return;
        const currentAnswer = responses.find(response => response.questionId === questions[currentQuestionIndex].id)?.score;
        setHasAnswered(!!currentAnswer); // If the current question has a recorded answer, set hasAnswered to true
    }, [currentQuestionIndex, responses]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                <CircularProgress sx={{ color: "black" }}/>
            </Box>
        );
    }

    if (!questions[currentQuestionIndex]) {
        return (
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                {isCompleted ? (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", textAlign: "center", alignItems: "center", width: "60%", height: "80%" }}>
                        <Typography sx={{ color: "black", fontSize: "46px", fontFamily: "Poppins", fontWeight: 400 }}>
                            Congratulations, you answered all the questions successfully!
                        </Typography>
                        <Box 
                            sx={{ borderRadius: "50%", width: 200, height: 200, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#0076BA" }}
                        >
                            <Check sx={{ color: "white", fontSize: 120, fontWeight: 600 }}/>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", textAlign: "center", alignItems: "center" }}>
                            <Button 
                                sx={{ background: 'white', borderRadius: 40, border: "1px solid black", padding: "10px", width: "40%" }}
                                onClick={returnToAssessmentPage}
                            >
                                <Typography sx={{ color: 'black', fontSize: "12px", fontFamily: 'Lexend', fontWeight: 700, textTransform: 'uppercase', letterSpacing: 2.46, wordWrap: 'break-word' }}>
                                    Go Back
                                </Typography>
                            </Button>
                            {/* <Typography sx={{ color: "#8F8F8F", fontSize: "18px", fontFamily: "Lexend", fontWeight: 400 }}>
                                You will receive an email when your results are available!
                            </Typography> */}
                        </Box>
                    </Box>
                ) : (
                    <Typography sx={{ color: "black" }}>No more questions available.</Typography>
                )}
            </Box>
        );
    }

    if (error) {
        return <Typography sx={{color: "black"}}>Error loading assessment: {error.message}</Typography>;
    }

    const currentQuestion = questions[currentQuestionIndex];
    const currentChoices = TheBigFiveChoices[currentQuestion.keyed];

    // Check if the current question has already been answered
    const currentAnswer = responses.find(response => response.questionId === questions[currentQuestionIndex].id)?.score;

    const getChoiceText = (text) => {
        let displayText;
        switch(text){
            case 'Very Inaccurate':
                displayText = 'Disagree';
                break;
            case 'Moderately Inaccurate':
                displayText = 'Slightly Disagree';
                break;
            case 'Neither Accurate Nor Inaccurate':
                displayText = 'Neutral';
                break;
            case 'Moderately Accurate':
                displayText = 'Slightly Agree';
                break;
            case 'Very Accurate':
                displayText = 'Agree';
                break;
            default:
                displayText = text;
        }
        return displayText;
    };

    return (
        <Box 
            sx={{ 
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between", // Ensures space between top (title/question) and bottom (navigation)
                alignItems: "center", 
                width: "70%",
                height: "100%", // Full viewport height to keep things centered
                margin: "0 auto", 
                textAlign: "center", 
            }}
        >
            {!isCompleted ? (
                <>
                    {/* Title and Question */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%", // Takes full width
                            flex: "0 1 auto", // Ensures it takes only the space it needs
                        }}
                    >
                        <Typography
                            sx={{
                                color: "black",
                                fontSize: "28px",
                                fontWeight: 300,
                                fontFamily: "Poppins"                            
                            }}
                        >
                            The Big Five Personality Test
                        </Typography>

                        <Typography sx={{ fontSize: "26px", color: "#8C8C8C", fontFamily: "Poppins", fontWeight: 300 }}>
                            {`Question ${currentQuestionIndex + 1}/${questions.length}`}
                        </Typography>

                        {/* Display Current Question */}
                        <Typography variant="h6" sx={{ fontSize: "36px", color: "black", fontFamily: "Poppins", fontWeight: 400, marginTop: "40px" }}>
                            {currentQuestion.text}
                        </Typography>
                    </Box>

                    {/* Display Choices */}
                    <Box 
                        sx={{ 
                            display: "flex", 
                            flexDirection: "row", 
                            width: "100%",
                            justifyContent: "space-between", 
                            alignItems: "center", 
                            flex: "1", // This makes it take up the available space in the middle
                        }}
                    >
                        {currentChoices.map((choice, index) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    gap: "15px"
                                }}
                            >
                                <Button
                                    key={index}
                                    onClick={() => handleResponse(choice.score)}
                                    sx={{ 
                                        background: currentAnswer === choice.score ? "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)" : "#F1F1F1", 
                                        color: currentAnswer === choice.score ? "white" : "black", 
                                        borderRadius: "50%", // Make the button circular
                                        width: 120, // Set the width of the button
                                        height: 120, // Set the height to the same as the width
                                        display: "flex",
                                        justifyContent: "center", 
                                        alignItems: "center", // Center the content inside the button
                                        "&:hover": {
                                            background: "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                                            color: "white"
                                        }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 46,
                                            fontFamily: "Poppins",
                                            fontWeight: "400",
                                        }}
                                    >
                                        {index + 1}
                                    </Typography>
                                </Button>
                                <Typography
                                    sx={{
                                        color: "#8C8C8C",
                                        fontSize: "20px",

                                    }}
                                >
                                    {getChoiceText(choice.text)}
                                </Typography>
                            </Box>
                        ))}
                    </Box>

                    {/* Navigation */}
                    <Box 
                        sx={{ 
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            width: "100%", 
                            flex: "0 1 auto", // Ensures it takes only the space it needs at the bottom,
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center"
                        }}
                    >
                        <Box
                            sx={{
                                width: "30%",
                                display: "flex",
                                gap: "12px",
                                flexDirection: "column"
                            }}
                        >
                            <Button
                                sx={{ background: "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)", borderRadius: "40px", border: "1px solid black", color: "white", 
                                        "&:disabled": {
                                            background: "white", 
                                            border: "1px solid #E3E3E3",
                                            color: "#E3E3E3",
                                            cursor: "not-allowed",
                                        }
                                }}
                                disabled={!hasAnswered || loadingFinish}
                                onClick={moveToNextQuestion}
                            >
                                <Typography sx={{ fontSize: "12px", fontFamily: "Lexend", fontWeight: 700 }}>
                                    {loadingFinish ? (
                                        <CircularProgress size={20} sx={{ color: "black" }} />
                                    ) : (
                                        currentQuestionIndex < questions.length - 1 ? "Next" : "Finish"
                                    )}
                                </Typography>
                            </Button>
                            <Button
                                sx={{ background: "white", borderRadius: "40px", border: "1px solid black", color: "black", 
                                    "&:disabled": {
                                        background: "white", 
                                        border: "1px solid #E3E3E3",
                                        color: "#E3E3E3",
                                        cursor: "not-allowed",
                                    }
                                }}
                                disabled={currentQuestionIndex === 0}
                                onClick={moveToPreviousQuestion}
                            >
                                <Typography sx={{fontSize: "12px", fontFamily: "Lexend", fontWeight: 700}}>
                                    Go Back
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", textAlign: "center", alignItems: "center", width: "70%", height: "100%" }}>
                        <Typography sx={{ color: "black", fontSize: "46px", fontFamily: "Poppins", fontWeight: 400 }}>
                            Congratulations, you answered all the questions successfully!
                        </Typography>
                        <Box 
                            sx={{ borderRadius: "50%", width: 200, height: 200, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#0076BA" }}
                        >
                            <Check sx={{ color: "white", fontSize: 120, fontWeight: 600 }}/>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", textAlign: "center", alignItems: "center", width: "100%" }}>
                            <Button 
                                sx={{ background: 'white', borderRadius: 40, border: "1px solid black", padding: "10px", width: "40%" }}
                                onClick={returnToAssessmentPage}
                            >
                                <Typography sx={{ color: 'black', fontSize: "12px", fontFamily: 'Lexend', fontWeight: 700, textTransform: 'uppercase', letterSpacing: 2.46, wordWrap: 'break-word' }}>
                                    Go Back
                                </Typography>
                            </Button>
                            {/* <Typography sx={{ color: "#8F8F8F", fontSize: "18px", fontFamily: "Lexend", fontWeight: 400 }}>
                                You will receive an email when your results are available!
                            </Typography> */}
                        </Box>
                </Box>
            )}
        </Box>
    );
};

export default TheBigFiveAssessment;
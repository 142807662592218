import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Modal, Typography } from "@mui/material";
import Team from "scenes/team";
import { useDispatch, useSelector } from "react-redux";
import { getCallsByCoachID } from "slices/CoachSlice";
import { getCallsByClientID } from "slices/ClientSlice";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { toDateFormat } from "helpers/DateHelper";
import WelcomeCard from "components/CoachDashboardCards/WelcomeCard";
import ClientCard from "components/CoachDashboardCards/ClientCard";
import VirtualCoachCard from "components/CoachDashboardCards/VirtualCoachCard";
import ZoomCenterModal from "components/ClientDashboardCards/ZoomCenterModal";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import ClientLimitReached from "components/ClientLimitReachedModal";
import ReturnFromZoomModal from "components/CoachDashboardCards/ReturnFromZoomModal";
import MetricCards from "components/ClientDashboardCards/MetricCards";
import axios from "axios";
import {
  handleStripeRedirect,
  updateUserStateAfterStripe,
} from "utils/stripeRedirect";
import { fetchAuthSession } from "aws-amplify/auth";
import * as Frigade from "@frigade/react";

const CoachDashboard = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [loading, setLoading] = useState(true);
  const [zoomStatus, setZoomStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const coachCallData = useSelector((state) => state.coach.data);
  const [lastFileName, setLastFileName] = useState("");
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [client, setClient] = useState([]);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [unprocessedCallsSum, setUnprocessedCallsSum] = useState(0);
  const [unassignedCallsSum, setUnassignedCallsSum] = useState(0);
  const [assignedCallsSum, setAssignedCallsSum] = useState(0);
  const [totalActionsSum, setTotalActionsSum] = useState(0);
  const clientID = process.env.REACT_APP_CLIENT_ID;
  const redirectURL = "https://apizoom.altercall.ai/zoom";

  const refreshUserSession = async () => {
    try {
      await fetchAuthSession({ forceRefresh: true });
      console.log("Session refreshed successfully");
      window.location.replace("/platform/coach");
    } catch (error) {
      console.error("Error refreshing session:", error);
    }
  };

  useEffect(() => {
    // Call the utility function to handle the Stripe redirect
    handleStripeRedirect(() => updateUserStateAfterStripe(refreshUserSession));
  }, []);

  const handleOAuthRedirect = async () => {
    try {
      const userId = user?.sub;
      const stateParam = encodeURIComponent(userId);
      const zoomOAuthURL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientID}&redirect_uri=${encodeURIComponent(
        redirectURL
      )}&state=${stateParam}`;
      window.location.href = zoomOAuthURL;
    } catch (error) {
      console.error("Error fetching user ID or redirecting:", error);
    }
  };

  const fetchSum = async () => {
    try {
      const {data: response} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-coach-sum`, {
        params: {
          coach_id: user?.sub,
        },
      });

      if (!response || !response.data) {
        console.warn("No data received from API");
        return;
      }

      setUnprocessedCallsSum(response.data.unprocessed_sum);
      setUnassignedCallsSum(response.data.unassigned_sum);
      setAssignedCallsSum(response.data.assigned_sum);
      setTotalActionsSum(response.data.actions_sum);
    } catch (error) {
      console.error("Error fetching sum data:", error);
    }
  };

  console.log("exececute")

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const isCoachee = user?.["custom:is_coachee"] === "true";
      let isCompanyTier = userData?.companyInformation ?? false;
      let response;
      
      try {
          response = await dispatch(getCallsByCoachID({ coachId: user?.sub })).unwrap();
          response = response.data
      } catch (error) {
        console.error("Error fetching calls:", error);
        response = []; // Provide an empty array as a fallback response in case of error
      }
      
      
      
        const {data: zoomCheckResponse} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/check-zoom`, {
          params: {
            coach_id: user?.sub,
          },
        }
      );
      console.log("zoom check: ", zoomCheckResponse);
      if (zoomCheckResponse) {
        let zoomStatus =
          zoomCheckResponse.data === undefined ||
          zoomCheckResponse?.data[0].zoom_access_token === null;
        setZoomStatus(!zoomStatus);
      }

      // Check if the response contains data and is an array
      if (response) {
        const sanitizedData = response?.map((row) => ({
          ...row,
          call_name: row.call_name.replace(/_/g, " "), // Replace underscores with spaces
          zoom_date: row.zoom_date
            ? new Date(row.zoom_date).toLocaleDateString("en", {
                weekday: "short",
                month: "short",
                day: "numeric",
                year: "numeric",
              })
            : "",
          file_name: row.file_name, // Include the "file_name" from the response
          id: row.id,
        }));
        const sortedData = sanitizedData?.sort(
          (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
        );
        for (const row of sortedData) {
          if (row.status === "ReadyToPublish" || "assigned") {
            setLastFileName(row.file_name);
            break;
          }
        }

        if (!response) {
          setLoading(false);
          return;
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setClient([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchClientData = async () => {
    // If the user does not have access to the client portal, exit early
    if (userData.accessTo === "client_portal") return;

    try {
      const userId = user?.sub;
      let response = null;
  
      if (userData?.companyInformation) {
        switch (user?.role) {
          case "OWNER":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`, {
              params: { userId: userId },
            });
            break;
          case "SUPERADMINS":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/super-admin-fetch-company-members`, {
              params: { userId: userId, userGroup: user?.role },
            });
            break;
          case "ADMINS":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/admin-fetch-company-members`, {
              params: { userId: userId, userGroup: user?.role }
            });
            break;
          case "USERS":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`, {
              params: { userId: userId, userGroup: user?.role },
            });
            break;
          case "PRIVATE":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/private-fetch-company-members`, {
              params: { userId: userId, userGroup: user?.role, companyId: userData?.companyInformation?.company_id},
            });
            break;
          default:
            break;
        }
      } else {
        response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-clients`, {
          params: { coach_id: userId },
        });
      }

      const responseData = response.data;
      console.log("res data: ", responseData)
      if (Array.isArray(responseData.members)) {
        const processedClientData = responseData.members
          .filter((item) => item.user_id !== user?.sub)
          .map((item, index) => ({
            ...item,
            id: index + 1,
            isClient: true,
            zoom_date: toDateFormat(item.zoom_date),
            avatar: item.avatar ? `https://d7joen7tb0xwx.cloudfront.net/public/${item.avatar}` : null,
          }));
        const sortedClientData = processedClientData.sort(
          (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
        );
        setClient(sortedClientData);
      } else if (typeof responseData.members === "object" && responseData.members !== null) {
        // If client_data is an object, process it similarly
        const processedClientData = {
          ...responseData.members,
          id: 1,
          isClient: true,
          zoom_date: toDateFormat(responseData.members.zoom_date),
          avatar: responseData.client_data.avatar
            ? `https://d7joen7tb0xwx.cloudfront.net/public/${responseData.client_data.avatar}`
            : null,
        };
  
        // Exclude the current user if applicable
        if (processedClientData.user_id !== user?.sub) {
          setClient([processedClientData]); // Assuming you expect an array for `setClient`
        }
      } else {
        console.warn("Unexpected data format for client_data.");
        return;
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  useEffect(() => {
    fetchClientData();
    fetchUserData();
    fetchSum();
  }, [user]);

  const modal = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: { xs: "50%", lg: "60%", xl: "58%" },
          transform: "translate(-50%, -50%)",
          width: 500,
          background: "white",
          border: "2px solid white",
          boxShadow: 24,
          paddingX: "50px",
          paddingY: "30px",
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "20px",
          margin: "0 auto",
          maxWidth: "calc(100% - 40px)",
        }}
      >
        <Box>
          <Typography color="black" fontWeight="400">
            {`You will be redirected to Zoom and asked to allow our app connection.`}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            gap: { xs: "8px", md: "15px" },
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <DeclineButton onClick={handleClose} label={"Cancel"} />
          <ConfirmButton onClick={handleOAuthRedirect} label={"Connect"} />
        </Box>
      </Box>
    </Modal>
  );

  if (!zoomStatus && userData.isClient !== true) {
    return (
      <>
        <ClientLimitReached user={user} userData={userData} />
        <ZoomCenterModal user={user} loading={loading} modal={modal} setOpen={setOpen} collapsed={collapsed} userData={userData} />
        <Frigade.Checklist.Collapsible
          flowId="flow_HER5jgDw"
          style={{
            position: "fixed", // Makes the carousel fixed position relative to the viewport
            top: "50%", // Positions the top edge of the carousel at the center of the viewport
            left: "50%", // Positions the left edge of the carousel at the center of the viewport
            transform: "translate(-50%, -50%)", // Shifts the carousel back to truly center it
            maxWidth: "600px",
            maxHeight: "600px",
            width: "90%", // Responsive width, adjust as needed
            margin: "0", // Removes auto margin since it's now absolutely positioned
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)", // X=0, Y=5, Blur=15, and a subtle opacity for better effect
            border: "1px solid #ccc",
            borderRadius: "10px",
            overflow: "scroll",
            padding: "20px",
            zIndex: 1000, // High z-index to ensure it's on top of other content
            background: "white", // Ensures the content is readable by providing a solid background
            fontFamily: "Poppins",
          }}
        />
      </>
    );
  }

  return (
    <GlobalPageComponent user={user} accessTo={userData.accessTo}>
      <ClientLimitReached user={user} userData={userData} />
      <Box sx={{ backgroundColor: "#EFF3F6", width: "100%", padding: "16px", display: { xs: !collapsed ? "none" : "block" }, overflowY: "auto" }}>
        <Box display="flex" alignItems="center" gap="12px">
          <Typography sx={{ color: "#070728", fontSize: "36px", fontWeight: 300, paddingBottom: "16px" }}>
            Dashboard
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "8px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", md: "calc(100% / 3 * 2)" }, gap: "8px", flexGrow: 1 }}>
            <Box sx={{ display: "flex", gap: "8px", flexDirection: { xs: "column", md: "row" }, height: "100%" }}>
              {userData.accessTo === "platform_professional" && user?.["custom:is_coachee"] === "true" ? (
                <MetricCards user={user} accessTo={userData.accessTo} zoomStatus={zoomStatus} modal={modal} handleOpen={handleOpen} />
              ) : (
                <>
                  <WelcomeCard loading={loading} zoomStatus={zoomStatus} user={user} coachCallData={coachCallData} lastFileName={lastFileName} handleOpen={handleOpen} modal={modal} unprocessedCallsSum={unprocessedCallsSum} unassignedCallsSum={unassignedCallsSum} totalActionsSum={totalActionsSum} accessTo={userData.accessTo} />
                  <ClientCard loading={loading} zoomStatus={zoomStatus} handleOpen={handleOpen} modal={modal} client={client} assignedCallsSum={assignedCallsSum} totalActionsSum={totalActionsSum} />
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "calc(100% / 3)" }, flexGrow: 1 }}>
            <VirtualCoachCard user={user} />
          </Box>
        </Box>
        <Team user={user} userDataInfo={userData} fetchData={fetchUserData} isDashboard={true} isProfessionalTier={userData.accessTo === 'platform_professional'} fetchSum={fetchSum} accessTo={userData.accessTo} memberData={client} />
      </Box>
      <ReturnFromZoomModal open={openSuccessMessage} setOpen={setOpenSuccessMessage} />
    </GlobalPageComponent>
  );
};

export default CoachDashboard;

import { Box, Typography } from "@mui/material";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TheBigFiveAssessment from "components/Assessments/TheBigFive/TheBigFiveAssessment";

const AssessmentTestPage = ( {user, userData} ) => {
    const collapsed = useSelector((state) => state.layout.collapsed);
    const { assessmentType, assessmentId, coachId, teamId } = useParams();
    console.log("assessment id: ", assessmentId)
    console.log("coach id: ", coachId)
    console.log("user id: ", )

    console.log("url ", assessmentType);

    const renderAssessment = () => {
        switch (decodeURIComponent(assessmentType)) {
          case "The Big Five Personality Test":
            return <TheBigFiveAssessment user={user} userData={userData} assessmentId={assessmentId} coachId={coachId} teamId={teamId}/>;
          // Add more cases for other assessments
          default:
            return <Typography sx={{color: "black", fontSize: "16px"}}>Test not found</Typography>;
        }
      };


    return(
        <GlobalPageComponent user={user} accessTo={userData.accessTo} >
            <ClientLimitReached user={user} userData={userData} />
                <Box
                    sx={{
                    backgroundColor: "#EFF3F6",
                    width: "100%",
                    height: "100%",
                    padding: {
                        xs: "16px",
                        md: "32px 40px",
                    },
                    display: {
                        xs: !collapsed ? "none" : "block",
                    },
                    overflowY: "auto",
                    }}
                >
                     <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Typography
                            sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                            }}
                        >
                            Assessments
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            background: "white",
                            padding: "24px",
                            height: "88vh",
                            width: "100%",
                            borderRadius: "40px",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {renderAssessment()}
                    </Box>
                </Box>
        </GlobalPageComponent>
    )
}

export default AssessmentTestPage;
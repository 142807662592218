import React from "react";

function Actions({ color, size = 24 }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.0539 8.37812L11.8099 3.86588C13.4478 2.58187 14.2668 1.93986 14.8275 2.00331C15.3128 2.05823 15.7399 2.36901 15.9645 2.83071C16.224 3.36412 15.9608 4.41061 15.4345 6.5036L14.9936 8.25725C14.7829 9.09517 14.6775 9.51413 14.7501 9.87787C14.8139 10.1978 14.9711 10.488 15.1993 10.7069C15.4586 10.9557 15.8518 11.068 16.6381 11.2925L17.1483 11.4382C18.6605 11.87 19.4165 12.0858 19.7188 12.5253C19.9816 12.9075 20.0659 13.3968 19.9475 13.8534C19.8113 14.3786 19.1764 14.8665 17.9066 15.8422L12.2549 20.185C10.6271 21.4359 9.81313 22.0613 9.25523 21.9941C8.77222 21.936 8.34845 21.6245 8.12597 21.1639C7.869 20.632 8.12871 19.5991 8.64814 17.5334L9.04237 15.9656C9.25307 15.1277 9.35842 14.7087 9.28586 14.345C9.22204 14.025 9.0648 13.7348 8.83668 13.516C8.57735 13.2671 8.18418 13.1549 7.39784 12.9304L6.83013 12.7683C5.33385 12.341 4.58571 12.1274 4.28364 11.6909C4.02094 11.3112 3.93491 10.8246 4.04995 10.3691C4.18222 9.84537 4.80611 9.35629 6.0539 8.37812Z" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default Actions;

import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logoImg from "assets/logo.svg";
import logoMobile from "assets/logo-mobile.svg";

const Logo = ({ user, accessTo, routing }) => {
  const navigate = useNavigate();

  return (
    <Box
      // onClick={() => {
      //   if (user && accessTo) {
      //     if (accessTo === 'client_portal') {
      //       navigate("/platform/coachee");
      //     } else {
      //       navigate("/platform/coach");
      //     }
      //   } else {
      //     navigate("/");
      //   }
      // }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        // cursor: "pointer",
      }}
    >
      <Box
        component="img"
        src={logoImg}
        alt="logo"
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
          transform: "scale(0.6)",
        }}
      />
      <Box
        component="img"
        src={logoMobile}
        alt="mobile-logo"
        sx={{
          display: {
            xs: "flex",
            md: "none",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
            marginLeft: "10px",
          },
        }}
      ></Box>
    </Box>
  );
};

export default Logo;

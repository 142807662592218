import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  BorderColor,
  Check,
  Delete,
  ErrorOutline,
  KeyboardArrowDown,
  Undo,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { getFormattedDate } from "helpers/DateHelper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import avatar from "assets/avatar.png";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlaceIcon from "@mui/icons-material/Place";
import _ from "lodash";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "components/Icons/Edit.svg";
import MarkAsCompletedIcon from "components/Icons/MarkAsCompleted.svg";
import ActionCalendarIcon from "components/Icons/actioncalender.svg";
import DeleteIcon from "components/Icons/Delete.svg";
import ActionDeleteIcon from "components/Icons/ActionDelete.svg";

import PositiveActionButton from "components/PositiveActionButton";
import NegativeActionButton from "components/NegativeActionButton";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDecrementClientCount } from "hooks/useDecrementClientCount";
import { formatDistanceToNow } from "date-fns";
import ClientLimitReached from "components/ClientLimitReachedModal";
import { useIncrementClientCount } from "hooks/useIncrementClientCount";
import MetricsComponent from "components/CoachClientOverview/MetricsComponent";
import LaraClientOverview from "components/CoachClientOverview/LaraClientOverview";
import GoalCard from "components/CoachClientOverview/GoalCard";
import LaraSessionCircle from "components/CoachClientOverview/LaraSessionCircle";
import axios from "axios";
import { generateClient } from 'aws-amplify/data';
import Assessments from "components/CoachClientOverview/Assessments";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{
        position: "relative",
      }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const ClientOverview = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [clientData, setClientData] = useState([]);
  const [callData, setCallData] = useState([]);
  const [laraData, setLaraData] = useState([]);
  const [actions, setActions] = useState([]);
  const [todoActions, setTodoActions] = useState([]);
  const [completedActions, setCompletedActions] = useState([]);
  const [principles, setPrinciples] = useState([]);
  const [notes, setNotes] = useState([]);
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingLara, setLoadingLara] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [editClientData, setEditClientData] = useState(false);
  const [openAddActionModal, setOpenAddActionModal] = useState(false);
  const [actionDate, setActionDate] = useState("");
  const [actionsSum, setActionsSum] = useState(0);
  const [sessionsSum, setSessionsSum] = useState(0);
  const [todoActionsSum, setTodoActionsSum] = useState(0);
  const [completedActionsSum, setCompletedActionsSum] = useState(0);
  const [overdueActionsSum, setOverdueActionsSum] = useState(0);
  const [noteMenuShow, setNoteMenuShow] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedActionObjects, setSelectedActionObjects] = useState([]);
  const [selectedCompletedObjects, setSelectedCompletedObjects] = useState([]);
  const [selectedCompletedActions, setSelectedCompletedActions] = useState([]);
  const [openCompleteActionModal, setOpenCompleteActionModal] = useState(false);
  const [openUndoActionModal, setOpenUndoActionModal] = useState(false);
  const [openEditActionModal, setOpenEditActionModal] = useState(false);
  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false);
  const [updatedAction, setUpdatedAction] = useState({});
  const [initialAction, setInitialAction] = useState({});
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDueDateSetModal, setOpenDueDateSetModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [clientControlAnchorEl, setClientControlAnchorEl] = useState(null);
  const [openDeleteClientModal, setOpenDeleteClientModal] = useState(false);
  const [activeTab, setActiveTab] = useState("editText");
  const { client_id } = useParams();
  const { decrementClientCount, loadingIncrement, errorIncrement } =
    useDecrementClientCount();
  const { incrementClientCount, loadingIncrease, errorIncrease } =
    useIncrementClientCount();
  const newNoteTitleRef = useRef(null);
  const newNoteDescriptionRef = useRef(null);
  const handleCloseEditAction = () => {
    setOpenEditActionModal(false);
    setInitialAction({});
    setActiveTab("editText");
  };
  const handleCloseCompleteAction = () => {
    setOpenCompleteActionModal(false);
  };
  const handleCloseUndoAction = () => {
    setOpenUndoActionModal(false);
  };
  const handleCloseDeleteAction = () => {
    setOpenDeleteActionModal(false);
  };
  const handleCloseSetDueDate = () => {
    setOpenDueDateSetModal(false);
  };
  const handleClose = () => {
    setOpenAddActionModal(false);
    formik3.resetForm();
    setActionDate("");
  };

  const open = Boolean(clientControlAnchorEl);
  const handleClientControlClick = (event) => {
    setClientControlAnchorEl(event.currentTarget);
  };
  const handleClientControlClose = () => {
    setClientControlAnchorEl(null);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "20px",
      maxWidth: 700,
    },
  }));

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: "24px",
      backgroundColor: "#F0F0F0",
      color: "#202226",
      maxWidth: 330,
      fontSize: "12px",
      borderRadius: "25px",
    },
  }));

  const actionColumns = useMemo(() => [
    {
      field: "action_id",
      headerName: "Number",
      headerClassName: "table-header",
      hide: true,
      cellClassName: "table-cell",
    },
    {
      field: "action",
      headerName: "Task",
      flex: 0.3,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box width={"100%"} display={"flex"} alignItems={"center"}>
            <BootstrapTooltip title={params.value} placement="top-start">
              <Box
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(100% - 140px)",
                }}
              >
                {params.value}
              </Box>
            </BootstrapTooltip>
          </Box>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.1,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => (
        <Box display={"flex"} alignItems={"flex-end"}>
          {params.value === "incomplete" ? (
            <Box position="relative" width="100px">
              <Typography
                sx={{
                  background: "#2BBB42",
                  color: "white",
                  border: "1px solid green",
                  borderRadius: "100px",
                  // width: "150px",
                  textAlign: "center",
                  padding: "2px",
                }}
              >
                Complete
              </Typography>
            </Box>
          ) : (
            <Box position="relative" width="100px">
              <Typography
                sx={{
                  background: "#c518007d",
                  color: "white",
                  border: "1px solid #B60000",
                  borderRadius: "100px",
                  // width: "150px",
                  textAlign: "center",
                  padding: "2px",
                }}
              >
                Incomplete
              </Typography>
            </Box>
          )}
        </Box>
      ),
    },
  ]);

  const deleteActionModal = () => {
    return (
      <Modal
        open={openDeleteActionModal}
        onClose={() => setOpenDeleteActionModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to delete selected actions?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCloseDeleteAction} label={"No"} />
            <ConfirmButton onClick={deleteActions} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const deleteActions = async () => {
    try {
      setOpenDeleteActionModal(false);
      const selectedIdsToDelete = [
        ...selectedActionObjects,
        ...selectedCompletedObjects,
      ]; // Combine selectedIds and selectedCompletedIds
      await Promise.all(
        selectedIdsToDelete.map(async (row) => {
          const { status } = await axios.put(
            `${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-action`,
            {
              action_id: row.action_id,
            }
          );
          console.log("status", status);

          if (status === "success") {
            toast(`Deleted action successfully`, {
              hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
              icon: () => <Check color="success" />,
            });
            setActions((prev) =>
              prev.filter((prevRow) => prevRow.action_id !== row.action_id)
            );
          }
        })
      );
      setSelectedActions([]);
      setSelectedCompletedActions([]);
      setSelectedCompletedActions([]);
      setSelectedCompletedObjects([]);
    } catch (error) {
      console.error("Error updating action:", error);
    }
  };

  const [profilePicture, setProfilePicture] = useState(null);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      let response;

      response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-data-test`,
        {
          params: {
            client_id: client_id,
            coach_id: user?.sub,
            company_id: userData?.companyInformation?.company_id
          },
        }
      );
      console.log("response client: ", response);

      if (!response.data) {
        setLoading(false);
        return;
      }

      let pictureUrl = "";
      const data = response.data.client_data;

      data.avatar = data.avatar
        ? "https://d7joen7tb0xwx.cloudfront.net/public/" + data.avatar
        : null;
      setClientData(data);

      setProfilePicture(pictureUrl);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const fetchClientGoalsData = async () => {
    // setLoading(true);
    try {
      let response;

      response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-goals-for-coach`,
        {
          params: {
            client_id: client_id,
            coach_id: user?.sub,
          },
        }
      );
      console.log("goal response: ", response);
      if (!response.data.goals_data) {
        // setLoading(false);
        return;
      }

      const data = response.data.goals_data;

      setGoals(data);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const fetchActionData = async () => {
    setLoading(true);
    try {
      let response;

      response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetchActions`,
        {
          params: {
            client_id: client_id,
            coach_id: user?.sub,
          },
        }
      );
      console.log("response act: ", response);
      if (!response.data.data) {
        setLoading(false);
        return;
      }
      console.log("Actions data:", response);

      let data = Array.isArray(response.data.data) ? response.data.data : [];

      // Create a new array with unique IDs added to each action
      data = data.map((row) => ({
        id: _.uniqueId("actions tab"),
        ...row,
      }));

      // Now `data` contains the updated array with unique IDs

      const sortedData = data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      setActions(sortedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching action data:", error);
    }
  };

  useEffect(() => {
    const todoActions = actions.filter(
      (action) => action.status === "incomplete"
    );
    const completedActions = actions.filter(
      (action) => action.status === "complete"
    );
    setTodoActions(todoActions);
    setCompletedActions(completedActions);
  }, [actions]);

  const fetchClientNotes = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-note`,
        {
          params: {
            client_id: client_id,
          },
        }
      );

      console.log("notes res: ", response);
      if (!response.data) {
        // setLoading(false);
        return;
      }
      console.log("Notes data:", response.data);

      const sortedData = Array.isArray(response.data)
        ? response.data.sort((a, b) => new Date(b.date) - new Date(a.date))
        : [];

      setNotes(sortedData);
      setNoteMenuShow([]);
      sortedData.map((item) => {
        setNoteMenuShow((prev) => [...prev, false]);
      });
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching action data:", error);
    }
  };

  const fetchCallData = async () => {
    setLoading(true);
    setLoadingLara(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-coach-test`,
        {
          params: {
            client_id: client_id,
            coach_id: user?.sub,
          },
        }
      );

      let { data } = response;

      if (data && typeof data === "object" && !Array.isArray(data)) {
        data = data.data;
      }

      if (!Array.isArray(data)) {
        console.error(
          "Error: Expected data to be an array but received:",
          typeof data
        );
        console.error("Received data:", data);
        setLoading(false);
        setLoadingLara(false);
        return;
      }

      const dataByMonth = {};
      const sortedData = data.sort(
        (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
      );

      let totalClientListenScore = 0;
      let totalClientAffirmScore = 0;
      let totalClientRespondScore = 0;
      let totalClientAskScore = 0;
      let totalClientOverallScore = 0;
      let clientScoreCount = 0;

      for (const row of sortedData) {
        row.coachLaraData = [];
        row.clientLaraData = [];

        try {
          const { data: laraResult, errors: resultErrors } =
            await resourceClient.models.lara.get(
              { call_id: row.id },
              {
                selectionSet: [
                  "call_id",
                  "coach_affirm_score",
                  "coach_ask_score",
                  "coach_listen_score",
                  "coach_respond_score",
                  "coach_overall_score",
                  "coach_name",
                  "coachee_name",
                  "coachees.*",
                ],
              }
            );

          console.log("lara result: ", laraResult);

          if (resultErrors) {
            console.error("Error fetching LARA data:", resultErrors);
          }

          if (laraResult) {
            row.coachLaraData.push({
              affirm_score: laraResult.coach_affirm_score,
              ask_score: laraResult.coach_ask_score,
              listen_score: laraResult.coach_listen_score,
              respond_score: laraResult.coach_respond_score,
              overall_score: laraResult.coach_overall_score,
            });

            if (laraResult.coachee_overall_score !== undefined) {
              row.clientLaraData.push({
                overall_score: laraResult.coachee_overall_score,
              });
            }

            row.coach_name = laraResult.coach_name || row.coach_name;
            row.coachee_name = laraResult.coachee_name || row.client_name;

            // Eagerly load the coachees
            if (laraResult.coachees) {
              const matchingCoachee = laraResult.coachees.find(
                (coachee) => coachee.coachee_name === row.client_name
              );

              if (matchingCoachee) {
                row.clientLaraData.push({
                  affirm_score: matchingCoachee.affirm_score,
                  ask_score: matchingCoachee.ask_score,
                  listen_score: matchingCoachee.listen_score,
                  respond_score: matchingCoachee.respond_score,
                  overall_score: matchingCoachee.overall_score,
                });
              }
            }
          }
        } catch (error) {
          console.error("Failed to process LARA data for row:", row.id, error);
        }

        console.log("client lara data", row.clientLaraData);
        // Calculate client scores
        if (row.clientLaraData.length > 0) {
          const clientData = row.clientLaraData[0];
          row.clientOverallScore = Math.round(
            ((clientData.listen_score +
              clientData.affirm_score +
              clientData.respond_score +
              clientData.ask_score) /
              4) *
              20
          );
          clientScoreCount += 1;
          totalClientListenScore += clientData.listen_score;
          totalClientAffirmScore += clientData.affirm_score;
          totalClientRespondScore += clientData.respond_score;
          totalClientAskScore += clientData.ask_score;
          totalClientOverallScore +=
            (clientData.listen_score +
              clientData.affirm_score +
              clientData.respond_score +
              clientData.ask_score) /
            4;
        }

        // Calculate coach scores
        if (row.coachLaraData.length > 0) {
          const coachData = row.coachLaraData[0];
          row.coachOverallScore = Math.round(
            ((coachData.listen_score +
              coachData.affirm_score +
              coachData.respond_score +
              coachData.ask_score) /
              4) *
              20
          );
        }
      }

      if (clientScoreCount > 0) {
        const avg_listen = (totalClientListenScore / clientScoreCount) * 20;
        const avg_affirm = (totalClientAffirmScore / clientScoreCount) * 20;
        const avg_respond = (totalClientRespondScore / clientScoreCount) * 20;
        const avg_ask = (totalClientAskScore / clientScoreCount) * 20;
        const avg_overall = (totalClientOverallScore / clientScoreCount) * 20;

        const clientLaraAverages = {
          listen_score: avg_listen,
          affirm_score: avg_affirm,
          respond_score: avg_respond,
          ask_score: avg_ask,
          overall_score: avg_overall,
        };

        console.log("lara avg: ", clientLaraAverages);
        setLaraData(clientLaraAverages);
      }

      sortedData.forEach((row) => {
        const month = new Date(row.zoom_date).toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        });

        if (!dataByMonth[month]) {
          dataByMonth[month] = [];
        }

        if (row.call_name) {
          dataByMonth[month].push(row);
        }
      });

      setCallData(dataByMonth);
      console.log("Updated Call Data:", dataByMonth);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
      setLoadingLara(false);
    }
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const handleActionTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedActions([]);
    setSelectedActionObjects([]);
    setSelectedCompletedActions([]);
    setSelectedCompletedObjects([]);
  };

  const formik3 = useFormik({
    initialValues: {
      action_title: "",
      action: "",
      date: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      action: Yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          client_id: clientData.user_id,
          title: values.action_title,
          action: values.action,
          due_date: actionDate ? actionDate : null,
          coach_id: user?.sub,
          client_name: clientData.full_name,
          coach_name: user?.given_name + " " + user?.family_name,
          add: true,
        };

        setOpenAddActionModal(false);
        setLoading(true);
        const response = await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/add-action`,
          body
        );
        console.log("add action response", response);
        await fetchActionData();
        setLoading(false);
        setActionDate("");
        formik3.setFieldValue("action", "");
      } catch (err) {
        console.log("Error in update", err.message);
        toast.error(err.message);
        setLoading(false);
      }
    },
  });

  const completeActionModal = () => {
    return (
      <Modal
        open={openCompleteActionModal}
        onClose={() => setOpenCompleteActionModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to mark selected actions as completed?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCloseCompleteAction} label={"No"} />
            <ConfirmButton onClick={completeActions} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const undoActionModal = () => {
    return (
      <Modal
        open={openUndoActionModal}
        onClose={() => setOpenUndoActionModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to mark selected actions as to-do?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCloseUndoAction} label="No" />
            <ConfirmButton onClick={undoActions} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const ClientComponent = () => {
    return (
      <Box
        sx={{
          maxHeight: "100%",
        }}
        padding="24px"
      >
        <Box display="flex" gap="20px" alignItems="center">
          <Box
            sx={{
              background:
                "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
              padding: "3px",
              borderRadius: "50%",
              width: {
                xs: "60px",
                lg: "80px",
                xl: "100px",
              },
              height: {
                xs: "60px",
                lg: "80px",
                xl: "100px",
              },
            }}
          >
            <Box
              sx={{
                background: "white",
                padding: "4px",
                borderRadius: "50%",
                width: {
                  xs: "54px",
                  lg: "74px",
                  xl: "94px",
                },
                height: {
                  xs: "54px",
                  lg: "74px",
                  xl: "94px",
                },
              }}
            >
              <Avatar
                sx={{
                  width: {
                    xs: "46px",
                    lg: "66px",
                    xl: "86px",
                  },
                  height: {
                    xs: "46px",
                    lg: "66px",
                    xl: "86px",
                  },
                }}
                src={clientData.avatar || avatar}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography
              sx={{
                fontSize: { xs: "20px", md: "28px" },
                fontWeight: "bold",
                lineHeight: "1.2",
                color: "black",
              }}
            >
              {clientData.full_name}
            </Typography>
            {!editClientData && (
              <>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "18px",
                    lineHeight: "1.35",
                    color: "black",
                  }}
                >
                  {clientData.occupation}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                xl: "unset",
              },
              flexDirection: {
                xs: "column",
                xl: "row",
              },
              gap: {
                xs: "24px",
                lg: "0",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: {
                  xs: "16px",
                  lg: "20px 35px 0",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <EmailIcon
                  sx={{
                    color: "#9DB2CE",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "18px",
                    lineHeight: "1.35",
                    color: "black",
                  }}
                >
                  {clientData.email}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <PhoneIphoneIcon
                  sx={{
                    color: "#9DB2CE",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "18px",
                    lineHeight: "1.35",
                    color: "black",
                  }}
                >
                  {clientData.phone_number}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <PlaceIcon
                  sx={{
                    color: "#9DB2CE",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "18px",
                    lineHeight: "1.35",
                    color: "black",
                  }}
                >
                  {clientData.city}, {clientData.state} {clientData.zip_code}
                </Typography>
              </Box>
              {/* <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "18px",
                              color: "#202226",
                              lineHeight: "1.35",
                            }}
                          >
                            last login: Feb 12, 2024
                          </Typography> */}
            </Box>
          </Box>
        
      </Box>
    );
  };

  const addActionModal = (
    <Modal
      open={openAddActionModal}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          boxShadow: 24,
          padding: {
            xs: "24px",
            lg: "64px",
          },
          gap: {
            xs: "24px",
            lg: "unset",
          },
          borderRadius: "30px",
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          justifyContent: "space-between",
          maxWidth: "calc(100% - 40px)",
          margin: "20px auto",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "50%",
            },
            display: "flex",
            flexDirection: "column",
            gap: {
              xs: "8px",
              lg: "16px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              maxWidth: {
                md: "350px",
              },
              fontSize: "36px",
              color: "black",
            }}
          >
            Create a new action for {clientData.full_name}
          </Typography>
          <Typography
            sx={{
              maxWidth: {
                lg: "280px",
              },
              fontSize: "14px",
              lineHeight: "1.5",
              letterSpacing: "0.3px",
              color: "black",
            }}
          >
            Once posted, the recipient will be notified of the action.
          </Typography>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "50%",
            },
            maxWidth: {
              xs: "100%",
              lg: "340px",
            },
          }}
        >
          <form onSubmit={formik3.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "22px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                >
                  Action
                </Typography>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  error={
                    formik3.touched.action && Boolean(formik3.errors.action)
                  }
                  {...formik3.getFieldProps("action")}
                  InputProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  sx={{
                    color: "#22272F",
                    background: "white",
                    borderRadius: "16px",
                    width: "100%",
                    border: "1px solid #C4CAD4",
                    "&:before, &:after": {
                      display: "none",
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  }}
                  focused={formik3.touched.action}
                />
                {formik3.touched.action && formik3.errors.action && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#BE0808",
                      letterSpacing: "2px",
                      fontSize: "8px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {formik3.touched.action && formik3.errors.action}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                >
                  Due Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .utc()
                          .format("YYYY-MM-DD");
                        setActionDate(formattedDate);
                      }
                    }}
                    sx={{
                      borderRadius: "16px",
                      border: "1px solid #C4CAD4",
                      "& svg": {
                        color: "#22272F",
                      },
                      "& input": {
                        color: "#22272F",
                      },
                      "& fieldset": {
                        border: "none !important",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box
              marginTop="42px"
              display="flex"
              justifyContent="flex-end"
              gap="15px"
            >
              <NegativeActionButton onClick={handleClose} label={"Cancel"} />
              <PositiveActionButton
                onClick={formik3.handleSubmit}
                label={"Create Action"}
                disabled={!actionDate || !formik3.values.action}
              />
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );

  const dueDateSetModal = () => {
    return (
      <Modal
        open={openDueDateSetModal}
        onClose={() => setOpenDueDateSetModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to set due date to ${selectedDate}?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCloseSetDueDate} label={"No"} />
            <ConfirmButton onClick={setDueDate} label="Yes" />
          </Box>
        </Box>
      </Modal>
    );
  };

  const setDueDate = useCallback(async () => {
    try {
      setOpenDueDateSetModal(false);
      const selectedActionsToUpdate = [
        ...selectedActions,
        ...selectedCompletedActions,
      ];
      selectedActionsToUpdate.map(async (row) => {
        const { message } = await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action-due-date`,
          {
            action_id: row,
            due_date: selectedDate,
          }
        );
        console.log("update action due date message", message);

        if (message === "Updated successfully") {
          toast(`Updated due date successfully`, {
            hideProgressBar: true,
            style: {
              background: "white",
              boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
              borderRadius: "8px",
              padding: "16px 28px",
              color: "#160042",
            },
            icon: () => <Check color="success" />,
          });

          setActions((prev) =>
            prev.map((prevRow) =>
              selectedActions.indexOf(prevRow.action_id) > -1
                ? { ...prevRow, due_date: selectedDate }
                : prevRow
            )
          );

          setActions((prev) =>
            prev.map((prevRow) =>
              selectedCompletedActions.indexOf(prevRow.action_id) > -1
                ? { ...prevRow, due_date: selectedDate }
                : prevRow
            )
          );
          setSelectedActions([]);
          setSelectedCompletedActions([]);
        }
      });
    } catch (error) {
      console.error("Error setting due date:", error);
    }
  });

  const handleMenuOpen = (field, event) => {
    const newAnchorEl = { ...anchorEl, [field]: event.currentTarget };
    setAnchorEl(newAnchorEl);
  };

  const handleMenuClose = (field) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [field]: null,
    }));
  };

  const handleMenuItemClick = (item, direction) => {
    if (!Array.isArray(actions)) {
      console.error("actions is not an array:", actions);
      return;
    }
    const currentData = [...actions];
    let sortedData;
    if (item === "todo_start_date" || item === "complete_start_date") {
      sortedData = currentData.sort((a, b) => {
        if (a.call_date === null) return 1;
        if (b.call_date === null) return -1;
        if (direction === "asc") {
          return new Date(a.call_date) - new Date(b.call_date);
        }
        return new Date(b.call_date) - new Date(a.call_date);
      });
    } else if (item === "todo_end_date" || item === "complete_end_date") {
      sortedData = currentData.sort((a, b) => {
        if (a.due_date === null) return 1;
        if (b.due_date === null) return -1;
        if (direction === "asc") {
          return new Date(a.due_date) - new Date(b.due_date);
        }
        return new Date(b.due_date) - new Date(a.due_date);
      });
    } else {
      sortedData = currentData.sort((a, b) => {
        if (direction === "asc") {
          return a[item].localeCompare(b[item]);
        }
        return b[item].localeCompare(a[item]);
      });
    }
    setActions(sortedData);
    handleMenuClose(item);
  };

  const completeActions = async () => {
    try {
      const promises = selectedActions.map(async (row) => {
        const body = {
          completed: "incomplete",
          action_id: row,
        };
        const response = await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/setActionStatus`,
          body
        );
        return response;
      });
      console.log("set action status promises", promises);

      const results = await Promise.all(promises);

      setOpenCompleteActionModal(false);
      setActions((prev) =>
        prev.map((prevRow) =>
          selectedActions.indexOf(prevRow.action_id) > -1
            ? { ...prevRow, status: "complete", completed_at: new Date() }
            : prevRow
        )
      );
      setSelectedActions([]);
      setSelectedActionObjects([]);

      toast(`Action marked as completed`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
    } catch (err) {
      console.log("Error in update", err.message);
      toast.error(err.message);
    }
  };

  const undoActions = async () => {
    try {
      selectedCompletedActions.map(async (row) => {
        const body = {
          completed: "complete",
          action_id: row,
        };
        const response = await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/setActionStatus`,
          body
        );
        return response;
      });

      setOpenUndoActionModal(false);
      setActions((prev) =>
        prev.map((prevRow) =>
          selectedCompletedActions.indexOf(prevRow.action_id) > -1
            ? { ...prevRow, status: "incomplete" }
            : prevRow
        )
      );
      setSelectedCompletedActions([]);
      setSelectedCompletedObjects([]);

      toast(`Action marked as to-do`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
    } catch (err) {
      console.log("Error in update", err.message);
      toast.error(err.message);
    }
  };

  const CompletedActionComponent = ({
    title,
    actionValue,
    index,
    setActions,
    actionId,
    status,
    date,
    due_date,
    edits,
  }) => {
    const [actionTitle, setActionTitle] = useState(title);
    const [action, setAction] = useState(actionValue);
    const [actionStatus, setActionStatus] = useState(status);

    const handleCheckboxChange = async (event) => {
      const checked = event.target.checked;
      const actionObject = {
        title: actionTitle,
        action: action,
        status: actionStatus,
        action_id: actionId,
        edits: edits,
      };

      if (checked === true) {
        setSelectedCompletedActions([...selectedCompletedActions, actionId]);
        setSelectedCompletedObjects([
          ...selectedCompletedObjects,
          actionObject,
        ]);
      } else {
        setSelectedCompletedActions(
          selectedCompletedActions.filter((id) => id !== actionId)
        );
        setSelectedCompletedObjects(
          selectedCompletedObjects.filter((obj) => obj.action_id !== actionId)
        );
      }
    };

    const rowBackground = index % 2 === 0 ? "white" : "#F9F9F9";

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
              paddingRight: "20px",
            }}
          >
            <Checkbox
              checked={
                selectedCompletedActions.indexOf(actionId) > -1 ? "checked" : ""
              }
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "4px",
                color: "#E4E6F2",
                "&.Mui-checked": {
                  color: "black",
                },
              }}
              onChange={handleCheckboxChange}
            />
          </Box>
          <Box
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
              paddingY: "8px",
            }}
          >
            <Typography
              sx={{
                ...textStyle,
              }}
            >
              {action}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "200px",
            marginLeft: "14px",
          }}
        >
          <Box
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
              justifyContent: "center",
              paddingY: "8px",
              minWidth: "100px",
            }}
          >
            <Typography
              sx={{
                display: "inline-block",
                fontFamily: "Lexend",
                fontSize: "10px",
                lineHeight: "2.6",
                fontWeight: "500",
                color: "#202226",
                textAlign: "left",
                marginRight: "-12px",
              }}
            >
              {date !== null &&
                date !== "" &&
                dayjs(date).utc().format("MM.DD.YY")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
              paddingY: "8px",
              minWidth: "100px",
            }}
          >
            <Typography
              sx={{
                display: "inline-block",
                fontFamily: "Lexend",
                fontSize: "10px",
                lineHeight: "2.6",
                fontWeight: "500",
                color: "#202226",
                textAlign: "left",
              }}
            >
              {due_date !== null &&
                due_date !== "" &&
                dayjs(due_date).utc().format("MM.DD.YY")}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const ActionComponent = ({
    title,
    actionValue,
    index,
    setActions,
    actionId,
    status,
    date,
    due_date,
    edits,
  }) => {
    const [actionTitle, setActionTitle] = useState(title);
    const [action, setAction] = useState(actionValue);
    const [actionStatus, setActionStatus] = useState(status);

    const handleCheckboxChange = async (event) => {
      const checked = event.target.checked;
      const actionObject = {
        title: actionTitle,
        action: action,
        status: actionStatus,
        action_id: actionId,
        edits: edits,
      };

      if (checked === true) {
        setSelectedActions([...selectedActions, actionId]);
        setSelectedActionObjects([...selectedActionObjects, actionObject]);
      } else {
        setSelectedActions(selectedActions.filter((id) => id !== actionId));
        setSelectedActionObjects(
          selectedActionObjects.filter((obj) => obj.action_id !== actionId)
        );
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
              paddingRight: "20px",
            }}
          >
            <Checkbox
              checked={selectedActions.indexOf(actionId) > -1 ? "checked" : ""}
              disabled={actionStatus === "complete" ? "disabled" : ""}
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "4px",
                color: "#E4E6F2",
                "&.Mui-checked": {
                  color: "black",
                },
              }}
              onChange={handleCheckboxChange}
            />
          </Box>
          <Box
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
              paddingY: "8px",
            }}
          >
            <Typography
              sx={{
                ...textStyle,
              }}
            >
              {action}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "200px",
            marginLeft: "14px",
          }}
        >
          <Box
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
              justifyContent: "center",
              paddingY: "8px",
              minWidth: "100px",
            }}
          >
            <Typography
              sx={{
                display: "inline-block",
                fontFamily: "Lexend",
                fontSize: "10px",
                lineHeight: "2.6",
                fontWeight: "500",
                color: "#202226",
                textAlign: "left",
                marginRight: "-12px",
              }}
            >
              {date !== null &&
                date !== "" &&
                dayjs(date).utc().format("MM.DD.YY")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
              paddingY: "8px",
              minWidth: "100px",
            }}
          >
            <Typography
              sx={{
                display: "inline-block",
                fontFamily: "Lexend",
                fontSize: "10px",
                lineHeight: "2.6",
                fontWeight: "500",
                color: "#202226",
                textAlign: "left",
              }}
            >
              {due_date !== null &&
                due_date !== "" &&
                dayjs(due_date).utc().format("MM.DD.YY")}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const NoteComponent = () => {
    const [openCreateNoteModal, setOpenCreateNoteModal] = useState(false);

    const handleOpenCreateNoteModal = () => {
      setOpenCreateNoteModal(true);
    };

    const handleCloseCreateNoteModal = () => {
      setOpenCreateNoteModal(false);
    };

    const formik4 = useFormik({
      initialValues: {
        note_title: "",
        note_description: "",
      },
      enableReinitialize: true,
      onSubmit: async (values) => {
        try {
          if (values.note_title === "" || values.note_description === "") {
            return;
          }

          const res = await axios.put(
            `${process.env.REACT_APP_API_ENDPOINT}/zoom/save-client-note`,
            {
              note_id: uuid(),
              coach_id: user?.sub,
              client_id: client_id,
              client_name: clientData.full_name,
              title: values.note_title,
              note: values.note_description,
              date: new Date(Date.now()),
              status: "incomplete",
              add: true,
            }
          );

          toast(`Note was added successfully`, {
            hideProgressBar: true,
            style: {
              background: "white",
              boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
              borderRadius: "8px",
              padding: "16px 28px",
              color: "#160042",
            },
            icon: () => <Check color="success" />,
          });

          await fetchClientNotes();
          handleCloseCreateNoteModal();
        } catch (err) {
          console.log("Error in update", err.message);
          toast.error(err.message);
          setLoading(false);
        }
      },
    });

    const handleComplteNote = async (noteId) => {
      const res = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/complete-client-note`,
        {
          note_id: noteId,
        }
      );

      if (res.status === "success") {
        toast(`Note status updated successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
      } else {
        toast.error("Error");
      }

      await fetchClientNotes();
    };

    const handleDeleteNote = async (noteId) => {
      const res = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-client-note`,
        {
          note_id: noteId,
        }
      );

      if (res.status === "success") {
        toast(`Note deleted successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
      } else {
        toast.error("Error");
      }

      await fetchClientNotes();
    };

    return (
      <Box paddingTop="20px" display="flex" flexDirection="column" gap="36px">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              sx={{
                paddingX: "30px",
                paddingY: "10px",
                background: "white",
                border: "1px solid black",
                color: "black",
                borderRadius: "43px",
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s",
                "&:hover": {
                  background: "rgba(242, 242, 242, 0.4)",
                  border: "1px solid #D5D5D5",
                  color: "#6D6D6D",
                },
              }}
              onClick={handleOpenCreateNoteModal}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "10px",
                  lineHeight: "1.5",
                  fontWeight: 600,
                  letterSpacing: "2px",
                  textTransform: "uppercase",
                }}
              >
                Add a Note
              </Typography>
            </Button>
          </Box>

          <Modal
            open={openCreateNoteModal}
            onClose={handleCloseCreateNoteModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                background: "white",
                border: "2px solid white",
                boxShadow: 24,
                paddingX: "20px",
                paddingY: "30px",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "8px",
                margin: "0 auto",
                maxWidth: "calc(100% - 40px)",
              }}
            >
              <Box
                sx={{
                  padding: "30px",
                  borderRadius: "30px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    maxWidth: {
                      md: "350px",
                    },
                    fontSize: "36px",
                    color: "black",
                    marginBottom: "16px",
                  }}
                >
                  Create a new note
                </Typography>
                <form onSubmit={formik4.handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "22px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase",
                        }}
                      >
                        Note Subject
                      </Typography>
                      <TextField
                        name="note_title"
                        {...formik4.getFieldProps("note_title")}
                        fullWidth
                        InputProps={{
                          placeholder: "Add note subject",
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase",
                        }}
                      >
                        Add your note
                      </Typography>
                      <TextField
                        name="note_description"
                        {...formik4.getFieldProps("note_description")}
                        fullWidth
                        multiline
                        rows={4}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    marginTop="42px"
                    display="flex"
                    justifyContent="flex-end"
                    gap="15px"
                  >
                    <NegativeActionButton
                      onClick={handleCloseCreateNoteModal}
                      label={"Cancel"}
                    />
                    <PositiveActionButton
                      onClick={formik4.handleSubmit}
                      label={"Save Note"}
                    />
                  </Box>
                </form>
              </Box>
            </Box>
          </Modal>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "480px",
              overflowY: "auto",
            }}
          >
            {notes.map((note, index) => (
              <Box
                sx={{
                  padding: "24px",
                  background: "#F6F6F6",
                  borderRadius: "40px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "22px",
                      lineHeight: "1.35",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {note.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        padding: "0 26px",
                        background: "white",
                        borderRadius: "48px",
                        fontFamily: "Lexend",
                        fontSize: "10px",
                        lineHeight: "2.6",
                        fontWeight: "500",
                        color: "#202226",
                      }}
                    >
                      {note.date !== null &&
                        note.date !== "" &&
                        dayjs(note.date).utc().format("MM.DD.YY")}
                    </Typography>
                    <Box
                      sx={{
                        color: "#C4C4C4",
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        padding: "10px",
                        border: "1px solid #C4C4C4",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        const newStates = [...noteMenuShow];
                        newStates[index] = !newStates[index];
                        setNoteMenuShow(newStates);
                      }}
                    >
                      <MenuIcon
                        sx={{
                          color: "#C4C4C4",
                        }}
                      />
                    </Box>
                    {noteMenuShow[index] && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          paddingY: "6px",
                          top: "60px",
                          right: "24px",
                          width: "80px",
                          borderRadius: "12pt",
                          background: "#fff",
                          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                          zIndex: "999",
                        }}
                      >
                        {/* <Button
                        sx={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "16px",
                          fontFamily: "Montserrat",
                          lineHeight: "1.4",
                          padding: "8px",
                          textAlign: "center",
                          "&:hover": {
                            background: "#F7F7F7",
                            cursor: "pointer",
                          },
                          "&:disabled": {
                            background: "grey",
                            color: "#F7F7F7",
                          }
                        }}
                        disabled={clientData.status === "1"}
                        onClick={() => {
                          // setInfo("");
                          setSelectedClient(row);
                          inviteClient();
                          // setOpenInviteModal(true);
                        }}
                      >
                        Invite
                      </Button> */}
                        <Button
                          sx={{
                            color: "black",
                            fontSize: "14px",
                            lineHeight: "1.4",
                            padding: "8px",
                            textAlign: "center",
                            textTransform: "none",
                            fontWeight: "200",
                            "&:hover": {
                              background: "#F5F5F5",
                              cursor: "pointer",
                            },
                            "&:disabled": {
                              color: "#d3d3d3",
                              cursor: "not-allowed",
                            },
                          }}
                          disabled={note.status === "complete"}
                          onClick={() => {
                            handleComplteNote(note.note_id);
                          }}
                        >
                          Done
                        </Button>
                        <Button
                          sx={{
                            color: "black",
                            fontSize: "14px",
                            lineHeight: "1.4",
                            padding: "8px",
                            textAlign: "center",
                            textTransform: "none",
                            fontWeight: "200",
                            "&:hover": {
                              background: "#F5F5F5",
                              cursor: "pointer",
                            },
                            "&:disabled": {
                              color: "#d3d3d3",
                              cursor: "not-allowed",
                            },
                          }}
                          onClick={() => {
                            handleDeleteNote(note.note_id);
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "14px",
                    lineHeight: "1.4",
                    color: "#202226",
                    fontWeight: "300",
                  }}
                >
                  {note.note}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "16px",
                    right: "24px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor:
                        note.status === "complete" ? "#83BF6E" : "#FF6A55",
                    }}
                  />
                  <Typography sx={textStyle}>
                    {note.status === "complete" ? "Done" : "Not done"}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  const todoActionComponent = todoActions.map((action, index) => (
    <Box
      key={index}
      sx={{
        justifyContent: "center",
        paddingY: "8px",
        background: index % 2 === 0 ? "white" : "#F9F9F9",
        borderTop: "1px solid #F3F3F3",
        borderBottom: "1px solid #F3F3F3",
        "&:hover": {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          zIndex: 1,
          cursor: "pointer",
          background: index % 2 === 0 ? "white" : "#F9F9F9",
        },
      }}
    >
      <ActionComponent
        key={index}
        title={action.title}
        actionValue={action.action}
        index={index}
        actionId={action.action_id}
        setActions={setActions}
        status={action.status}
        date={action.call_date}
        due_date={action.due_date}
        edits={action.edits}
      />
    </Box>
  ));

  const completedActionComponent = completedActions.map((action, index) => (
    <Box
      key={index}
      sx={{
        justifyContent: "center",
        paddingY: "8px",
        background: index % 2 === 0 ? "white" : "#F9F9F9",
        borderTop: "1px solid #F3F3F3",
        borderBottom: "1px solid #F3F3F3",
        "&:hover": {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          zIndex: 1,
          cursor: "pointer",
          background: index % 2 === 0 ? "white" : "#F9F9F9",
        },
      }}
    >
      <CompletedActionComponent
        title={action.title}
        actionValue={action.action}
        index={index}
        actionId={action.action_id}
        setActions={setActions}
        status={action.status}
        date={action.call_date}
        due_date={action.due_date}
        edits={action.edits}
      />
    </Box>
  ));

  const fetchSumData = async () => {
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-sum-by-coach`,
        {
          params: {
            client_id: client_id,
            coach_id: user?.sub,
          },
        }
      );
      console.log("sum res: ", response);
      if (!response.data) {
        return;
      }
      setActionsSum(response.data.actions_sum);
      setSessionsSum(
        response.data.session_sum === "0" ? 0 : response.data.session_sum
      );
      setTodoActionsSum(response.data.todo_actions_sum);
      setCompletedActionsSum(response.data.completed_actions_sum);
      setOverdueActionsSum(response.data.overdue_actions_sum);
    } catch (error) {
      console.error("Error fetching sum data:", error);
    }
  };

  const handleDisableClient = async (client_id) => {
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/disable_client_test`,
        {
          client_id: client_id,
          coach_id: user?.sub,
        }
      );
      const { clientCount } = await decrementClientCount(user?.sub);
      fetchClientData();
      toast(`Client disabled successfully`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      setLoading(false);
    } catch (err) {
      console.log("Error in disable client", err.message);
      toast.error(err.message);
      setLoading(false);
    } finally {
      handleClientControlClose();
    }
  };

  const handleEnableClient = async (client_id) => {
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/enable_client_test`,
        {
          client_id: client_id,
          coach_id: user?.sub,
        }
      );
      const { clientTierAllowance, clientCount } = await incrementClientCount(
        user?.sub
      );
      fetchClientData();
      toast(`Client enabled successfully`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      setLoading(false);
    } catch (err) {
      console.log("Error in enable client", err.message);
      toast.error("Failed to update client count.");
      setLoading(false);
    } finally {
      handleClientControlClose();
    }
  };

  const handleResendInvite = async (client_id, client_name) => {
    try {
      if (!userData?.companyInformation) {
        throw new Error("Company information is not available");
      }
      setLoading(true);
      const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/resend-invite`, {
        invited_user_id: client_id, 
        user_id: user?.sub, 
        company_id: userData?.companyInformation?.company_id,
        company_name: userData?.companyInformation?.company_name || ""
      });
      const data = response.data;


      toast(`Invite has been resent to ${client_name}`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });

      setLoading(false);
    } catch (err) {
      console.error("Error in resend invite", err.message);
      toast.error(`Error resending invitation to ${client_name}`)
      setLoading(false);
    } finally {
      handleClientControlClose();
    }
  };

  const deleteClientModalContent = () => {
    return (
      <Modal open={openDeleteClientModal} onClose={closeDeleteClientModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="#160042" fontWeight="400">
              Are you sure you want to delete {clientData.full_name}?
            </Typography>
            <Box display="flex" gap="8px" alignItems="center">
              <ErrorOutline
                sx={{
                  color: "#CC5F0A",
                }}
              />
              <Typography color="#6B7094" fontWeight="400">
                All of their data will be erased and cannot be retrieved.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={closeDeleteClientModal} label={"No"} />
            <ConfirmButton onClick={handleDeleteClient} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const handleDeleteClient = async () => {
    if (!user?.sub) {
      console.error("User ID is undefined or null.");
      toast.error("User authentication is required.");
      return;
    }
    try {
      setLoading(true);
      setOpenDeleteClientModal(false);
      const client_id = clientData.user_id;
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-company-members`, {
        userIds: [client_id], // Pass client_id as an array
        companyId: userData?.companyInformation?.company_id,
        user_id: user?.sub
      });
      // Decrement client count in DB after successful client deletion
      const { deletedCount } = response.data; // API should return the count of deleted users
  
      // Decrement the seat count based on the number of deleted users
      for (let i = 0; i < deletedCount; i++) {
        await resourceClient.mutations.decrementCoacheeSeatCount({
          user_id: userData?.companyInformation?.owner?.user_id,
        });
      }

      setLoading(false);
      toast(`Member deleted successfully`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      navigate("/platform/coach/leadership-center");
    } catch (err) {
      console.error("Error deleting member", err.message);
      console.error("Failed to decrement client count:", err);
      toast.error("Failed to update seat count.");
      toast.error(err.message);
      setLoading(false);
    } finally {
      handleClientControlClose();
    }
  };

  const editActionModal = () => {
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
    let originalAction = "";
    if (initialAction && initialAction.edits && initialAction.edits[0]) {
      originalAction = initialAction.edits[0].previous_value;
    }
    return (
      <Modal open={openEditActionModal} onClose={handleCloseEditAction}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                lg: "32px",
              },
            }}
          >
            <Box display="flex" gap="10px">
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editText" ? "1px solid black" : "none",
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editText")}
              >
                Edit Action
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editHistory" ? "1px solid black" : "none",
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editHistory")}
              >
                View History
              </Typography>
            </Box>
            {activeTab === "editHistory" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  height: "220px",
                  overflowY: "auto",
                }}
              >
                {initialAction.edits &&
                  initialAction.edits
                    .slice()
                    .reverse()
                    .map((edit, index) => (
                      <Box key={index} sx={{ gap: "2px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          {`${edit.new_value}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#2D2D2D",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginBottom: "6px",
                          }}
                        >
                          edited by{" "}
                          {user?.sub === edit.edited_by_userid
                            ? "me"
                            : (clientData.full_name ?? edit.participant_name) ||
                              "Client"}{" "}
                          {formatDistanceToNow(new Date(edit.edit_timestamp))}{" "}
                          ago
                        </Typography>
                        <Divider color="#d3d3d3" />
                      </Box>
                    ))}
                {initialAction.edits && initialAction.edits[0] && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      {originalAction}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2D2D",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      orginal action
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {activeTab === "editText" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "150%",
                      letterSpacing: "2px",
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    autoFocus
                    multiline
                    rows={4}
                    value={updatedAction.action}
                    InputProps={{
                      inputProps: {
                        style: {
                          color: "black",
                        },
                      },
                      style: {
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        backgroundColor: "transparent",
                      },
                    }}
                    onChange={(event) => {
                      setUpdatedAction((prev) => ({
                        ...prev,
                        action: event.target.value,
                      }));
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" gap="15px">
                  <NegativeActionButton
                    onClick={handleCloseEditAction}
                    label={"Cancel"}
                  />
                  <PositiveActionButton
                    onClick={saveEditAction}
                    label={"Save Action"}
                    disabled={updatedAction.action === initialAction.action}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  const saveEditAction = async () => {
    try {
      setOpenEditActionModal(false);
      const { status } = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action`,
        {
          action_id: updatedAction.action_id,
          action: updatedAction.action,
          initial_action: initialAction.action,
          edited_by_userID: user?.sub,
        }
      );
      if (status === "success") {
        toast(`Updated action successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        setActions((prev) =>
          prev.map((prevRow) =>
            prevRow.action_id === updatedAction.action_id
              ? { ...prevRow, action: updatedAction.action }
              : prevRow
          )
        );
        await fetchActionData();
        setUpdatedAction("");
        setSelectedActions([]);
        setSelectedActionObjects([]);
        setSelectedCompletedObjects([]);
        setSelectedCompletedActions([]);
      }
    } catch (error) {
      console.error("Error updating action:", error);
    }
  };

  const closeDeleteClientModal = () => {
    setOpenDeleteClientModal(false);
  };

  useEffect(() => {
    fetchClientData();
    fetchClientGoalsData();
    fetchCallData();
    fetchActionData();
    fetchClientNotes();
  }, []);

  useEffect(() => {
    fetchSumData();
  }, [saveEditAction, undoActions, completeActions, deleteActions, setDueDate]);

  return loading ? (
    <GlobalPageComponent user={user} accessTo={userData.accessTo}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </GlobalPageComponent>
  ) : (
    <GlobalPageComponent user={user} accessTo={userData.accessTo}>
      <ClientLimitReached user={user} userData={userData} />
      {deleteClientModalContent()}
      {editActionModal()}
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          padding: {
            xs: "16px",
            md: "32px 40px",
          },
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "flex-start" }, // Adjusted alignment for small screens
            gap: { sm: "0px", md: "24px" },
            textAlign: { xs: "center", md: "left" }, // Align text to center on small screens
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontWeight: 300,
              fontSize: "36px",
              fontFamily: "Poppins",
              paddingBottom: "24px",
            }}
          >
            {clientData?.full_name ? `${clientData.full_name}` : ""}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontSize: "10px",
                fontWeight: "700",
                lineHeight: "1.5",
                letterSpacing: "2px",
                textTransform: "uppercase",
                marginTop: { md: "22px" },
                color:
                  clientData.status === "accepted"
                    ? "#499A0A"
                    : clientData.status === "disabled" ||
                      clientData.status === "expired"
                    ? "#FF6A55"
                    : "#F69607",
              }}
            >
              {clientData.status === "pending"
                ? "Invite Pending"
                : clientData.status === "accepted"
                ? ""
                : clientData.status === "disabled"
                ? "Disabled Status"
                : clientData.status === "expired"
                ? "Invite Expired"
                : clientData.status === "withdrew"
                ? "Client Withdrew"
                : ""}
            </Typography>
            {(user?.role === 'SUPERADMINS' || user?.role === 'OWNER') && (user?.sub !== clientData.user_id) && (clientData.user_id !== userData?.companyInformation?.owner.user_id) && (
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClientControlClick}
                sx={{
                  marginTop: "-15px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <MoreVertIcon
                  sx={{
                    color: "#C4C4C4",
                    fontSize: "26px",
                  }}
                />
              </IconButton>
            )}
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
                sx: {
                  width: "100%",
                },
              }}
              PaperProps={{
                sx: {
                  display: "flex",
                  background: "#fff",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)", // Increase the blur radius by 10%
                  borderRadius: "12pt",
                  paddingY: "6px",
                  width: "100px",
                  marginTop: "-15px",
                },
              }}
              anchorEl={clientControlAnchorEl}
              open={open}
              onClose={handleClientControlClose}
            >
              {/* {clientData.status !== "withdrew" &&
                (clientData.status === "disabled" ? (
                  <MenuItem
                    sx={{
                      color: "black",
                      textAlign: "center",
                      justifyContent: "center",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    disabled={
                      clientData.status === "accepted" ||
                      clientData.status === "pending"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEnableClient(clientData.user_id);
                    }}
                  >
                    <Typography sx={{ color: "black" }}>Enable</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    sx={{
                      color: "black",
                      textAlign: "center",
                      justifyContent: "center",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                        cursor: "pointer",
                      },
                      "&:disabled": {
                        color: "#d3d3d3",
                        cursor: "not-allowed",
                      },
                    }}
                    disabled={clientData.status === "pending"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDisableClient(clientData.user_id);
                    }}
                  >
                    <Typography sx={{ color: "black" }}>Disable</Typography>
                  </MenuItem>
                ))} */}
              {clientData.status !== "accepted" &&
                clientData.status !== "disabled" && (
                  <MenuItem
                    sx={{
                      color: "black",
                      textAlign: "center",
                      justifyContent: "center",
                      fontSize: "14px",
                      lineHeight: "1.4",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleResendInvite(
                        clientData.user_id,
                        clientData.full_name
                      );
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                      }}
                    >
                      Resend Invite
                    </Typography>
                  </MenuItem>
                )}
              <MenuItem
                sx={{
                  color: "black",
                  textAlign: "center",
                  justifyContent: "center",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDeleteClientModal(true);
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                  }}
                >
                  Delete
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box
          sx={{
           maxHeight: { md: "calc(100vh - 100px)" },
            height: "95%",
            overflowY: "auto", // Enable vertical scrolling
            "&::-webkit-scrollbar": {
              width: "8px", // Custom scrollbar width
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)", // Custom scrollbar thumb color
              borderRadius: "4px", // Rounded scrollbar thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(170, 170, 170)", // Thumb color on hover
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent", // Custom scrollbar track
            },
          }}
        >

        <Grid container spacing="8px">
          {/* <GoalCard
            user={user}
            clientData={clientData}
            loading={loading}
            setLoading={setLoading}
            fetchClientGoalsData={fetchClientGoalsData}
            goals={goals}
            setGoals={setGoals}
          /> */}
          <LaraClientOverview
            clientName={clientData.full_name}
            client_id={clientData.user_id}
            laraData={laraData}
            loading={loadingLara}
          />
          {/* Left Side - Information */}
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                lg: "flex",
              },
              flexDirection: {
                lg: "column",
              },
            }}
          >
            <Box
              sx={{
                ...boxStyle,
                overflow: "hidden",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                padding="24px"
                gap="20px"
                maxHeight="100%"
                sx={{
                  position: {
                    xs: "relative",
                    lg: "unset",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "28px",
                      color: "black",
                    }}
                  >
                    Actions
                  </Typography>
                  <Button
                    onClick={() => setOpenAddActionModal(true)}
                    sx={{
                      paddingX: "30px",
                      paddingY: "10px",
                      background: "white",
                      border: "1px solid black",
                      color: "black",
                      borderRadius: "43px",
                      display: "flex",
                      alignItems: "center",
                      transition: "all 0.3s",
                      "&:hover": {
                        background: "rgba(242, 242, 242, 0.4)",
                        border: "1px solid #D5D5D5",
                        color: "#6D6D6D",
                      },
                    }}
                    data-cy="add-action-button"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "10px",
                        lineHeight: "1.5",
                        fontWeight: 600,
                        letterSpacing: "2px",
                        textTransform: "uppercase",
                      }}
                    >
                      Add an action
                    </Typography>
                  </Button>
                </Box>
                <Tabs
                  value={tabValue}
                  onChange={handleActionTabChange}
                  TabIndicatorProps={{ style: { backgroundColor: "black" } }}
                  sx={{
                    overflow: "visible",
                    "& .MuiTab-textColorPrimary": {
                      color: "black",
                      fontFamily: "Montserrat",
                    },
                    "& .MuiTab-root.MuiButtonBase-root": {
                      minHeight: { xs: "16px", lg: "32px" },
                      fontSize: { xs: "12px", lg: "14px" },
                      fontWeight: 600,
                    },
                    "& .MuiTabs-flexContainer": {
                      display: "flex",
                      // justifyContent: {
                      //   xs: "space-between!important",
                      //   lg: "flex-start!important",
                      // },
                    },
                    "& .MuiTabs-scroller": {
                      overflowX: {
                        xs: "auto !important",
                        lg: "visible !important",
                      },
                      overflowY: {
                        xs: "visible !important",
                      },
                    },
                  }}
                >
                  <Tab
                    label="To-Do"
                    sx={{
                      letterSpacing: 2,
                      fontSize: 12,
                      fontWeight: 500,
                      fontFamily: "Lexend",
                    }}
                  />
                  <Tab
                    label="Completed"
                    sx={{
                      letterSpacing: 2,
                      fontSize: 12,
                      fontWeight: 500,
                      fontFamily: "Lexend",
                    }}
                  />
                </Tabs>
                <CustomTabPanel value={tabValue} index={0}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      position: "relative",
                      overflowY: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                          background: "#F5F7FF",
                          cursor:
                            selectedActions.length > 0
                              ? "pointer"
                              : "not-allowed",
                        },
                      }}
                      onClick={() => {
                        selectedActions.length === 1 &&
                          setUpdatedAction(selectedActionObjects[0]);
                        selectedActions.length === 1 &&
                          setInitialAction(selectedActionObjects[0]);
                        selectedActions.length === 1 &&
                          setOpenEditActionModal(true);
                      }}
                    >
                      <EditIcon
                        sx={{
                          fill:
                            selectedActions.length === 1 ? "black" : "#B6B6B6",
                        }}
                        color = {selectedActions.length === 1 ? "black" : "#B6B6B6"}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontsize: "12px",
                          lineHeight: "1.66",
                          color:
                            selectedActions.length === 1 ? "black" : "#B6B6B6",
                        }}
                      >
                        Edit
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                          background: "#F5F7FF",
                          cursor:
                            selectedActions.length > 0
                              ? "pointer"
                              : "not-allowed",
                        },
                      }}
                      onClick={() =>
                        selectedActions.length > 0 &&
                        setOpenCompleteActionModal(true)
                      }
                    >
                      <MarkAsCompletedIcon
                        sx={{
                          fill:
                            selectedActions.length > 0 ? "black" : "#B6B6B6",
                        }}
                        color = {selectedActions.length > 0 ? "black" : "#B6B6B6"}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontsize: "12px",
                          lineHeight: "1.66",
                          color:
                            selectedActions.length > 0 ? "black" : "#B6B6B6",
                        }}
                      >
                        Mark as Completed
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                          background: "#F5F7FF",
                          cursor:
                            selectedActions.length > 0
                              ? "pointer"
                              : "not-allowed",
                        },
                      }}
                      onClick={() =>
                        selectedActions.length > 0 &&
                        setOpenDeleteActionModal(true)
                      }
                    >
                      <ActionDeleteIcon
                        sx={{
                          fill: selectedActions.length > 0 ? "#000000" : "#B6B6B6",
                        }}
                        fill={selectedActions.length > 0 ? "black" : "#B6B6B6"} // Pass fill dynamically
                      />

                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontsize: "12px",
                          lineHeight: "1.66",
                          color:
                            selectedActions.length > 0 ? "black" : "#B6B6B6",
                        }}
                      >
                        Delete
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                          background: "#F5F7FF",
                          cursor:
                            selectedActions.length > 0
                              ? "pointer"
                              : "not-allowed",
                        },
                      }}
                      onClick={() =>
                        selectedActions.length > 0 && setOpenDatePicker(true)
                      }
                    >
                      <ActionCalendarIcon
                        sx={{
                          fill:
                            selectedActions.length > 0 ? "black" : "#B6B6B6",
                        }}
                        color = {selectedActions.length > 0 ? "black" : "#B6B6B6"}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontsize: "12px",
                          lineHeight: "1.66",
                          color:
                            selectedActions.length > 0 ? "black" : "#B6B6B6",
                        }}
                      >
                        Assign Due Date
                      </Typography>
                    </Box>
                  </Box>
                  {openDatePicker && (
                    <>
                      <Box
                        sx={{
                          position: "absolute",
                          zIndex: 999,
                          background: "white",
                          borderRadius: "8px",
                          width: "300px",
                          height: "300px",
                          top: "-100px", // Closer to the top of the viewport
                          right: "220px", // Aligned to the right side of the viewport
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticDatePicker
                            defaultValue={dayjs(selectedDate)}
                            onChange={(newValue) => {
                              setSelectedDate(
                                dayjs(newValue).format("YYYY-MM-DD")
                              );
                              setOpenDatePicker(false);
                              setOpenDueDateSetModal(true);
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        sx={{
                          position: "fixed",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 99,
                        }}
                        onClick={() => setOpenDatePicker(false)}
                      />
                    </>
                  )}
                  <Box
                    sx={{
                      maxHeight: "450px",
                      overflowY: {
                        xs: "auto",
                        "&::-webkit-scrollbar": {
                          width: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgb(207, 207, 207)",
                          borderRadius: "4px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          backgroundColor: "rgb(207, 207, 207)",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "transparent",
                        },
                      },
                    }}
                  >
                    <Box
                      display="table"
                      sx={{
                        position: "relative",
                        paddingBottom: "10px",
                        marginTop: "24px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        display="table-row"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          top: 0,
                          background: "white",
                          zIndex: 99,
                          marginLeft: "40px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...tableHeadingStyle,
                            display: "table-cell",
                          }}
                        >
                          Action
                        </Typography>
                        <Box>
                          <Typography
                            sx={{
                              ...tableHeadingStyle,
                              display: "table-cell",
                              minWidth: "100px",
                            }}
                          >
                            Start Date
                            <IconButton
                              aria-label="more"
                              aria-controls={`header-menu-${"todo_start_date"}`}
                              aria-haspopup="true"
                              onClick={(event) =>
                                handleMenuOpen("todo_start_date", event)
                              }
                              // Set the ref property to make IconButton the anchor element
                              ref={anchorEl["todo_start_date"]}
                            >
                              <KeyboardArrowDown
                                sx={{
                                  fill: "#93989A",
                                }}
                              />
                            </IconButton>
                            <Menu
                              id={`header-menu-${"todo_start_date"}`}
                              anchorEl={anchorEl["todo_start_date"]}
                              open={Boolean(anchorEl["todo_start_date"])}
                              onClose={() => handleMenuClose("todo_start_date")}
                              sx={{
                                "& .MuiPaper-root": {
                                  background: "white",
                                  color: "#202226",
                                  borderRadius: "10px",
                                  "& .MuiList-root .MuiMenuItem-root": {
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    lineHeight: "20px",
                                    fontWeight: "500",
                                    color: "#202226",
                                    "&:hover": {
                                      background: "#F5F5F5",
                                    },
                                  },
                                },
                              }}
                            >
                              <Box>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(
                                      "todo_start_date",
                                      "desc"
                                    )
                                  }
                                >
                                  Newest
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(
                                      "todo_start_date",
                                      "asc"
                                    )
                                  }
                                >
                                  Oldest
                                </MenuItem>
                              </Box>
                            </Menu>
                          </Typography>
                          <Typography
                            sx={{
                              ...tableHeadingStyle,
                              display: "table-cell",
                              minWidth: "105px",
                            }}
                          >
                            Due Date
                            <IconButton
                              aria-label="more"
                              aria-controls="header-menu"
                              aria-haspopup="true"
                              onClick={(event) =>
                                handleMenuOpen("todo_end_date", event)
                              }
                            >
                              <KeyboardArrowDown
                                sx={{
                                  fill: "#93989A",
                                }}
                              />
                            </IconButton>
                            <Menu
                              id={`header-menu-${"todo_end_date"}`}
                              anchorEl={anchorEl["todo_end_date"]}
                              open={Boolean(anchorEl["todo_end_date"])}
                              onClose={() => handleMenuClose("todo_end_date")}
                              sx={{
                                "& .MuiPaper-root": {
                                  background: "white",
                                  color: "#202226",
                                  borderRadius: "10px",
                                  "& .MuiList-root .MuiMenuItem-root": {
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    lineHeight: "20px",
                                    fontWeight: "500",
                                    color: "#202226",
                                    "&:hover": {
                                      background: "#F5F5F5",
                                    },
                                  },
                                },
                              }}
                            >
                              <Box>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick("todo_end_date", "desc")
                                  }
                                >
                                  Newest
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick("todo_end_date", "asc")
                                  }
                                >
                                  Oldest
                                </MenuItem>
                              </Box>
                            </Menu>
                          </Typography>
                        </Box>
                      </Box>
                      {todoActionComponent}
                      {completeActionModal()}
                      {dueDateSetModal()}
                      {deleteActionModal()}
                    </Box>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      position: "relative",
                      overflowY: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                          background: "#F5F7FF",
                          cursor:
                            selectedCompletedActions.length > 0
                              ? "pointer"
                              : "not-allowed",
                        },
                      }}
                      onClick={() => {
                        selectedCompletedActions.length === 1 &&
                          setUpdatedAction(selectedCompletedObjects[0]);
                        selectedCompletedActions.length === 1 &&
                          setInitialAction(selectedCompletedObjects[0]);
                        selectedCompletedActions.length === 1 &&
                          setOpenEditActionModal(true);
                      }}
                    >
                      <EditIcon
                        sx={{
                          fill:
                            selectedCompletedActions.length === 1
                              ? "black"
                              : "#B6B6B6",
                        }}
                        color = {selectedCompletedActions.length === 1 ? "black" : "#B6B6B6"}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontsize: "12px",
                          lineHeight: "1.66",
                          color:
                            selectedCompletedActions.length === 1
                              ? "black"
                              : "#B6B6B6",
                        }}
                      >
                        Edit
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                          background: "#F5F7FF",
                          cursor:
                            selectedCompletedActions.length > 0
                              ? "pointer"
                              : "not-allowed",
                        },
                      }}
                      onClick={() =>
                        selectedCompletedActions.length > 0 &&
                        setOpenUndoActionModal(true)
                      }
                    >
                      <Undo
                        sx={{
                          fill:
                            selectedCompletedActions.length > 0
                              ? "black"
                              : "#B6B6B6",
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontsize: "12px",
                          lineHeight: "1.66",
                          color:
                            selectedCompletedActions.length > 0
                              ? "black"
                              : "#B6B6B6",
                        }}
                      >
                        Mark as To-Do
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                          background: "#F5F7FF",
                          cursor:
                            selectedCompletedActions.length > 0
                              ? "pointer"
                              : "not-allowed",
                        },
                      }}
                      onClick={() =>
                        selectedCompletedActions.length > 0 &&
                        setOpenDeleteActionModal(true)
                      }
                    >
                      <ActionDeleteIcon
                        sx={{
                          fill: selectedCompletedActions.length > 0 ? "black" : "#B6B6B6",
                        }}
                        fill={selectedCompletedActions.length > 0 ? "black" : "#B6B6B6"} // Pass fill dynamically
                      />

                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontsize: "12px",
                          lineHeight: "1.66",
                          color:
                            selectedCompletedActions.length > 0
                              ? "black"
                              : "#B6B6B6",
                        }}
                      >
                        Delete
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        "&:hover": {
                          background: "#F5F7FF",
                          cursor:
                            selectedCompletedActions.length > 0
                              ? "pointer"
                              : "not-allowed",
                        },
                      }}
                      onClick={() =>
                        selectedCompletedActions.length > 0 &&
                        setOpenDatePicker(true)
                      }
                    >
                      <ActionCalendarIcon
                        sx={{
                          fill:
                            selectedCompletedActions.length > 0
                              ? "black"
                              : "#B6B6B6",
                        }}
                        color = {selectedCompletedActions.length > 0 ? "black" : "#B6B6B6"}
                      />
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontsize: "12px",
                          lineHeight: "1.66",
                          color:
                            selectedCompletedActions.length > 0
                              ? "black"
                              : "#B6B6B6",
                        }}
                      >
                        Assign Due Date
                      </Typography>
                    </Box>
                  </Box>
                  {openDatePicker && (
                    <>
                      <Box
                        sx={{
                          position: "fixed",
                          zIndex: 999,
                          background: "white",
                          borderRadius: "8px",
                          width: "300px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticDatePicker
                            defaultValue={dayjs(selectedDate)}
                            onChange={(newValue) => {
                              setSelectedDate(
                                dayjs(newValue).format("YYYY-MM-DD")
                              );
                              setOpenDatePicker(false);
                              setOpenDueDateSetModal(true);
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        sx={{
                          position: "fixed",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 99,
                        }}
                        onClick={() => setOpenDatePicker(false)}
                      />
                    </>
                  )}
                  <Box
                    sx={{
                      maxHeight: "450px",
                      overflowY: {
                        xs: "auto",
                        "&::-webkit-scrollbar": {
                          width: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgb(207, 207, 207)",
                          borderRadius: "4px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          backgroundColor: "rgb(207, 207, 207)",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "transparent",
                        },
                      },
                    }}
                  >
                    <Box
                      display="table"
                      sx={{
                        position: "relative",
                        marginTop: "24px",
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom: "10px",
                      }}
                    >
                      <Box
                        display="table-row"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          top: 0,
                          background: "white",
                          zIndex: 99,
                          marginLeft: "40px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...tableHeadingStyle,
                            display: "table-cell",
                          }}
                        >
                          Action
                        </Typography>
                        <Box>
                          <Typography
                            sx={{
                              ...tableHeadingStyle,
                              display: "table-cell",
                            }}
                          >
                            Start Date
                            <IconButton
                              aria-label="more"
                              aria-controls={`header-menu-${"todo_start_date"}`}
                              aria-haspopup="true"
                              onClick={(e) =>
                                handleMenuOpen("todo_start_date", e)
                              }
                            >
                              <KeyboardArrowDown
                                sx={{
                                  fill: "#93989A",
                                }}
                              />
                            </IconButton>
                            <Menu
                              id={`header-menu-${"todo_start_date"}`}
                              anchorEl={anchorEl["todo_start_date"]}
                              open={Boolean(anchorEl["todo_start_date"])}
                              onClose={() => handleMenuClose("todo_start_date")}
                              sx={{
                                "& .MuiPaper-root": {
                                  background: "white",
                                  color: "#202226",
                                  borderRadius: "10px",
                                  "& .MuiList-root .MuiMenuItem-root": {
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    lineHeight: "20px",
                                    fontWeight: "500",
                                    color: "#202226",
                                    "&:hover": {
                                      background: "#F5F5F5",
                                    },
                                  },
                                },
                              }}
                            >
                              <Box>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(
                                      "todo_start_date",
                                      "desc"
                                    )
                                  }
                                >
                                  Newest
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(
                                      "todo_start_date",
                                      "asc"
                                    )
                                  }
                                >
                                  Oldest
                                </MenuItem>
                              </Box>
                            </Menu>
                          </Typography>
                          <Typography
                            sx={{
                              ...tableHeadingStyle,
                              display: "table-cell",
                              minWidth: "105px",
                            }}
                          >
                            Due Date
                            <IconButton
                              aria-label="more"
                              aria-controls="header-menu"
                              aria-haspopup="true"
                              onClick={(event) =>
                                handleMenuOpen("todo_end_date", event)
                              }
                            >
                              <KeyboardArrowDown
                                sx={{
                                  fill: "#93989A",
                                }}
                              />
                            </IconButton>
                            <Menu
                              id={`header-menu-${"todo_end_date"}`}
                              anchorEl={anchorEl["todo_end_date"]}
                              open={Boolean(anchorEl["todo_end_date"])}
                              onClose={() => handleMenuClose("todo_end_date")}
                              sx={{
                                "& .MuiPaper-root": {
                                  background: "white",
                                  color: "#202226",
                                  borderRadius: "10px",
                                  "& .MuiList-root .MuiMenuItem-root": {
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    lineHeight: "20px",
                                    fontWeight: "500",
                                    color: "#202226",
                                    "&:hover": {
                                      background: "#F5F5F5",
                                    },
                                  },
                                },
                              }}
                            >
                              <Box>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick("todo_end_date", "desc")
                                  }
                                >
                                  Newest
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick("todo_end_date", "asc")
                                  }
                                >
                                  Oldest
                                </MenuItem>
                              </Box>
                            </Menu>
                          </Typography>
                        </Box>
                      </Box>
                      {completedActionComponent}
                      {undoActionModal()}
                      {dueDateSetModal()}
                      {deleteActionModal()}
                    </Box>
                  </Box>
                </CustomTabPanel>
              </Box>
            </Box>
          </Grid>

          {/* Right Side - Tabs */}
          <Grid item xs={12} lg={6}>
            <Box sx={boxStyle} padding={{ xs: "16px", lg: "24px" }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                sx={{
                  "& .MuiTabs-scroller": {
                    overflowX: "auto !important",
                  },
                }}
              >
                {/* <Tab sx={tabStyle} disabled label="Activity" {...a11yProps(0)}
                  style={{
                    "&.Mui-disabled": {
                      color: "gray"
                    }
                  }}
                /> */}
                <Tab sx={tabStyle} label="Metrics" {...a11yProps(0)} data-cy="view-metrics"/>
                <Tab sx={tabStyle} label="Sessions" {...a11yProps(0)} data-cy="view-sessions"/>
                <Tab sx={tabStyle} label="Goals" {...a11yProps(0)} data-cy="view-goals"/>
                <Tab sx={tabStyle} label="Assessments" {...a11yProps(0)} data-cy="view-assesstment"/>
                <Tab sx={tabStyle} label="Notes" {...a11yProps(0)} data-cy="view-notes"/>
                <Tab sx={tabStyle} label="Client Info" {...a11yProps(0)} data-cy="view-client-info"/>
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                <MetricsComponent
                  actions={actions}
                  completedActionsSum={completedActionsSum}
                  overdueActionsSum={overdueActionsSum}
                  todoActionsSum={todoActionsSum}
                  sessionsSum={sessionsSum}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <Box
                  sx={{
                    ...tabPanelStyle,
                    maxHeight: "535px",
                    overflowY: {
                      xs: "auto",
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgb(207, 207, 207)",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "rgb(207, 207, 207)",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                      },
                    },
                    paddingRight: {
                      lg: "8px",
                    },
                  }}
                >
                  {Object.keys(callData).map((index) => (
                    <>
                      <Typography
                        sx={{
                          ...tabTextStyle,
                          marginLeft: "24px",
                        }}
                      >
                        {index}
                      </Typography>
                      {callData[index].map((row) => (
                        <Box
                          sx={{
                            paddingX: "24px",
                            paddingY: "16px",
                            borderRadius: "40px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            background: "#F6F6F6",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (row.file_name && row.file_name !== "null")
                              navigate(
                                `/platform/coach/session/${row.file_name}`
                              );
                          }}
                        >
                          <Box display="flex" flexDirection="column" gap="8px">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    lineHeight: "2.6",
                                    letterSpacing: "2",
                                    color: "#202226",
                                  }}
                                >
                                  {row.zoom_date !== null &&
                                    row.zoom_date !== "" &&
                                    dayjs(row.zoom_date)
                                      .utc()
                                      .format("MM.DD.YY")}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    lineHeight: "2.6",
                                    letterSpacing: "2",
                                    color: "#202226",
                                  }}
                                >
                                  {row.duration_minutes} MINS
                                </Typography>
                                {!isNaN(row.clientOverallScore) && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Lexend",
                                        fontSize: "12px",
                                        lineHeight: "1.4",
                                        color: "#202226",
                                        fontWeight: "300",
                                      }}
                                    >
                                      Coachee Score:
                                    </Typography>
                                    <LaraSessionCircle
                                      laraOverallScore={row.clientOverallScore}
                                    />
                                    <Typography
                                      sx={{
                                        fontFamily: "Lexend",
                                        fontSize: "12px",
                                        lineHeight: "1.4",
                                        color: "#202226",
                                        fontWeight: "300",
                                      }}
                                    >
                                      Coach Score:
                                    </Typography>
                                    <LaraSessionCircle
                                      laraOverallScore={row.coachOverallScore}
                                    />
                                  </Box>
                                )}
                              </Box>
                              {clientData.status === "ReadyToPublish" ? (
                                <Box position="relative" width="150px">
                                  <Typography
                                    sx={{
                                      background: "white",
                                      border: "1px solid #116D1F",
                                      borderRadius: "52px",
                                      width: "130px",
                                      letterSpacing: "2px",
                                      fontSize: "10px",
                                      textAlign: "center",
                                      fontFamily: "Lexend",
                                      fontWeight: "500",
                                      padding: "0",
                                      lineHeight: "2.6",
                                      color: "#636467",
                                      textTransform: "uppercase",
                                      cursor: "pointer",
                                      transition: "all 0.3s",
                                      "&:hover": {
                                        background: "#10B02A",
                                        color: "white",
                                        border: "#10B02A",
                                      },
                                    }}
                                  >
                                    Processed
                                  </Typography>
                                </Box>
                              ) : clientData.status === "processing" ? (
                                <Typography
                                  sx={{
                                    background: "white",
                                    border: "1px solid #116D1F",
                                    borderRadius: "52px",
                                    width: "130px",
                                    letterSpacing: "2px",
                                    width: "130px",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    fontFamily: "Lexend",
                                    fontWeight: "500",
                                    padding: "0",
                                    lineHeight: "2.6",
                                    color: "#636467",
                                    textTransform: "uppercase",
                                    cursor: "pointer",
                                    transition: "all 0.3s",
                                    "&:hover": {
                                      background: "#EDFAED",
                                      color: "#116D1F",
                                      border: "#EDFAED",
                                    },
                                  }}
                                >
                                  Processing
                                </Typography>
                              ) : clientData.status === "incomplete" ? (
                                <Typography
                                  sx={{
                                    background: "white",
                                    border: "1px solid #B52100",
                                    borderRadius: "52px",
                                    width: "130px",
                                    letterSpacing: "2px",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                    cursor: "pointer",
                                    fontFamily: "Lexend",
                                    fontWeight: "500",
                                    padding: "0",
                                    lineHeight: "2.6",
                                    color: "#636467",
                                    transition: "all 0.3s",
                                    "&:hover": {
                                      background: "#CE3F3F",
                                      color: "#FFD9D9",
                                      borderColor: "#B52100",
                                    },
                                  }}
                                >
                                  Unprocessed
                                </Typography>
                              ) : (
                                <Box
                                  sx={{
                                    background: "white",
                                    position: "relative",
                                    border: "1px solid #50B500",
                                    borderRadius: "43px",
                                    width: "auto",
                                    textAlign: "center",
                                    padding: "0",
                                    textTransform: "uppercase",
                                    cursor: "pointer",
                                    transition: "all 0.3s",
                                    "&:hover": {
                                      background: "rgba(80, 181, 0, 0.4)",
                                      backgroundOrigin: "border-box",
                                      border: "rgba(80, 181, 0, 0.4)",
                                    },
                                    "&:hover p": {
                                      color: "white",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Lexend",
                                      paddingX: "30px",
                                      paddingY: "4px",
                                      fontWeight: "bold",
                                      color: "#50B500",
                                      lineHeight: "2.6",
                                      letterSpacing: "2px",
                                      fontSize: "10px",
                                      transition: "all 0.3s",
                                    }}
                                  >
                                    Session
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontSize: "22px",
                                lineHeight: "1.35",
                                color: "black",
                                fontWeight: "500",
                              }}
                            >
                              {row.call_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontSize: "14px",
                                lineHeight: "1.4",
                                color: "#202226",
                                fontWeight: "300",
                              }}
                            >
                              {row.quick_recap}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </>
                  ))}
                </Box>
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <GoalCard
                  user={user}
                  clientData={clientData}
                  loading={loading}
                  setLoading={setLoading}
                  fetchClientGoalsData={fetchClientGoalsData}
                  goals={goals}
                  setGoals={setGoals}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={3}>
                <Assessments user={user} userData={userData} clientData={clientData} loading={loading} setLoading={setLoading}/>
              </TabPanel>
              <TabPanel value={tabIndex} index={4}>
                <NoteComponent />
              </TabPanel>
              <TabPanel value={tabIndex} index={5}>
                <ClientComponent />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
        </Box>
      </Box>
      {addActionModal}
    </GlobalPageComponent>
  );
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  border: "1px solid #F3F3F3",
  borderRadius: "40px",
  backgroundColor: "white",
  height: "650px",
};

const subHeadingStyle = {
  color: "black",
  fontWeight: "500",
  fontSize: "18px",
  fontFamily: "Montserrat",
  lineHeight: "135%",
};

const tableHeadingStyle = {
  color: "black",
  fontWeight: "400",
  fontSize: {
    xs: "12px",
    lg: "14px",
  },
  fontFamily: "Montserrat",
  lineHeight: "135%",
};

const textStyle = {
  color: "#202226",
  fontWeight: "400",
  fontSize: "12px",
  fontFamily: "Montserrat",
};

const tabTextStyle = {
  color: "black",
  fontWeight: "600",
  fontSize: "18px",
  fontFamily: "Montserrat",
};

const tabStyle = {
  color: "black",
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 1.5,
  fontFamily: "Lexend",
  borderBottom: "1px solid #E8E8E8",
  "&.Mui-selected": {
    borderBottom: "1px solid #000",
  },
};

const tabPanelStyle = {
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
};

export default ClientOverview;

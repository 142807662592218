import { Box, Typography } from "@mui/material";
import SuperAdmin from "components/AdminPortal/SuperAdmin";
import Admin from "components/AdminPortal/Admin";
import User from "components/AdminPortal/User";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useSelector } from "react-redux";
import Owner from "../../../components/AdminPortal/Owner";

const AdminPortal = ({ user, userData }) => {
    console.log("user data in admin portal: ", userData)
    const collapsed = useSelector((state) => state.layout.collapsed);
    return(
        <GlobalPageComponent user={user} accessTo={userData.accessTo}>
            <Box
                sx={{
                backgroundColor: "#EFF3F6",
                width: "100%",
                padding: {
                    xs: "16px",
                    md: "32px 40px",
                },
                display: {
                    xs: !collapsed ? "none" : "block",
                },
                overflowY: "auto",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%"
                    }}
                >
                    <Typography
                        sx={{
                        color: "black",
                        fontFamily: "Poppins",
                        fontSize: "36px",
                        fontWeight: 300,
                        marginBottom: "16px",
                        }} 
                        data-cy="admin-portal-title"
                    >
                        Admin Portal
                    </Typography>
                </Box>
                {user?.role === 'OWNER' ? (
                    <Owner user={user} userData={userData} />
                ) : user?.role === 'SUPERADMINS' ? (
                    <SuperAdmin user={user} userData={userData} />
                ) : user?.role === 'ADMINS' ? (
                    <Admin user={user} userData={userData} />
                ) : user?.role === 'USERS' ? (
                    <User user={user} userData={userData} />
                ) : (
                    null
                )}
            </Box>
        </GlobalPageComponent>
    )
}

export default AdminPortal;
import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "components/Icons/Delete.svg";
import RefreshIcon from "components/Icons/Refresh.svg";
import BlockIcon from '@mui/icons-material/Block';
import { useState } from "react";

const ToolBar = ({ user, selected, fetchCompanyData, openDeleteModal }) => {
    const [isRefreshHovered, setIsRefreshHovered] = useState(false);
    const [isDeleteHovered, setIsDeleteHovered] = useState(false);
    const [isDeactivateHovered, setIsDeactivateHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // State for loading indication

    const handleRefresh = async () => {
        setIsLoading(true); // Set loading state to true
        await fetchCompanyData(); // Call the function to fetch data
        setIsLoading(false); // Set loading state to false after fetching
    };


    return(
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center"
                }}
            >
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    transition: "all 0.3s",
                    cursor: "pointer",
                    }}
                    onMouseEnter={() => setIsRefreshHovered(true)}
                    onMouseLeave={() => setIsRefreshHovered(false)}
                    onClick={handleRefresh}
                >
                    {isRefreshHovered === false ? (
                    <>
                        <RefreshIcon color="enabled" />
                        <Typography
                        sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "#2D2D2D",
                        }}
                        >
                        Refresh
                        </Typography>
                    </>
                    ) : (
                    <>
                        <RefreshIcon color="hovered" />
                        <Typography
                        sx={{
                            background:
                            "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                            backgroundOrigin: "border-box",
                            backgroundClip: "text",
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "20px",
                            color: "transparent",
                        }}
                        >
                        Refresh
                        </Typography>
                    </>
                    )}
                </Box>
                {(user.role === 'SUPERADMINS' || user.role === 'OWNER') && (
                <>
                    <Box
                        sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        transition: "all 0.3s",
                        cursor: selected.length > 0 ? "pointer" : "not-allowed",
                        }}
                        onMouseEnter={() => setIsDeleteHovered(true)}
                        onMouseLeave={() => setIsDeleteHovered(false)}
                        onClick={() => {
                            if (selected.length > 0) {
                              openDeleteModal(); // Trigger the delete modal
                            }
                        }}
                    >
                        {selected.length > 0 ? (
                        isDeleteHovered === false ? (
                            <>
                            <DeleteIcon color="enabled" />
                            <Typography
                                sx={{
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#2D2D2D",
                                }}
                            >
                                Delete
                            </Typography>
                            </>
                        ) : (
                            <>
                            <DeleteIcon color="hovered" />
                            <Typography
                                sx={{
                                background:
                                    "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                backgroundOrigin: "border-box",
                                backgroundClip: "text",
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "transparent",
                                }}
                            >
                                Delete
                            </Typography>
                            </>
                        )
                        ) : (
                        <>
                            <DeleteIcon color="disabled" />
                            <Typography
                            sx={{
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#B6B6B6",
                            }}
                            >
                            Delete
                            </Typography>
                        </>
                        )}
                    </Box>
                    </>
                )}
            </Box>
        </>
    );
}

export default ToolBar;

import { useEffect, useState } from "react";
import { fetchUserAttributes, getCurrentUser, updateUserAttribute } from 'aws-amplify/auth'
import { Box, Typography, Input, Button, CircularProgress, TextField, FormControlLabel, Checkbox, List, ListItem} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import Lottie from "react-lottie";
import * as animationData from "data/lottieAnimation.json";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { Check } from "@mui/icons-material";
import axios from "axios";

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Source Sans Pro", "sans-serif"].join(","),
  },
  headerImage: "",
};

export const theme = createTheme(themeOptions);

const ClientAcceptForm = ({user, userData}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [info, setInfo] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [emailsMatch, setEmailsMatch] = useState(false)


  const [token_data, setTokenData] = useState({
    email: "",
    coach_id: "",
    firstName: "",
    lastName: "",
    invite_id: "",
    company_id: "",
    company_name: "",
    role: "",
    role_id: "",
  });

  const handleFormSubmit = async (values) => {
    try {
        setLoading(true);
        await getCurrentUser(); // Retrieve the current authenticated user
        const user = fetchUserAttributes();
        
 const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/existing-client-accept-invite_test`, {
          params: {
            user_id: user?.sub,
            client_first_name: token_data.firstName,
            client_last_name: token_data.lastName,
            client_email: token_data.email,
            old_client_id: queryParams.get("client_id"),
            coach_id: token_data.coach_id,
            stripe_customer_id: userData?.paymentInfo?.stripeCustomerId,
            access_to: userData?.accessTo,
            invite_id: token_data.invite_id,
            role: token_data.role,
            role_id: token_data.role_id,
            company_id: token_data.company_id
          }
        });
        
        await updateUserAttribute({
          userAttribute: {
            attributeKey: "custom:is_coachee",
            value: "true"
          }
        });
        toast(`Invite has been accpeted. Preparing your dashboard...`, {
            hideProgressBar: true,
            style: {
              background: "white",
              boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
              borderRadius: "8px",
              padding: "16px 28px",
              color: "#160042",
            },
            icon: () => <Check color="success" />,
          });
        navigate("/authenticating");
    } catch (err) {
        toast.error(err.message, {
          hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
        });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/verify-token`,
          {
            params: {
              token: queryParams.get("token"),
            },
          }
        );
  
        if (response?.data?.decoded) {
          const {
            client_email,
            coach_id,
            info,
            client_first_name,
            client_last_name,
            invite_id,
            company_id,
            company_name,
            role,
            role_id
          } = response.data.decoded;

          console.log("token decoded: ", response.data.decoded)
  
          setInfo(info);
          setTokenData({
            email: client_email,
            coach_id: coach_id,
            firstName: client_first_name,
            lastName: client_last_name,
            invite_id: invite_id,
            company_id: company_id,
            company_name: company_name,
            role: role,
            role_id: role_id
          });
          setIsVerified(true);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
      }
    })();
  }, [queryParams]);
  
  useEffect(() => {
    if (isVerified && token_data && user) {
      if (user?.email.toLowerCase() === token_data.email.toLowerCase()) {
        setEmailsMatch(true);
      } else {
        setEmailsMatch(false);
      }
    } else {
      setEmailsMatch(false);
    }
  }, [isVerified, token_data, user]);
  



  return (
    <>
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            flex: {
              md: "50%",
            },
            marginLeft: {md: "40px"},
            alignContent: "center",
            justifyContent: "center"
          }}
        >
        {!user ? (
        <>
            <Typography
                sx={{
                    fontSize: "30px",
                    lineHeight: "1.17",
                    letterSpacing: "-0.02em",
                    color: "#22272F",
                }}
            >
                Please sign in and open your invitation link to accept.
            </Typography>
        <Box
            sx={{
              fontFamily: "Inter",
              fontSize: "20px",
              lineHeight: "1.5",
              color: "#454F5F",
              marginTop: "10px"
            }}
          >
            <Typography
              onClick={() => navigate("/sign-in")}
              style={{
                fontSize: "20px",
                textDecoration: "underline",
                cursor: "pointer",
                color: "#8E86C1",
              }}
              component={"span"}
            >
              Sign in
            </Typography>
          </Box>
          </>
        ) : (
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={{ email: "", password: "", acknowledgement: false }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                submitForm,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}>
                  <Box
                    color="black"
                    display= "flex"
                    position={"relative"}
                    zIndex={1}
                    maxWidth="400px"
                    justifyContent= "center"
                    alignItems={"center"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "14px",
                        marginBottom:"20px",
                        justifyContent: "center"
                        
                      }}
                    >
                    <Typography
                       sx={{
                        fontSize: "48px",
                        lineHeight: "1.17",
                        letterSpacing: "-0.02em",
                        color: "#22272F",
                      }}
                    >
                      Accept Invitation
                    </Typography>
                    <Typography
                       sx={{
                        fontSize: "18px",
                        lineHeight: "1.17",
                        letterSpacing: "-0.02em",
                        color: "#22272F",
                      }}
                    >
                        Begin your journey with your coach today!
                    </Typography>
                    <Typography
                       sx={{
                        fontSize: "18px",
                        lineHeight: "1.17",
                        letterSpacing: "-0.02em",
                        color: "#22272F",
                      }}
                    >
                      Gain access to innovative features such as:
                      <List>
                        <ListItem>- Virtual Coach</ListItem>
                        <ListItem>- Analysis on coaching sessions</ListItem>
                        <ListItem>- Action tracking</ListItem>
                        <ListItem>- Goal tracking</ListItem>
                      </List>
                    </Typography>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={values.acknowledgement}
                            color="primary"
                            InputProps={{
                            style: {
                                color: "black",
                            },
                            }}
                            InputLabelProps={{
                            style: {
                                color: "black",
                            },
                            }}
                            sx={{
                            "& fieldset": {
                                borderColor: "black !important",
                            },
                            "& svg": {
                                color: "black",
                            },
                            }}
                            onChange={() => {
                            setFieldValue(
                                "acknowledgement",
                                !values.acknowledgement
                            );
                            }}
                        />
                        }
                        label={
                        <span
                            style={{
                            fontSize: "14px",
                            color: "black",
                            marginRight: "10px",
                            display: "flex",
                            gap: "10px",
                            }}
                        >
                            {userData?.accessTo === 'virtual_coach' 
                            ? 'I acknowledge that by accepting this invitation my current subscription will be cancelled and replaced with complementary access from your coach.'
                            : 'I accept this invitation and understand my current subscription will continue.'
                          }
                        </span>
                        }
                    />
                    </Box>
                  </Box>

                    <Button
                      sx={{
                        marginTop: "32px",
                        width: "50%",
                        padding: "18px 0",
                        background: "white",
                        border: "1px solid black",
                        borderRadius: "43px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        transition: "all 0.3s",
                        "&:hover": {
                          background: "black",
                          "& *": {
                            color: "white",
                          },
                        },
                        "&:disabled": {
                          background: "#d3d3d3", 
                          color: "rgba(0, 0, 0, 0.26)",
                          border: "none",
                          cursor: "not-allowed"
                        },
                      }}
                      disabled={!values.acknowledgement || loading || isSubmitting || !isVerified || !user || !emailsMatch}
                      onClick={submitForm}
                    >
                      {loading ? (
                        <CircularProgress size={20} sx={{marginRight: "5px", marginBottom: "-3px", color: "black"}} /> 
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "600",
                            letterSpacing: "0.4px",
                            textTransform: "uppercase",
                          }}
                        >
                          Accept
                        </Typography>
                      )}
                    </Button>
                    {isVerified && !emailsMatch && (
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            <Typography
                            sx={{
                                fontFamily: "Montserrat",
                                marginTop: "10px",
                                color: "red",
                                fontSize: "10px",
                                fontWeight: "600",
                                letterSpacing: "0.4px",
                                textTransform: "uppercase",
                            }}
                            >
                            Emails do not match, please sign in with correct email.
                            </Typography>
                            <Typography
                                onClick={() => navigate("/sign-in")}
                                style={{
                                    fontSize: "14px",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    color: "#8E86C1",
                                }}
                                component={"span"}
                                >
                                Sign in
                            </Typography>
                        </Box>
                    )}
                </Form>
              )}
            </Formik>
        )}
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
            flex: {
              lg: "50%",
            },
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width="100%"
            height="auto"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Box>
      </ThemeProvider>
    </>
  );
};

export default ClientAcceptForm;

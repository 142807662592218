import { Modal, Box, Button, TextField, Typography, Chip, Grid, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import AddIcon from '@mui/icons-material/Add'; // Import Add Icon
import { toast } from "react-toastify";

import { generateClient } from "aws-amplify/data";
import { KeyboardArrowDown } from "@mui/icons-material";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const OwnerAddMemberModal = ({ user, userData, openAddMemberModal, setOpenAddMemberModal, companyInformation, teamsInformation, fetchCompanyData, companyMembers }) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [customTeamName, setCustomTeamName] = useState('');
    const [title, setTitle] = useState('')
    const [invites, setInvites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [companyId, setCompanyId] = useState(userData?.companyInformation?.company_id)
    const [errorMessage, setErrorMessage] = useState('');


    const CustomIcon = (props) => (
        <KeyboardArrowDown {...props} style={{ color: 'black' }} />
      );

    useEffect(() => {
        setCompanyId(userData?.companyInformation?.company_id);
    }, [userData])

    // Add invite function
    const handleAddInvite = () => {
        if (!firstName || !lastName) {
            setErrorMessage("Please provide a first and last name");
            return;
        }
        if (email && validateEmail(email)) {
            const emailExists = invites.some(invite => invite.email === email.trim());
            const emailExistsInCompanyMembers = companyMembers.some(member => member.email === email.trim());
  
            if (emailExists) {
                setErrorMessage("Email has already been added");
                return;
            }

            if (emailExistsInCompanyMembers) {
                setErrorMessage("A member already exists with this email");
                return;
            }

            // Check if selectedTeam is 'custom' and customTeamName is not empty
            if (selectedTeam === 'custom') {
                if (customTeamName.trim() === '') {
                    setErrorMessage('Custom team name cannot be empty.');
                    return; // Exit if custom team name is empty
                }
    
                // Check for duplicate custom team name in teamsInformation or invites
                const teamExists = teamsInformation.some(team => team.team_name.toLowerCase() === customTeamName.trim().toLowerCase());
                const customTeamExistsInInvites = invites.some(invite => invite.selectedTeam.toLowerCase() === customTeamName.trim().toLowerCase());
                if (teamExists || customTeamExistsInInvites) {
                    setErrorMessage('A team with this name already exists. Please choose a different name.');
                    return; // Exit if team name already exists
                }
            }

            // Prevent adding an admin without selecting a team
            if (isAdmin && !selectedTeam) {
                setErrorMessage('Cannot be marked as admin without selecting a team.');
                setIsAdmin(false)
                return;
            }
    
            // Add invite to the list
            setInvites([...invites, { 
                email: email.trim(), 
                firstName: firstName.trim(), 
                lastName: lastName.trim(), 
                title: title ? title.trim() : "",
                isAdmin, 
                selectedTeam: selectedTeam === 'custom' ? customTeamName : selectedTeam 
            }]);
            
            // Clear input fields
            setEmail('');
            setFirstName('');
            setLastName('');
            setSelectedTeam('');
            setTitle('');
            setCustomTeamName('');
            setErrorMessage('')
            setIsAdmin(false);
        }
    };
    

    const handleCloseAddMemberModal = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
        setIsAdmin(false);
        setSelectedTeam('');
        setCustomTeamName('');
        setTitle('');
        setErrorMessage('');
        setInvites([]);
        setOpenAddMemberModal(false);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleDeleteInvite = (inviteToDelete) => () => {
        setInvites(invites.filter(invite => invite.email !== inviteToDelete.email));
    };

    const handleInvite = async () => {
        try {

            const {data: responseSeats, errors} = await resourceClient.queries.checkCoacheeInviteAllowance({
                userID: user?.sub
              })
        
            const availableSeats = (responseSeats.coachee_seat_tier_allowance - responseSeats.coachee_seat_count);

            if (availableSeats < 1) {
                toast.error("Not enough seats available. Please upgrade your seat count to continue.");
                return; // Stop execution if no seats are available
            }
    
            if (invites.length > availableSeats) {
                toast.error(`You are trying to invite ${invites.length} members, but only ${availableSeats} seat(s) are available.`);
                return; // Stop execution if invites exceed available seats
            }
            setLoading(true);
            const body = {
                user_id: user?.sub,
                user_group: user?.role,
                user_full_name: user?.given_name + " " + user?.family_name,
                user_email: user?.email,
                invites: invites,
                company_id: companyId,
                company_name: companyInformation.company_name || "",
            };
            await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/add-members-to-company`, body);

            for (const invite of invites) {
                const response = await resourceClient.mutations.incrementCoacheeSeatCount({
                    user_id: user?.sub
                });
    
                console.log(`Seat count incremented for invite to: ${invite.email}`);
            }

            toast.success(`${invites.length} invitation${invites.length === 1 ? '' : 's'} sent successfully`);

            await fetchCompanyData();
        } catch (error) {
            console.log("error in sending invites: ", error);
        } finally {
            handleCloseAddMemberModal();
            setLoading(false);
        }
    };

    return (
        <Modal
            open={openAddMemberModal}
            onClose={handleCloseAddMemberModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "white",
                    color: "black",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    maxWidth: "calc(100% - 40px)",
                    maxHeight: {
                        xs: "calc(100% - 50px)",
                        md: "80%",
                    },
                    overflowY: "hidden",
                    boxShadow: 24,
                    borderRadius: "30px",
                    padding: {
                        xs: "24px",
                        md: "64px",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography sx={{ color: 'black', fontSize: 36, fontFamily: 'Lexend', fontWeight: '300' }}>
                        Add Members
                    </Typography>
                    <Typography sx={{ color: '#828282', fontSize: 16, fontFamily: 'Lexend', fontWeight: '500' }}>
                        Press + to add the user.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        overflowY: "auto", // Add scrolling to this section
                        flexGrow: 1, // Ensure this section grows to fill available space
                        marginBottom: "16px", // Ensure some margin before the footer
                        paddingRight: '16px', // For scrollbar padding (optional)
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Typography
                                sx={{
                                color: "black",
                                textTransform: "uppercase",
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                fontWeight: 700,
                                letterSpacing: 2
                                }}
                            >
                                First Name
                            </Typography>
                            <TextField
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                fullWidth
                                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                                InputProps={{
                                    style: { color: "black", borderColor: "1px #C4CAD4 solid", borderRadius: 16 },
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '1px #C4CAD4 solid' },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Typography
                                sx={{
                                color: "black",
                                textTransform: "uppercase",
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                fontWeight: 700,
                                letterSpacing: 2
                                }}
                            >
                                Last Name
                            </Typography>
                            <TextField
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                fullWidth
                                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                                InputProps={{
                                    style: { color: "black", borderColor: "1px #C4CAD4 solid", borderRadius: 16 },
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '1px #C4CAD4 solid' },
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Typography
                                    sx={{
                                    color: "black",
                                    textTransform: "uppercase",
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    letterSpacing: 2
                                    }}
                                >
                                    Email
                            </Typography>
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                                InputProps={{
                                    style: { color: "black", borderColor: "1px #C4CAD4 solid", borderRadius: 16 },
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '1px #C4CAD4 solid' },
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Typography
                                sx={{
                                color: "black",
                                textTransform: "uppercase",
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                fontWeight: 700,
                                letterSpacing: 2
                                }}
                            >
                                Team
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={selectedTeam}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setSelectedTeam(value);
                                        if (value !== "custom") {
                                        setCustomTeamName('');
                                        }
                                    }}
                                    IconComponent={CustomIcon}
                                    displayEmpty
                                    inputProps={{
                                        style: { color: "#4F4F4F" },
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                          style: {
                                            backgroundColor: 'white',
                                            maxHeight: 300,
                                            overflow: 'auto',
                                            maxWidth: '20%',
                                            width: '20%',
                                          },
                                        },
                                      }}
                                      sx={{
                                        color: "black",
                                        background: "white",
                                        borderRadius: "16px",
                                        border: "1px solid #C4CAD4",
                                        "&:before, &:after": {
                                          display: "none",
                                        },
                                        "& fieldset": {
                                          border: "none !important",
                                        },
                                      }}
                                    >
                                    {teamsInformation &&
                                        [...new Set([...teamsInformation.map(team => team.team_name), ...invites.map(invite => invite.selectedTeam)])]
                                        .filter(teamName => teamName) // Filter out any undefined/null team names
                                        .map((teamName, index) => (
                                            <MenuItem
                                            key={index}
                                            value={teamName}
                                            disabled={
                                                // Disable if:
                                                // 1. The team already has an admin and the "isAdmin" checkbox is selected, OR
                                                // 2. There is already an invite for this team with an admin selected in the invites list
                                                (isAdmin && teamsInformation.find(team => team.team_name === teamName)?.admin_id) ||
                                                (invites.some(invite => invite.selectedTeam === teamName && invite.isAdmin) && isAdmin)
                                            }
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                "&:hover": {
                                                  background: "#F7F7F7",
                                                  cursor: "pointer"
                                                },
                                              }}
                                            >
                                            <Typography
                                                sx={{
                                                color: "black",
                                                fontSize: "12px",
                                                whiteSpace: "normal",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word"
                                                }}
                                            >
                                                {teamName}
                                            </Typography>
                                            </MenuItem>
                                        ))}
                                    <MenuItem value="custom"
                                        sx={{
                                            background: "white",
                                            color: "black",
                                            "&:hover": {
                                              background: "#F7F7F7",
                                              cursor: "pointer"
                                            },
                                          }}
                                    >
                                        <Typography
                                                sx={{
                                                color: "black",
                                                fontSize: "12px",
                                                whiteSpace: "normal",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word"
                                                }}
                                            >
                                                Create New Team
                                        </Typography>
                                    </MenuItem>
                                    </Select>
                            </FormControl>
                        </Grid>

                        {selectedTeam === "custom" && (
                            <Grid item xs={12}>
                                <TextField
                                    placeholder="Enter Custom Team Name"
                                    value={customTeamName}
                                    onChange={(e) => setCustomTeamName(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                                    InputProps={{
                                        style: { color: "black", borderColor: "1px #C4CAD4 solid", borderRadius: 16 },
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '1px #C4CAD4 solid' },
                                        },
                                    }}
                                />
                            </Grid>
                        )}
                            <Grid item xs={12} sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                                <Typography
                                    sx={{
                                    color: "black",
                                    textTransform: "uppercase",
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    letterSpacing: 2
                                    }}
                                >
                                    Title
                                </Typography>
                                <TextField
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                                    InputProps={{
                                        style: { color: "black", borderColor: "1px #C4CAD4 solid", borderRadius: 16 },
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#C4CAD4' },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '1px #C4CAD4 solid' },
                                        },
                                    }}
                                />
                            </Grid>

                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/* Admin Checkbox */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{ color: "black", marginLeft: "12px" }}
                                        checked={isAdmin}
                                        onChange={() => setIsAdmin(!isAdmin)}
                                        disabled={
                                            selectedTeam && (
                                                teamsInformation.find(team => team.team_name === selectedTeam)?.admin_id ||
                                                invites.some(invite => invite.selectedTeam === selectedTeam && invite.isAdmin)
                                            )
                                        }
                                    />
                                }
                                label="Mark as Admin"
                            />

                            {/* Plus Icon Button to Add Invite */}
                            <IconButton
                                color="primary"
                                onClick={handleAddInvite} // Call add invite function
                            >
                                <AddIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography sx={{color: "red", fontSize: "12px", fontFamily: "Lexend"}}>
                                {errorMessage ?? ""}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Display Invited Users */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '16px' }}>
                        {invites.length > 0 && invites.map((invite, index) => (
                            <Chip
                                key={index}
                                label={`${invite.firstName} ${invite.lastName} (${invite.email}) - ${invite.isAdmin ? "Admin for" : invite.title} ${invite.selectedTeam}`}
                                onDelete={handleDeleteInvite(invite)}
                                color="primary"
                                sx={{
                                    fontSize: 14,
                                    backgroundColor: "#F1F1F1",
                                    color: "black",
                                    overflowX: "auto"
                                }}
                            />
                        ))}
                    </Box>
                </Box>

                {/* Invite Button */}
                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "24px" }}>
                    <ConfirmButton 
                        onClick={handleInvite}
                        label={"Send"}
                        disabled={invites.length < 1 || loading || !companyId}
                        loading={loading}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default OwnerAddMemberModal;

/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Modal,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  tooltipClasses,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Menu,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
// import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import _CallDataGrid from "components/CallDataGrid";
import _Select from "components/Select";
import {
  _CallListFilterSelect,
  _CallListFilterMultiSelect,
  _CallListFilterDateRange,
} from "components/CallListFilter";
import _DataGrid from "components/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { setSession } from "slices/SessionSlice";
import { GridApiRef, useGridApiRef } from "@mui/x-data-grid";
import AssignIcon from "components/Icons/Assign.svg";
import ShareIcon from "components/Icons/Share.svg";
import RefreshIcon from "components/Icons/Refresh.svg";
import DeleteIcon from "components/Icons/Delete.svg";
import axios from "axios";
import { generateClient } from 'aws-amplify/data';
 /**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
import {
  CloseOutlined,
  KeyboardArrowDown,
  ErrorOutline,
  Check,
  PersonAddAlt,
} from "@mui/icons-material";
import zoom from "assets/zoom.png";
import { toast } from "react-toastify";
import { getFormattedDate, toDateFormat } from "helpers/DateHelper";
import avatar from "assets/avatar.png";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import PersonalTierUpgrade from "components/CoachClientCenter/PersonalTierUpgrade";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import utc from 'dayjs/plugin/utc';
import { getCallsByCoachID } from "slices/CoachSlice";
import { getCallsByClientID } from "slices/ClientSlice";
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(utc);



const tabProps = {
  iconPosition: "start",
  sx: {
    flexDirection: "row",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "none",
    fontFamily: "Montserrat",
  },
};

const tabMobileProps = {
  iconPosition: "start",
  sx: {
    flexDirection: "row",
    fontSize: "9px",
    fontWeight: 600,
    textTransform: "none",
    fontFamily: "Montserrat",
  },
};

const Team = ({
  fetchData,
  onCallStatusChange,
  user,
  userDataInfo,
  isProfessionalTier,
  fetchSum,
  accessTo,
  memberData
}) => {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(true);
  const [isLoadingProcessing, setLoadingProcessing] = useState(false);
  const [zoomLoading, setZoomLoading] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const loadingTitle = "Your call is processing. This can take up to 3 mins.";
  const [selected, setSelected] = useState([]);
  const [oldSelection, setOldSelection] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [assignedModal, setAssignedModal] = useState(false);
  const [processModal, setProcessModal] = useState(false);
  const [processedLength, setProcessedLength] = useState(0);
  const [callIds, setCallIds] = useState([]);
  const [userData, setUserData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [unprocessedData, setUnprocessedData] = useState([]);
  const [clientData, setClient] = useState([]);
  const [assignedData, setAssignedData] = useState([]);
  const [showClientList, setShowClientList] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [lastAction, setLastAction] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [client, setAssignClient] = useState({});
  const [processedClient, setProcessedClient] = useState(false);
  const [showCallActionList, setShowCallActionList] = useState(false);
  const [assignee, setAssignee] = useState({});
  const [unprocessModal, setUnprocessModal] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  const [filterParticipant, setFilterParticipant] = useState("none");
  const [filterParticipantKey, setFilterParticipantKey] =
    useState("Participant");
  const [filterClient, setFilterClient] = useState("none");
  const [filterStatus, setFilterStatus] = useState(["none"]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterCoach, setFilterCoach] = useState("none");
  const [filterCoachItems, setFilterCoachItems] = useState([
    { key: "Coach", value: "none" },
  ]);
  const [filterParticipantItems, setFilterParticipantItems] = useState([
    { key: "Participant", value: "none" },
  ]);
  const [filterClientItems, setFilterClientItems] = useState([
    { key: "Coachee", value: "none" },
  ]);
  const [filterStatusItems, setFilterStatusItems] = useState([
    {
      key: "Session Status",
      value: "none",
    },
    {
      key: "Unloaded Insights",
      value: "incomplete",
    },
    {
      key: "Viewable Insights",
      value: "ReadyToPublish",
    },
    {
      key: "Shared Sessions",
      value: "assigned",
    },
  ]);
  const [isAssignHovered, setIsAssignHovered] = useState(false);
  const [isShareHovered, setIsShareHovered] = useState(false);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);
  const [isDeleteHovered, setIsDeleteHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const dispatch = useDispatch();
  const coachCallData = useSelector((state) => state.coach.data);
  const clientCallData = useSelector((state) => state.client.data);
  const [gridHeight, setGridHeight] = useState(0);
  const [selectedSortItem, setSelectedSortItem] = useState("");
  const [selectedSortDirection, setSelectedSortDirection] = useState("");
  const headerHeight = 56;
  const rowHeight = 52;
  const handleSubscriptionClick = () => {
    window.open(
      "https://billing.stripe.com/p/login/dR6eWJ2bkfuobja000",
      "_blank"
    );
  };
  const customToast = ({ closeToast }) => (
    <div onClick={handleSubscriptionClick} style={{ cursor: "pointer" }}>
      Executive Tier users cannot assign calls. Please{" "}
      <span
        style={{
          color: "#007bff",
          textDecoration: "underline",
          cursor: "pointer",
          transition: "color 0.3s",
        }}
        onMouseOver={(e) => {
          e.target.style.color = "#0000FF";
        }}
        onMouseOut={(e) => {
          e.target.style.color = "#007bff";
        }}
      >
        upgrade
      </span>{" "}
      to enable sharing.
    </div>
  );

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleModalClose = () => setModalOpen(false);
  const openDeleteModal = () => setDeleteModal(true);
  const closeDeleteModal = () => setDeleteModal(false);
  const openAssignModal = () => {
    setShowClientList(false);
    setAssignClient(assignee);
    setAssignModal(true);
  };
  const closeAssignModal = () => setAssignModal(false);
  const openAssignedModal = () => setAssignedModal(true);
  const closeAssignedModal = () => setAssignedModal(false);
  const openProcessModal = () => {
    setProcessModal(true);
  };
  const closeProcessModal = () => setProcessModal(false);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 700,
    },
  })); 

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        style={{
          position: "relative",
        }}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const handleResetFilters = () => {
    setFilterParticipant("none");
    setFilterParticipantKey("Participant");
    setFilterClient("none");
    setFilterStatus(["none"]);
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterCoach("none");
  };


  
    useEffect(() => {
    if (user) {
      dispatch(getCallsByCoachID({ coachId: user?.sub }));
      dispatch(getCallsByClientID({ clientId: user?.sub }))
    }
  }, [user, dispatch]);

  const isRowSelectable = (rowParams) => {
    const { row } = rowParams;
    return row.status !== "processing" && row.client_id !== user?.sub;
  };
  

  useEffect(() => {
    let filteredData = userData;

    if (filterParticipant !== "none") {
      filteredData = filteredData.filter(
        (row) => row.client_name === filterParticipant
      );
    }

    if (filterClient !== "none") {
      filteredData = filteredData.filter((row) => row.client === filterClient);
    }

    if (filterCoach !== "none") {
      filteredData = filteredData.filter(
        (row) => row.coach_name === filterCoach
      );
    }

    if (filterStatus.length !== 1) {
      filteredData = filteredData.filter(
        (row) => filterStatus.indexOf(row.status) > -1
      );
    }

    if (filterStartDate !== "") {
      filteredData = filteredData.filter(
        (row) =>
          new Date(row.zoom_date).toISOString().split("T")[0] >= filterStartDate
      );
    }

    if (filterEndDate !== "") {
      filteredData = filteredData.filter(
        (row) =>
          new Date(row.zoom_date).toISOString().split("T")[0] <= filterEndDate
      );
    }

    setFilteredUserData(filteredData);
    const height =
      filteredData.length > 25
        ? headerHeight + 25 * rowHeight
        : headerHeight + filteredData.length * rowHeight;
    setGridHeight(height);
  }, [
    userData,
    filterParticipant,
    filterClient,
    filterStatus,
    filterStartDate,
    filterEndDate,
    filterCoach,
  ]);

  const handleMenuOpen = (field, event) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [field]: event.currentTarget,
    }));
  };

  const handleMenuClose = (field) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [field]: null,
    }));
  };

  const handleMenuItemClick = (item, direction) => {
    if (!Array.isArray(filteredUserData)) {
      console.error("filteredUserData is not an array:", filteredUserData);
      return;
    }

    if (selectedSortDirection === direction && selectedSortItem === item) {
      return;
    }

    setSelectedSortDirection(direction);
    setSelectedSortItem(item);
    const currentUserData = [...filteredUserData];
    let sortedData;
    if (item === "duration_minutes") {
      sortedData = currentUserData.sort((a, b) => {
        if (direction === "asc") {
          return a.duration_minutes - b.duration_minutes;
        }
        return b.duration_minutes - a.duration_minutes;
      });
    } else if (item === "zoom_date") {
      sortedData = currentUserData.sort((a, b) => {
        if (direction === "asc") {
          return new Date(a.zoom_date) - new Date(b.zoom_date);
        }
        return new Date(b.zoom_date) - new Date(a.zoom_date);
      });
    } else if (item === "client") {
      sortedData = currentUserData.sort((a, b) => {
        if (a[item] === null) return 1;
        if (b[item] === null) return -1;

        if (direction === "asc") {
          return a[item].localeCompare(b[item]);
        }
        return b[item].localeCompare(a[item]);
      });
    } else if (item === "status") {
      sortedData = currentUserData.filter((row) => row.status === direction);
    } else {
      sortedData = currentUserData.sort((a, b) => {
        if (direction === "asc") {
          return a[item].localeCompare(b[item]);
        }
        return b[item].localeCompare(a[item]);
      });
    }
    setFilteredUserData(sortedData);
    handleMenuClose(item);
  };

  const handleHeaderCellRender = (params) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>{params.colDef.headerName}</div>
        {params.field !== "status" && (
        <IconButton
          aria-label="more"
          aria-controls="header-menu"
          aria-haspopup="true"
          onClick={(event) => handleMenuOpen(params.field, event)}
        >
          <KeyboardArrowDown
            sx={{
              fill: "#93989A",
            }}
          />
        </IconButton>
        )}
        <Menu
          id={`header-menu-${params.field}`}
          anchorEl={anchorEl[params.field]}
          open={Boolean(anchorEl[params.field])}
          onClose={() => handleMenuClose(params.field)}
          sx={{
            "& .MuiPaper-root": {
              background: "white",
              color: "#202226",
              borderRadius: "10px",
              "& .MuiList-root .MuiMenuItem-root": {
                fontFamily: "Lexend",
                fontSize: "12px",
                lineHeight: "20px",
                fontWeight: "500",
                color: "#202226",
                "&:hover": {
                  background: "#F5F5F5",
                },
              },
            },
          }}
        >
          {params.field === "duration_minutes" ? (
            <Box>
              <MenuItem
                onClick={() => handleMenuItemClick(params.field, "asc")}
              >
                Shortest
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick(params.field, "desc")}
              >
                Longest
              </MenuItem>
            </Box>
          ) : params.field === "zoom_date" ? (
            <Box>
              <MenuItem
                onClick={() => handleMenuItemClick(params.field, "desc")}
              >
                Newest
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick(params.field, "asc")}
              >
                Oldest
              </MenuItem>
            </Box>
          ) : params.field === "status" ? (
            // <Box>
            //   <MenuItem
            //     onClick={() =>
            //       handleMenuItemClick(params.field, "ReadyToPublish")
            //     }
            //   >
            //     View Insights
            //   </MenuItem>
            //   <MenuItem
            //     onClick={() => handleMenuItemClick(params.field, "incomplete")}
            //   >
            //     Load Insights
            //   </MenuItem>
            //   <MenuItem
            //     onClick={() => handleMenuItemClick(params.field, "processing")}
            //   >
            //     Loading
            //   </MenuItem>
            // </Box>
            null
          ) : (
            <Box>
              <MenuItem
                onClick={() => handleMenuItemClick(params.field, "asc")}
              >
                (A-Z)
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick(params.field, "desc")}
              >
                (Z-A)
              </MenuItem>
            </Box>
          )}

          {/* Add more menu items as needed */}
        </Menu>
      </div>
    );
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      width: 200,
      minWidth: 170,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: (params) => {
        const statusComponent =
          params.row.status === "ReadyToPublish" ? (
            <Box position="relative" width="150px">
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                onClick={() => {
                  if (params.row.file_name && params.row.file_name !== "null")
                    if (user?.["custom:is_coachee"] === "true" && params.row.client_id === user?.sub) {
                      navigate(
                        `/platform/coachee/session/${params.row.file_name}`
                      );
                    } else {
                      navigate(
                        `/platform/coach/session/${params.row.file_name}`
                      );
                    }
                }}
              >
                View Insights
              </Box>
              {processedClient === params.row.id && (
                <Box
                  position="absolute"
                  zIndex={9999}
                  key={2}
                  sx={{
                    right: {
                      xs: "0",
                      lg: "unset",
                    },
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "430px",
                    background: "#F8F9FA",
                    padding: "16px 28px",
                    borderRadius: "8px",
                    boxShadow:
                      "14px 37px 52.6px 17px rgba(136, 136, 136, 0.12)",
                  }}
                  onMouseLeave={() => {
                    setProcessedClient("");
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{
                          color: "#160042",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          letterSpacing: "-0.03",
                        }}
                      >
                        Who is this session being shared with?
                      </Typography>
                      {/* <PersonAddAlt
                        sx={{
                          color: "black",
                          fontSize: "24px",
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProcessedClient("")}
                      /> */}
                    </Box>
                    <Box display="flex" alignItems="center" gap="4px">
                      <ErrorOutline
                        sx={{
                          color: "#00B11C",
                          fontSize: "11px",
                          width: "11px",
                          height: "11px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#6B7094",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.01",
                        }}
                      >
                        Once confirmed, the recipient will be notified
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      padding: "8px",
                      borderRadius: "8px",
                      background: "white",
                      maxHeight: "180px",
                      overflowY: "auto",
                    }}
                  >
                    {memberData && memberData.length > 0 ? (
                      memberData.map((client, key) =>
                        client.full_name ? (
                          <Box
                            key={key}
                            display="flex"
                            gap="12px"
                            alignItems="center"
                            padding="4px 12px"
                            borderRadius="4px"
                            sx={{
                              background:
                                client.full_name === assignee.name
                                  ? "#F5F5F5"
                                  : "initial",
                              "&:hover": {
                                background: "#F5F5F5",
                                cursor: "pointer",
                              },
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setAssignee({
                                id: client.user_id,
                                name: client.full_name,
                              });
                            }}
                          >
                            <Box
                              sx={{
                                background:
                                  "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                padding: "2px",
                                borderRadius: "50%",
                                width: "28px",
                                height: "28px",
                              }}
                            >
                              <Box
                                sx={{
                                  background: "white",
                                  padding: "1px",
                                  borderRadius: "50%",
                                  width: "24px",
                                  height: "24px",
                                }}
                              >
                                <Avatar
                                  sx={{
                                    width: "22px",
                                    height: "22px",
                                  }}
                                  src={client.avatar || avatar}
                                />
                              </Box>
                            </Box>
                            <Typography
                              color="#1B114A"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "300",
                                lineHeight: "24px",
                              }}
                            >
                              {client.full_name}
                            </Typography>
                          </Box>
                        ) : null
                      )
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "12px",
                        }}
                      >
                        <Typography
                          color="#1B114A"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Add your first client
                        </Typography>
                        <PersonAddAlt
                          sx={{
                            color: "black",
                            fontSize: "24px",
                            width: "24px",
                            height: "24px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate("/platform/enterprise/admin-portal")
                          }
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "24px",
                    }}
                  >
                    <Button
                      sx={{
                        width: "150px",
                        borderRadius: "8px",
                        color: "white",
                        background: "#160042",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                        zIndex: 9999,
                        "&:hover": {
                          background: "#10B02A",
                        },
                      }}
                      disabled={!assignee.id}
                      onClick={(event) => {
                        event.stopPropagation();
                        setProcessedClient("");
                        openAssignModal();
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      sx={{
                        width: "150px",
                        borderRadius: "8px",
                        background: "transparent",
                        color: "#7D7D7D",
                        border: "1px solid #E4E6F1",
                        fontWeight: "bold",
                        "&:hover": {
                          borderColor: "#B52100",
                          color: "#C51919",
                        },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setProcessedClient("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          ) : params.row.status === "processing" ? (
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Loading
            </Box>
          ) : params.row.status === "incomplete" ? (
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Load Insights
            </Box>
          ) : (
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              onClick={() => {
                if (params.row.file_name && params.row.file_name !== "null")
                  if (user?.["custom:is_coachee"] === "true" && params.row.client_id === user?.sub) {
                    navigate(
                      `/platform/coachee/session/${params.row.file_name}`
                    );
                  } else {
                    navigate(`/platform/coach/session/${params.row.file_name}`);
                  }
              }}
            >
              Shared
            </Box>
          );
        return <>{statusComponent}</>;
      },
      // sortComparator: (a, b) => new Date(a) - new Date(b),
    },
    {
      field: "call_name",
      headerName: "Call Subject",
      flex: 0.8,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row }) => (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            overflowX: "auto",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            scrollbarWidth: "none", // Hides the scrollbar in Firefox
            "-ms-overflow-style": "none", // Hides the scrollbar in IE/Edge
            "&::-webkit-scrollbar": {
              display: "none", // Hides the scrollbar in WebKit browsers
            },
            "&:hover": {
              color: row.file_name ? "#0000FF !important" : "inherit"
            },
          }}
          className={row.file_name ? "MuiDataGrid-cell--with-file" : ""}
          onClick={() => {
            if (row.file_name && row.file_name !== "null") {
              if (user?.["custom:is_coachee"] === "true" && row.client_id === user?.sub) {
                navigate(`/platform/coachee/session/${row.file_name}`);
              } else {
                navigate(`/platform/coach/session/${row.file_name}`);
              }
            }
          }}
        >
          {row.call_name}
        </Box>
      ),
    },    
    {
      field:
          "client_name",
      headerName:
          "Participant",
      flex: 0.5,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({
        row: { client_name, status, coach_name, file_name, picture, client_id },
      }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            overflowX: "auto",
            scrollbarWidth: "none", // Hides the scrollbar in Firefox
            "-ms-overflow-style": "none", // Hides the scrollbar in IE/Edge
            "&::-webkit-scrollbar": {
              display: "none", // Hides the scrollbar in WebKit browsers
            },
          }}
          onClick={() => {
            if (file_name && file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && client_id === user?.sub) {
                navigate(`/platform/coachee/session/${file_name}`);
              } else {
                navigate(`/platform/coach/session/${file_name}`);
              }
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            {client_name}
          </Box>
        </Box>
      ),
    },
    {
      field: "coachee",
      headerName: "Coachee",
      flex: 0.5,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      hide: isProfessionalTier,
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row: { client, client_avatar, file_name, client_id } }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            overflowX: "auto",
            scrollbarWidth: "none", // Hides the scrollbar in Firefox
            "-ms-overflow-style": "none", // Hides the scrollbar in IE/Edge
            "&::-webkit-scrollbar": {
              display: "none", // Hides the scrollbar in WebKit browsers
            },
          }}
          onClick={() => {
            if (file_name && file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && client_id === user?.sub) {
                navigate(`/platform/coachee/session/${file_name}`);
              } else {
                navigate(`/platform/coach/session/${file_name}`);
              }
          }}
        >
          {client && (
            <>
              <Box
                sx={{
                  background:
                    "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                  padding: "2px",
                  borderRadius: "50%",
                  width: "28px",
                  height: "28px",
                }}
              >
                <Box
                  sx={{
                    background: "white",
                    padding: "1px",
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "22px",
                      height: "22px",
                    }}
                    src={client_avatar || avatar}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {client}
              </Box>
            </>
          )}
        </Box>
      ),
    },
    {
      field: "zoom_date",
      headerName: "Date",
      flex: 0.5,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return new Date(cellParams1.value) - new Date(cellParams2.value);
      },
      renderCell: ({ row: { zoom_date, file_name , client_id} }) => (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            if (file_name && file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && client_id === user?.sub) {
                navigate(`/platform/coachee/session/${file_name}`);
              } else {
                navigate(`/platform/coach/session/${file_name}`);
              }
          }}
        >
          {zoom_date === null || zoom_date === ""
              ? ""
              : dayjs(zoom_date).utc().format('MM/DD/YY')
          }
        </Box>
      ),
    },
    {
      field: "duration_minutes",
      headerName: "Duration",
      flex: 0.5,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: (params) => {
        return (
          <>
            {params.row.loading ? (
              <BootstrapTooltip title={loadingTitle} placement="top-start">
                <Typography>
                  {params.row.duration_minutes.toLocaleString()} min
                </Typography>
              </BootstrapTooltip>
            ) : (
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {params.row.duration_minutes.toLocaleString() + " min"}
              </Box>
            )}
          </>
        );
      },
      // sortComparator: (a, b) => new Date(a) - new Date(b),
    },
  ];

  const coachColumn = {
    field: "coach_name",
    headerName: "Coach",
    flex: 0.5,
    headerClassName: "table-header",
    cellClassName: "table-cell",
    sortable: false,
    renderHeader: (params) => handleHeaderCellRender(params),
    renderCell: (params) => {
      return (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
          }}
        >
          {params.row.coach_name}
        </Box>
      );
    },
  };

  if (user?.["custom:is_coachee"] === "true") {
    const position = 3; // specify the index where you want to insert the additional column
    columns.splice(position, 0, coachColumn);
  }

  const clientColumns = [
    {
      field: "call_name",
      headerName: "Call Subject",
      flex: 0.8,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row }) => (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            "&:hover": {
              color: row.file_name ? "#0000FF !important" : "inherit"
            },
          }}
          className={row.file_name ? "MuiDataGrid-cell--with-file" : ""}
          onClick={() => {
            if (row.file_name && row.file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && row.client_id === user?.sub) {
                navigate(`/platform/coachee/session/${row.file_name}`);
              } else {
                navigate(`/platform/coach/session/${row.file_name}`);
              }
          }}
        >
          {row.call_name}
        </Box>
      ),
    },
    {
      field: "coach_name",
      headerName: "Coach",
      flex: 0.4,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({
        row: { coach_name, status, file_name, coach_avatar, client_id },
      }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
          onClick={() => {
            if (file_name && file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && client_id === user?.sub) {
                navigate(`/platform/coachee/session/${file_name}`);
              } else {
                navigate(`/platform/coach/session/${file_name}`);
              }
          }}
        >
          <Box
            sx={{
              background:
                "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
              padding: "2px",
              borderRadius: "50%",
              width: "28px",
              height: "28px",
            }}
          >
            <Box
              sx={{
                background: "white",
                padding: "1px",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
              }}
            >
              <Avatar
                sx={{
                  width: "22px",
                  height: "22px",
                }}
                src={coach_avatar || avatar}
              />
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            {coach_name}
          </Box>
        </Box>
      ),
    },
    {
      field: "zoom_date",
      headerName: "Date",
      flex: 0.4,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row: { zoom_date, file_name, client_id } }) => (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            if (file_name && file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && client_id === user?.sub) {
                navigate(`/platform/coachee/session/${file_name}`);
              } else {
                navigate(`/platform/coach/session/${file_name}`);
              }
          }}
        >
          {zoom_date !== null &&
                zoom_date !== "" &&
                dayjs(zoom_date).utc().format('MM/DD/YY')}
        </Box>
      ),
    },
    {
      field: "duration_minutes",
      headerName: "Duration",
      flex: 0.4,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: (params) => {
        return (
          <>
            {params.row.loading ? (
              <BootstrapTooltip title={loadingTitle} placement="top-start">
                <Typography>
                  {params.row.duration_minutes.toLocaleString()} min
                </Typography>
              </BootstrapTooltip>
            ) : (
              <Typography>
                {params.row.duration_minutes.toLocaleString() + " min"}
              </Typography>
            )}
          </>
        );
      },
    },
  ];

  const professionalClientColumns = [
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      width: 200,
      minWidth: 170,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: (params) => {
        const statusComponent =
          params.row.status === "ReadyToPublish" ? (
            <Box position="relative" width="150px">
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                onClick={() => {
                  if (params.row.file_name && params.row.file_name !== "null")
                    if (user?.["custom:is_coachee"] === "true" && params.row.client_id === user?.sub) {
                      navigate(
                        `/platform/coachee/session/${params.row.file_name}`
                      );
                    } else {
                      navigate(
                        `/platform/coach/session/${params.row.file_name}`
                      );
                    }
                }}
              >
                View Insights
              </Box>
              {processedClient === params.row.id && (
                <Box
                  position="absolute"
                  zIndex={9999}
                  key={2}
                  sx={{
                    right: {
                      xs: "0",
                      lg: "unset",
                    },
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "430px",
                    background: "#F8F9FA",
                    padding: "16px 28px",
                    borderRadius: "8px",
                    boxShadow:
                      "14px 37px 52.6px 17px rgba(136, 136, 136, 0.12)",
                  }}
                  onMouseLeave={() => {
                    setProcessedClient("");
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{
                          color: "#160042",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          letterSpacing: "-0.03",
                        }}
                      >
                        Who is this session being shared with?
                      </Typography>
                      {/* <PersonAddAlt
                        sx={{
                          color: "black",
                          fontSize: "24px",
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProcessedClient("")}
                      /> */}
                    </Box>
                    <Box display="flex" alignItems="center" gap="4px">
                      <ErrorOutline
                        sx={{
                          color: "#00B11C",
                          fontSize: "11px",
                          width: "11px",
                          height: "11px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#6B7094",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.01",
                        }}
                      >
                        Once confirmed, the recipient will be notified
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      padding: "8px",
                      borderRadius: "8px",
                      background: "white",
                      maxHeight: "180px",
                      overflowY: "auto",
                    }}
                  >
                    {memberData && memberData.length > 0 ? (
                      memberData.map((client, key) =>
                        client.full_name ? (
                          <Box
                            key={key}
                            display="flex"
                            gap="12px"
                            alignItems="center"
                            padding="4px 12px"
                            borderRadius="4px"
                            sx={{
                              background:
                                client.full_name === assignee.name
                                  ? "#F5F5F5"
                                  : "initial",
                              "&:hover": {
                                background: "#F5F5F5",
                                cursor: "pointer",
                              },
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setAssignee({
                                id: client.user_id,
                                name: client.full_name,
                              });
                            }}
                          >
                            <Box
                              sx={{
                                background:
                                  "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                padding: "2px",
                                borderRadius: "50%",
                                width: "28px",
                                height: "28px",
                              }}
                            >
                              <Box
                                sx={{
                                  background: "white",
                                  padding: "1px",
                                  borderRadius: "50%",
                                  width: "24px",
                                  height: "24px",
                                }}
                              >
                                <Avatar
                                  sx={{
                                    width: "22px",
                                    height: "22px",
                                  }}
                                  src={client.avatar || avatar}
                                />
                              </Box>
                            </Box>
                            <Typography
                              color="#1B114A"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "300",
                                lineHeight: "24px",
                              }}
                            >
                              {client.full_name}
                            </Typography>
                          </Box>
                        ) : null
                      )
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "12px",
                        }}
                      >
                        <Typography
                          color="#1B114A"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "300",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Add your first client
                        </Typography>
                        <PersonAddAlt
                          sx={{
                            color: "black",
                            fontSize: "24px",
                            width: "24px",
                            height: "24px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate("/platform/enterprise/admin-portal")
                          }
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "24px",
                    }}
                  >
                    <Button
                      sx={{
                        width: "150px",
                        borderRadius: "8px",
                        color: "white",
                        background: "#160042",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                        zIndex: 9999,
                        "&:hover": {
                          background: "#10B02A",
                        },
                      }}
                      disabled={!assignee.id}
                      onClick={(event) => {
                        event.stopPropagation();
                        setProcessedClient("");
                        openAssignModal();
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      sx={{
                        width: "150px",
                        borderRadius: "8px",
                        background: "transparent",
                        color: "#7D7D7D",
                        border: "1px solid #E4E6F1",
                        fontWeight: "bold",
                        "&:hover": {
                          borderColor: "#B52100",
                          color: "#C51919",
                        },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setProcessedClient("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          ) : params.row.status === "processing" ? (
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Loading
            </Box>
          ) : params.row.status === "incomplete" ? (
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Load Insights
            </Box>
          ) : (
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              onClick={() => {
                if (params.row.file_name && params.row.file_name !== "null")
                  if (user?.["custom:is_coachee"] === "true" && params.row.client_id === user?.sub) {
                    navigate(
                      `/platform/coachee/session/${params.row.file_name}`
                    );
                  } else {
                    navigate(`/platform/coach/session/${params.row.file_name}`);
                  }
              }}
            >
              Shared
            </Box>
          );
        return <>{statusComponent}</>;
      },
      // sortComparator: (a, b) => new Date(a) - new Date(b),
    },
    
    {
      field: "call_name",
      headerName: "Call Subject",
      flex: 0.8,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row }) => (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            overflowX: "auto",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            scrollbarWidth: "none", // Hides the scrollbar in Firefox
            "-ms-overflow-style": "none", // Hides the scrollbar in IE/Edge
            "&::-webkit-scrollbar": {
              display: "none", // Hides the scrollbar in WebKit browsers
            },
            "&:hover": {
              color: row.file_name ? "#0000FF !important" : "inherit"
            },
          }}
          className={row.file_name ? "MuiDataGrid-cell--with-file" : ""}
          onClick={() => {
            if (row.file_name && row.file_name !== "null") {
              if (user?.["custom:is_coachee"] === "true" && row.client_id === user?.sub) {
                navigate(`/platform/coachee/session/${row.file_name}`);
              } else {
                navigate(`/platform/coach/session/${row.file_name}`);
              }
            }
          }}
        >
          {row.call_name}
        </Box>
      ),
    },
    {
      field: "client_name",
      headerName: "Participant",
      flex: 0.4,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({
        row: { client_name, status, coach_name, file_name, picture, client_id },
      }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            overflowX: "auto",
            scrollbarWidth: "none", // Hides the scrollbar in Firefox
            "-ms-overflow-style": "none", // Hides the scrollbar in IE/Edge
            "&::-webkit-scrollbar": {
              display: "none", // Hides the scrollbar in WebKit browsers
            },
          }}
          onClick={() => {
            if (file_name && file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && client_id === user?.sub) {
                navigate(`/platform/coachee/session/${file_name}`);
              } else {
                navigate(`/platform/coach/session/${file_name}`);
              }
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            {client_name}
          </Box>
        </Box>
      ),
    },
    {
      field: "coach_name",
      headerName: "Coach",
      flex: 0.4,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({
        row: { coach_name, status, file_name, coach_avatar, client_id },
      }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
          onClick={() => {
            if (file_name && file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && client_id === user?.sub) {
                navigate(`/platform/coachee/session/${file_name}`);
              } else {
                navigate(`/platform/coach/session/${file_name}`);
              }
          }}
        >
          <Box
            sx={{
              background:
                "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
              padding: "2px",
              borderRadius: "50%",
              width: "28px",
              height: "28px",
            }}
          >
            <Box
              sx={{
                background: "white",
                padding: "1px",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
              }}
            >
              <Avatar
                sx={{
                  width: "22px",
                  height: "22px",
                }}
                src={coach_avatar || avatar}
              />
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            {coach_name}
          </Box>
        </Box>
      ),
    },
    {
      field: "zoom_date",
      headerName: "Date",
      flex: 0.5,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return new Date(cellParams1.value) - new Date(cellParams2.value);
      },
      renderCell: ({ row: { zoom_date, file_name, client_id } }) => (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            if (file_name && file_name !== "null")
              if (user?.["custom:is_coachee"] === "true" && client_id === user?.sub) {
                navigate(`/platform/coachee/session/${file_name}`);
              } else {
                navigate(`/platform/coach/session/${file_name}`);
              }
          }}
        >
          {zoom_date === null || zoom_date === ""
              ? ""
              : dayjs(zoom_date).utc().format('MM/DD/YY')
          }
        </Box>
      ),
    },
    {
      field: "duration_minutes",
      headerName: "Duration",
      flex: 0.5,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: (params) => {
        return (
          <>
            {params.row.loading ? (
              <BootstrapTooltip title={loadingTitle} placement="top-start">
                <Typography>
                  {params.row.duration_minutes.toLocaleString()} min
                </Typography>
              </BootstrapTooltip>
            ) : (
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {params.row.duration_minutes.toLocaleString() + " min"}
              </Box>
            )}
          </>
        );
      },
      // sortComparator: (a, b) => new Date(a) - new Date(b),
    },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (processedData.length > processedLength) {
      toast.success("Your session is processed.");
    }
  }, [processedData, processedLength]);

  const reFetchUserData = async () => {
    setLoading(true);
    await fetchData();
    await fetchSum();
    setLoading(false);
  };

  const fetchUserData = async () => {
  try {
    console.log("fetchinguser data")
    let response;
    const isClient = user?.["custom:is_coachee"] === "true";
    const hasPlatformProfessionalAccess = accessTo === "platform_professional";
    const hasPlatformAccess = accessTo === "platform";


    

    if(userDataInfo?.companyInformation || isClient){
      response = [
        ...(Array.isArray(coachCallData?.data) ? coachCallData.data : []), // check if coachCallData.data is an array
        ...(Array.isArray(clientCallData?.data) ? clientCallData.data : []), // check if clientCallData.data is an array
      ];
    }else{
      response = Array.isArray(coachCallData?.data) ? coachCallData.data : []; // check if coachCallData.data exists and is an array
    }


    // Ensure response is an array
    if (!Array.isArray(response)) {
      response = [];
    }

    // Process participant names
    const participantNames = response.length === 0 ? [] : response.map((row) => row?.client_name ?? "");
    const uniqueParticipantNames = [...new Set(participantNames)].filter(Boolean); // Remove null/empty values
    const formattedParticipantNames = uniqueParticipantNames.map((row) => ({
      key: row,
      value: row,
    }));
    
    setFilterParticipantItems([
      { key: "Participant", value: "none" },
      ...formattedParticipantNames,
    ]);

    // Process client names
    const clientNames = response.length === 0 ? [] : response.map((row) => row?.client ?? "");
    const uniqueClientNames = [...new Set(clientNames)].filter(Boolean); // Remove null/empty values
    const formattedClientNames = uniqueClientNames.map((row) => ({
      key: row,
      value: row,
    }));
    if (!isProfessionalTier) {
      setFilterClientItems([
        { key: "Coachee", value: "none" },
        ...formattedClientNames,
      ]);
    }

    // Process coach names
    const coachNames = response.length === 0 ? [] : response.map((row) => row?.coach_name ?? "");
    const uniqueCoachNames = [...new Set(coachNames)].filter(Boolean); // Remove null/empty values
    const formattedCoachNames = uniqueCoachNames.map((row) => ({
      key: row,
      value: row,
    }));
    
    setFilterCoachItems([
      { key: "Coach", value: "none" },
      ...formattedCoachNames,
    ]);

    // Sanitize and format data
    const sanitizedData = response.map((row) => ({
      ...row,
      call_name: row?.call_name ? row.call_name.replace(/_/g, " ") : "",
      zoom_date: row?.zoom_date ? dayjs(row.zoom_date).utc().format("MM/DD/YY") : "",
      file_name: row?.file_name ?? "",
      call_id: row?.id ?? "",
    }));

    // Add unique IDs to each row
    const rowsWithUniqueId = sanitizedData.map((row, index) => ({
      ...row,
      id: index + 1,
    }));

    // Sort data by date in descending order (ensure valid date before sorting)
    const sortedData = rowsWithUniqueId.sort(
      (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
    );

    // Filter and organize data based on status
    let processed = [];
    let unprocessed = [];
    let assigned = [];
    sortedData.forEach((data) => {
      if (data.status === "ReadyToPublish") {
        processed.push(data);
      } else if (data.status === "incomplete") {
        unprocessed.push(data);
      } else {
        assigned.push(data);
      }
    });

    // Set grid height based on the number of rows
    const height =
      sortedData.length > 25
        ? headerHeight + 25 * rowHeight
        : headerHeight + sortedData.length * rowHeight;
    setGridHeight(height);

    // Update state with the processed data
    setLoading(false);
    dispatch(setSession(sortedData));
    setUserData(sortedData);
    setFilteredUserData(sortedData);
    setProcessedData(processed);
    setProcessedLength(processed.length);
    setUnprocessedData(unprocessed);
    setAssignedData(sortedData);
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};


  useEffect(() => {
    fetchUserData();
  }, [user, coachCallData, clientCallData, dispatch]);


  const toastIds = useRef({});
  const batchProgress = useRef({});
  const subscriptionRef = useRef(null);

  const updateStatusToProcessing = (ids) => {
    const updatedData = filteredUserData.map(row => {
      if (ids.includes(row.id)) {
        return { ...row, status: "processing" };
      }
      return row;
    });
    setFilteredUserData(updatedData);
  };

  const handleProcessCall = async ({ rows }) => {
    try {
      setLoadingProcessing(true);
      setDisabled(true);
      closeProcessModal(); 
      const selectedRows = rows || selected;
      const selectedIds = selectedRows.map(row => row.id);
      updateStatusToProcessing(selectedIds);

      const groupCount = selectedRows.length;
      const messageGroupId = selectedRows[0].coach_name.replace(/\W/g, '') + uuidv4();

      const body = selectedRows.map(data => ({
        coach_id: data.coach_id,
        call_id: data.call_id,
        call_name: data.call_name,
        coach_name: data.coach_name,
        client_name: data.client_name,
        zoom_call_date: data.zoom_call_date,
        duration: data.duration_minutes || 0,
        messageGroupId,
        group_count: groupCount,
      }));

      if (groupCount > 1) {
        batchProgress.current[messageGroupId] = { total: groupCount, completed: 0 };
        toastIds.current[messageGroupId] = toast(`Generating Insights: 0/${groupCount} Completed`, {
          hideProgressBar: false,
          isLoading: true,
          closeButton: false,
          autoClose: false,
          style: {
            background: 'white',
            boxShadow: '14px 37px 17px rgba(136, 136, 136, 0.12)',
            borderRadius: '8px',
            padding: '16px 28px',
            color: '#160042',
          },
        });
      } else {
        const messages = ["Pulling Session", "Generating Insights", "Calculating LARA"];
        let messageIndex = 0;
        let progress = 0.20;

        toastIds.current[messageGroupId] = toast(messages[messageIndex], {
          progress,
          hideProgressBar: false,
          isLoading: true,
          closeButton: false,
          autoClose: false,
          style: {
            background: 'white',
            boxShadow: '14px 37px 17px rgba(136, 136, 136, 0.12)',
            borderRadius: '8px',
            padding: '16px 28px',
            color: '#160042',
          },
        });

        const interval = setInterval(() => {
          messageIndex = (messageIndex + 1) % messages.length;
          progress += 0.20;
          toast.update(toastIds.current[messageGroupId], {
            render: messages[messageIndex],
            progress: progress >= 0.9 ? 0.9 : progress,
            hideProgressBar: false,
            isLoading: true,
            closeButton: false,
            autoClose: false,
            style: {
              background: 'white',
              boxShadow: '14px 37px 17px rgba(136, 136, 136, 0.12)',
              borderRadius: '8px',
              padding: '16px 28px',
              color: '#160042',
            }
          });
        }, 20000);
      }

      await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/handleProcessCall`, body);
    } catch (err) {
      console.error('Error while sending data', err);
    } finally {
      setLoadingProcessing(false);
    }
  };

  const handleNotificationUpdate = (notificationData) => {
    const { group_id, update_type, group_count } = notificationData;

    if (update_type === 'batch_call_complete') {
      if (!batchProgress.current[group_id]) {
        batchProgress.current[group_id] = { total: group_count, completed: 0 };
        toastIds.current[group_id] = toast.loading(`Insights: 0/${group_count} Generated`);
      }
      batchProgress.current[group_id].completed++;

      const progressData = batchProgress.current[group_id];
      const progressPercentage = Math.round((progressData.completed / progressData.total) * 100) / 100;

      if (toastIds.current[group_id]) {
        toast.update(toastIds.current[group_id], {
          render: `Generated ${progressData.completed}/${progressData.total} Insights`,
          progress: progressPercentage,
          hideProgressBar: false,
          closeButton: true,
          closeOnClick: true,
          isLoading: true,
        });
      }

      if (progressData.completed === progressData.total) {
        if (toastIds.current[group_id]) {
          toast.update(toastIds.current[group_id], {
            render: 'Generating Insights Complete!',
            type: toast.TYPE.SUCCESS,
            progress: 1,
            autoClose: 5000,
            isLoading: false,
          });
          reFetchUserData();
        }
      }
    } else if (update_type === 'batch_call_failed' && toastIds.current[group_id]) {
      toast.update(toastIds.current[group_id], {
        render: `Batch call failed. ${group_id} could not be processed.`,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        isLoading: false,
      });
    } else if (update_type === 'zoom_calls_loaded') {
      toast.success('Your Calls Are Loaded!', {
        autoClose: 5000,
        closeButton: true,
        style: {
          background: 'white',
          boxShadow: '14px 37px 17px rgba(136, 136, 136, 0.12)',
          borderRadius: '8px',
          padding: '16px 28px',
          color: '#160042',
        }
      });
      reFetchUserData();
    } else if (update_type === 'webhook_call') {
      toast.success('You Have a New Call', {
        autoClose: 5000,
        closeButton: true,
        style: {
          background: 'white',
          boxShadow: '14px 37px 17px rgba(136, 136, 136, 0.12)',
          borderRadius: '8px',
          padding: '16px 28px',
          color: '#160042',
        }
      });
      reFetchUserData();
    } else if (update_type === 'process_complete') {
      if (toastIds.current[group_id]) {
        toast.update(toastIds.current[group_id], {
          render: 'Insights Generated',
          type: toast.TYPE.SUCCESS,
          progress: 1,
          autoClose: 5000,
          isLoading: false,
        });
        reFetchUserData();

        // Clear interval if it's running
        if (batchProgress.current[group_id]) {
          clearInterval(batchProgress.current[group_id]);
          delete batchProgress.current[group_id];
        }
      }
    }
  };

  const dataClient = generateClient({ authMode: "userPool" });

  useEffect(() => {
    console.log(`[Subscription Init]: Attempting to subscribe for user with ID: ${user?.sub}`);
    if (!subscriptionRef.current && user?.sub) {
  
      try {
        const subscription = dataClient.models.callNotification.onCreate({
          filter: {
            coach_id: {
              contains: `${user?.sub}`,
            },
          },
        }).subscribe({
          authMode: "userPool",
          next: (data) => {
            console.log(`[Subscription Update]: Received new notification data for user: ${user?.sub}`, data);
            handleNotificationUpdate(data);
          },
          error: (error) => {
            console.error(`[Subscription Error]: Error encountered for user: ${user?.sub}`, error);
          }
        });
  
        subscriptionRef.current = subscription;
        console.log(`[Subscription Success]: Successfully set up subscription for user: ${user?.sub}`);
      } catch (e) {
        console.error(`[Subscription Setup Failed]: Failed to set up subscription for user: ${user?.sub}`, e);
      }
    }
  
    return () => {
      if (subscriptionRef.current) {
        console.log(`[Subscription Cleanup]: Unsubscribing for user: ${user?.sub}`);
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
        console.log(`[Subscription Cleanup Success]: Unsubscription successful for user: ${user?.sub}`);
      }
    };
  }, [user?.sub]);
  
  
  

  const deleteCalls = async () => {
    try {
      setLoading(true);
      setShowCallActionList(false);
      closeDeleteModal();
      const newProcessedData = [];
      userData.filter((data) => {
        if (!selectedIds.includes(data.id)) {
          newProcessedData.push(data);
        } else {
          newProcessedData.push({ ...data, loading: true });
        }
      });
      const height =
        newProcessedData.length > 25
          ? headerHeight + 25 * rowHeight
          : headerHeight + newProcessedData.length * rowHeight;
      setGridHeight(height);
      setUserData(newProcessedData);
      const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/deleteCalls`, selectedIds);
      toast(`${res.message}`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      fetchData();
      setLoading(false);
      setCallIds([]);
      setSelected([]);
      setOldSelection(selected);
      setLastAction("delete");
    } catch (err) {
      setLoading(false);
    }
  };

  const assignCall = async (client_data) => {
    setAssignLoading(true);
    const oldUserData = userData;
    try {
      setShowCallActionList(false);
      setShowClientList(false);
      const body = [];
      const rows = selected;
      setCallIds([]);
      setSelected([]);
      rows.filter((data) => {
        if (data.status === "ReadyToPublish") {
          body.push({
            id: data.call_id,
            coach_id: data.coach_id,
            call_name: data.call_name,
            client_name: client_data.name,
            client_id: client_data.id,
            zoom_date: data.zoom_date,
            coach_name:
              user?.given_name + " " + user?.family_name,
          });
        }
      });
  
      const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/assignCall`, body );
      console.log("Res: ", res)
      closeAssignModal();
      if (res.data.message === "succeed") {
        // Update the selected items immediately upon success
        const updatedSelected = selected.map((item) => {
          if (item.status === "ReadyToPublish") {
            return {
              ...item,
              status: "assigned",
              client: client_data.name,
            };
          }
          return item;
        });
        setSelected(updatedSelected);
        
        fetchUserData();

        const sessionCount = body.length;
        const toastMessage =
          sessionCount === 1
            ? `Session has been shared with ${client_data.name}`
            : `${sessionCount} sessions have been shared with ${client_data.name}`;

        toast(toastMessage, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
      } else {
        toast.error("Error while sharing calls");
      }
    } catch (err) {
      console.log("Error in assign call", err);
    } finally {
      reFetchUserData();
      setAssignLoading(false);
    }
  };
  

  const undoActions = async () => {
    closeUnassignModal();
    if (selected.length == 0) {
      toast.error("Please select calls to undo assign");
      return;
    }
    const body = [];
    selected.map((row) => {
      if (row.status === "assigned") {
        body.push({
          id: row.call_id,
          client_name: row.client,
        });
      }
    });

    const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/undoAssignCall`, body);
    if (res.message === "succeed") {
      toast(`Undo Assigning calls succeed`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
      onCallStatusChange();
      setCallIds([]);
      setSelected([]);
      setOldSelection([]);
      setLastAction("assign");
    } else {
      await fetchData();
      toast.error("Error while undo assigning calls");
      setCallIds([]);
      setSelected([]);
      setOldSelection([]);
      setLastAction("assign");
    }
  };

  const zoomProcessModal = () => {
    return (
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "24px",
            borderRadius: "16px",
          }}
        >
          <Box
            marginBottom={"32px"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Avatar src={zoom} width={30} height={30} />
              <Typography
                marginLeft={"10px"}
                fontWeight={600}
                color={"#202226"}
                fontSize={"18px"}
              >
                Process Zoom Calls
              </Typography>
            </Box>
            <Box>
              <_Select
                value={0}
                items={[
                  { key: "2024", value: 0 },
                  { key: "2023", value: 7 },
                  { key: "2022", value: 28 },
                  { key: "2021", value: 90 },
                ]}
              />
              <CloseOutlined
                onClick={handleModalClose}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #E7E7E7",
                  width: "36px",
                  height: "36px",
                  color: "black",
                  marginLeft: "16px",
                  padding: "8px",
                  cursor: "pointer",
                }}
              />
            </Box>
          </Box>
          <_DataGrid
            height={"320px"}
            rows={unprocessedData}
            loading={zoomLoading}
            columns={columns}
            localeText={unprocessedData?.length ? "" : "No unprocessed calls."}
            checkboxSelection={true}
            style={{
              display: "none",
            }}
            onSelectionModelChange={(ids) => {
              const selectedIDs = ids;
              const selectedRowData = unprocessedData.filter((row) => {
                return selectedIDs.includes(row.id);
              });
              setSelected(selectedRowData);
              setCallIds(ids);
            }}
            selectionModel={callIds}
          />
          <Button
            sx={{
              width: "180px",
              height: "40px",
              marginLeft: "20px",
              borderRadius: "8px",
              color: "white",
              textTransform: "none",
              background:
                "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
            }}
            onClick={handleProcessCall}
          >
            Process Selected Calls
          </Button>
          <Button
            onClick={handleModalClose}
            sx={{
              border: "1px solid #E7E7E7",
              borderRadius: "8px",
              background: "#F6F6F6",
              padding: "10px 20px",
              color: "#202226",
              marginLeft: "27px",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    );
  };

  const deleteModalContent = () => {
    return (
      <Modal open={deleteModal} onClose={closeDeleteModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="#160042" fontWeight="400">
              Are you sure you want to delete these sessions?
            </Typography>
            <Box display="flex" gap="8px" alignItems="center">
              <ErrorOutline
                sx={{
                  color: "#CC5F0A",
                }}
              />
              <Typography color="#6B7094" fontWeight="400">
                You will not able to recover them
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={closeDeleteModal} label={"No"} />
            <ConfirmButton onClick={deleteCalls} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };
  const assignModalContent = () => {
    return (
      <Modal open={assignModal} onClose={closeAssignModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to share the session with ${client.name}?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" gap="15px">
            <Button
              sx={{
                paddingX: "80pt",
                paddingY: "10pt",
                borderRadius: "53px",
                background: "#FFFFFF",
                color: "#C10404",
                border: "1px solid #BE0808",
                fontWeight: "bold",
                "&:hover": {
                  background: "rgba(190, 8, 8, 0.4)",
                  border: "1px solid rgba(190, 8, 8, 0.4)",
                  color: "white",
                },
                "&.Mui-disabled": {
                  background: "#FFFFFF",
                  color: "rgba(193, 4, 4, 0.5)", // lighter color for the disabled state
                  border: "1px solid rgba(190, 8, 8, 0.5)", // lighter border for the disabled state
                  opacity: 0.7,
                },
              }}
              disabled={assignLoading}
              onClick={closeAssignModal}
            >
              No
            </Button>
            <Button
              sx={{
                paddingX: "80pt",
                paddingY: "10pt",
                borderRadius: "52px",
                color: "white",
                background:
                  "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background:
                    "linear-gradient(315deg, rgba(0, 118, 186, 0.8) 0%, rgba(3, 9, 58, 0.8) 60%, rgba(36, 47, 140, 0.8) 100%)",
                  cursor: "pointer",
                },
                "&.Mui-disabled": {
                  background:
                    "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)", // Keep gradient but adjust opacity
                  color: "rgba(255, 255, 255, 0.7)", // lighter color for the disabled state
                  opacity: 0.7,
                  cursor: "not-allowed", // ensures the cursor indicates the button is not clickable
                },
              }}
              onClick={() => assignCall(client)}
              disabled={assignLoading}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  const assignedModalContent = () => {
    return (
      <Modal open={assignedModal} onClose={closeAssignedModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Session has been shared with ${client.full_name}`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              sx={{
                width: "150px",
                borderRadius: "8px",
                background: "#160042",
                color: "white",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                "&:hover": {
                  background: "#10B02A",
                },
              }}
              onClick={closeAssignedModal}
            >
              <Check />
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  const unassignModalContent = () => {
    return (
      <Modal open={unassignModal} onClose={closeUnassignModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to stop sharing call?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              sx={{
                width: "150px",
                borderRadius: "8px",
                color: "white",
                background: "#160042",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                "&:hover": {
                  background: "#10B02A",
                },
              }}
              onClick={undoActions}
            >
              <Check />
              Yes
            </Button>
            <Button
              sx={{
                width: "150px",
                borderRadius: "8px",
                background: "transparent",
                color: "#7D7D7D",
                border: "1px solid #E4E6F1",
                fontWeight: "bold",
                "&:hover": {
                  borderColor: "#B52100",
                  color: "#C51919",
                },
              }}
              onClick={closeUnassignModal}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  const processModalContent = () => {
    return (
      <Modal open={processModal} onClose={closeProcessModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Would you like to gain insights from this session?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={closeProcessModal} label={"No"} />
            <ConfirmButton 
              onClick={() => {
                handleProcessCall({ rows: selected });
                setSelected([]);
                setCallIds([]);
              }} 
              label={"Yes"} 
            />
          </Box>
        </Box>
      </Modal>
    );
  };

  const handleUnprocessCall = async () => {
    try {
      closeUnprocessModal();
      const selectedRows = selected;
      if (selectedRows.length) {
        const body = [];
        const selectedIds = [];
        const newProcessedData = [];
        selectedRows.filter((data) => {
          if (data.status === "ReadyToPublish") {
            selectedIds.push(data.id);
            const json = {
              file_name: null,
              coach_id: data.coach_id,
              id: data.id,
              client_id: null,
              principles: null,
              principles_count: null,
              agreements: null,
              agreements_count: null,
              call_topics: null,
              call_keywords: null,
              vtt_s3_link: null,
              m4a_s3_link: null,
              mp4_s3_link: null,
              created_at: null,
              updated_at: null,
              timestamp: null,
              status: "incomplete",
              client_email: null,
            };
            body.push(json);
          }
        });

        if (selectedIds.length === 0) {
          closeUnprocessModal();
          return;
        }

        userData.filter((data) => {
          if (!selectedIds.includes(data.id)) {
            newProcessedData.push({ ...data, loading: false });
          } else {
            newProcessedData.push({ ...data, loading: true });
          }
        });
        const height =
          newProcessedData.length > 25
            ? headerHeight + 25 * rowHeight
            : headerHeight + newProcessedData.length * rowHeight;
        setGridHeight(height);
        setUserData(newProcessedData);
        const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/handleUnprocessCall`, body);

        if (res.message === "Rows updated successfully") {
          const processingData = [];
          userData.filter((data) => {
            if (!selectedIds.includes(data.id)) {
              processingData.push({
                ...data,
                loading: false,
                processing: false,
              });
            } else {
              processingData.push({
                ...data,
                loading: false,
                status: "incomplete",
              });
            }
          });
          onCallStatusChange();
          const height =
            processingData.length > 25
              ? headerHeight + 25 * rowHeight
              : headerHeight + processingData.length * rowHeight;
          setGridHeight(height);
          setUserData(processingData);
          // await getCallList();
          toast(`Unprocessed calls successfully`, {
            hideProgressBar: true,
            style: {
              background: "white",
              boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
              borderRadius: "8px",
              padding: "16px 28px",
              color: "#160042",
            },
            icon: () => <Check color="success" />,
          });
          setSelected([]);
          setOldSelection(selectedRows);
          setCallIds([]);
          setShowCallActionList(false);
          setLastAction("process");
        } else {
          await fetchUserData();
          toast.error("Error while processing calls");
          setCallIds([]);
          setShowCallActionList(false);
          setSelected([]);
          setOldSelection(selectedRows);
          setLastAction("process");
        }
      }
    } catch (err) {
      console.log("Error while unprocessing calls", err);
    }
  };

  const unprocessModalContent = () => {
    return (
      <Modal open={unprocessModal} onClose={closeUnprocessModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to unprocess call?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              sx={{
                width: "150px",
                borderRadius: "8px",
                color: "white",
                background: "#160042",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                "&:hover": {
                  background: "#10B02A",
                },
              }}
              onClick={handleUnprocessCall}
            >
              <Check />
              Yes
            </Button>
            <Button
              sx={{
                width: "150px",
                borderRadius: "8px",
                background: "transparent",
                color: "#7D7D7D",
                border: "1px solid #E4E6F1",
                fontWeight: "bold",
                "&:hover": {
                  borderColor: "#B52100",
                  color: "#C51919",
                },
              }}
              onClick={closeUnprocessModal}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  const openUnprocessModal = () => setUnprocessModal(true);
  const closeUnprocessModal = () => setUnprocessModal(false);
  const openUnassignModal = () => setUnassignModal(true);
  const closeUnassignModal = () => setUnassignModal(false);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const filteredData = userData.filter((row) => row.client_id !== user?.sub);
      setSelected(filteredData.map((row) => row));
      setSelectedIds(filteredData.map((row) => row.call_id));
    } else {
      setSelected([]);
      setSelectedIds([]);
    }
  };
  

  return (
    <>
      {(accessTo === 'platform' || accessTo === 'platform_professional') && (
        <>
          <Box
            display={{
              xs: "none",
              lg: "block",
            }}
            sx={{
              marginTop: {
                xs: "8px",
                lg: "8px",
              },
              padding: "24px",
              background: "white",
              borderRadius: "30px",
              "& .MuiTabs-root": {
                width: "100%",
              },

              "& .MuiFormControl-root": {
                width: "160px",
              },
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
          >
            <Box
              display={{
                xs: "none",
                lg: "block",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "24px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "24px",
                    },
                    lineHeight: "1.2",
                    fontWeight: 300,
                    color: "#202226",
                    minWidth: "160px",
                    marginRight: {
                      lg: "24px",
                      xl: "48px",
                    },
                  }}
                  data-cy="session-list"
                >
                  Sessions List
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "812px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      width: "100%",
                      "& > :nth-of-type(n)": {
                        flexBasis: {
                          xs: "100%",
                          md: "50%",
                          lg: "25%",
                        },
                      },
                    }}
                  >
                    <_CallListFilterSelect
                      value={filterParticipant}
                      items={filterParticipantItems}
                      handleChange={(e) => setFilterParticipant(e)}
                    />
                    {!isProfessionalTier && (
                      <_CallListFilterSelect
                        value={filterClient}
                        items={filterClientItems}
                        handleChange={(e) => setFilterClient(e)}
                      />
                    )}
                    <_CallListFilterMultiSelect
                      value={filterStatus}
                      items={filterStatusItems}
                      handleChange={(e) => {
                        const selectedValue = e;
                        filterStatus.indexOf(selectedValue) !== -1
                          ? setFilterStatus(
                              filterStatus.filter((status) => status !== e)
                            )
                          : setFilterStatus([...filterStatus, e]);
                      }}
                    />
                    <_CallListFilterDateRange
                      startDate={filterStartDate}
                      endDate={filterEndDate}
                      handleStartDateChange={(date) => {
                        setFilterStartDate(date);
                      }}
                      handleEndDateChange={(date) => {
                        setFilterEndDate(date);
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        color: "#FF5E00",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        borderRadius: "45px",
                        border: "1px solid black",
                        paddingY: "4px",
                        justifyContent: "center",
                        textAlign: "center",
                        letterSpacing: "-0.32px",
                        lineHeight: "24px",
                        "&:hover": {
                          cursor: "pointer",
                          background: "#F7F7F7",
                        },
                      }}
                      data-cy="session-list-reset"
                      onClick={handleResetFilters}
                    >
                      Reset Filters
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems={"center"}
                marginTop="16px"
              >
                {accessTo !== 'client_portal' ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="20px"
                    position="relative"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        transition: "all 0.3s",
                        cursor: "pointer",
                        padding: "8px", // Add padding for better hover effect
                        borderRadius: "8px", // Add border-radius for a rounded effect
                        backgroundColor: isRefreshHovered ? "#F5F7FF" : "transparent",
                        "&:hover": {
                          backgroundColor: "#F5F7FF", // Hover background color
                        },
                        }}
                      onMouseEnter={() => setIsRefreshHovered(true)}
                      onMouseLeave={() => setIsRefreshHovered(false)}
                      onClick={() => {
                        reFetchUserData();
                      }}
                    >
                      {isRefreshHovered === false ? (
                        <>
                          <RefreshIcon color="enabled" />
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "#2D2D2D",
                            }}
                          >
                            Refresh
                          </Typography>
                        </>
                      ) : (
                        <>
                          <RefreshIcon color="hovered" />
                          <Typography
                            sx={{
                              background:
                                "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                              backgroundOrigin: "border-box",
                              backgroundClip: "text",
                              fontFamily: "Lexend",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "transparent",
                            }}
                          >
                            Refresh
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        transition: "all 0.3s",
                        padding: "8px", // Add padding for better hover effect
                        borderRadius: "8px", // Add border-radius for a rounded effect
                        "&:hover": {
                          backgroundColor: "#F5F7FF", // Hover background color
                        },
                        cursor:
                          selected.length > 0 &&
                          selected.filter((row) => row.status === "incomplete")
                            .length === selected.length
                            ? "pointer"
                            : "not-allowed",
                      }}
                      disabled={isLoadingProcessing}
                      onMouseEnter={() => setIsAssignHovered(true)}
                      onMouseLeave={() => setIsAssignHovered(false)}
                      onClick={() => {
                        if (
                          selected.length > 0 &&
                          selected.filter((row) => row.status === "incomplete")
                            .length === selected.length
                        ) {
                          openProcessModal();
                        }
                      }}
                    >
                      {isLoadingProcessing ? (
                        <CircularProgress size={15} />
                      ) : (
                        <>
                          {selected.length > 0 &&
                          selected.filter((row) => row.status === "incomplete")
                            .length === selected.length ? (
                            !isAssignHovered ? (
                              <>
                                <AssignIcon color="enabled" />
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    lineHeight: "20px",
                                    color: "#2D2D2D",
                                  }}
                                >
                                  Generate Insights
                                </Typography>
                              </>
                            ) : (
                              <>
                                <AssignIcon color="hovered" />
                                <Typography
                                  sx={{
                                    background:
                                      "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                    backgroundOrigin: "border-box",
                                    backgroundClip: "text",
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    lineHeight: "20px",
                                    color: "transparent",
                                  }}
                                >
                                  Generate Insights
                                </Typography>
                              </>
                            )
                          ) : (
                            <>
                              <AssignIcon color="disabled" />
                              <Typography
                                sx={{
                                  fontFamily: "Lexend",
                                  fontSize: "12px",
                                  lineHeight: "20px",
                                  color: "#B6B6B6",
                                }}
                              >
                                Generate Insights
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        transition: "all 0.3s",
                        padding: "8px", // Add padding for better hover effect
                        borderRadius: "8px", // Add border-radius for a rounded effect
                        "&:hover": {
                          backgroundColor: "#F5F7FF", // Hover background color
                        },
                        cursor:
                          selected.length > 0 &&
                          selected.filter(
                            (row) => row.status === "ReadyToPublish"
                          ).length === selected.length
                            ? "pointer"
                            : "not-allowed",
                      }}
                      onMouseEnter={() => setIsShareHovered(true)}
                      onMouseLeave={() => setIsShareHovered(false)}
                      onClick={() => {
                        if (isProfessionalTier) {
                          toast.error(customToast);
                        } else {
                          if (
                            selected.length > 0 &&
                            selected.filter(
                              (row) => row.status === "ReadyToPublish"
                            ).length === selected.length
                          ) {
                            setShowClientList(!showClientList);
                          }
                        }
                      }}
                    >
                      {selected.length > 0 &&
                      selected.filter((row) => row.status === "ReadyToPublish")
                        .length === selected.length ? (
                        isShareHovered === false ? (
                          <>
                            <ShareIcon color="enabled" />
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#2D2D2D",
                              }}
                            >
                              Share
                            </Typography>
                          </>
                        ) : (
                          <>
                            <ShareIcon color="hovered" />
                            <Typography
                              sx={{
                                background:
                                  "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                backgroundOrigin: "border-box",
                                backgroundClip: "text",
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "transparent",
                              }}
                            >
                              Share
                            </Typography>
                          </>
                        )
                      ) : (
                        <>
                          <ShareIcon color="disabled" />
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "#B6B6B6",
                            }}
                          >
                            Share
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        transition: "all 0.3s",
                        padding: "8px", // Add padding for better hover effect
                        borderRadius: "8px", // Add border-radius for a rounded effect
                        "&:hover": {
                          backgroundColor: "#F5F7FF", // Hover background color
                        },
                        cursor: selected.length > 0 ? "pointer" : "not-allowed",
                      }}
                      onMouseEnter={() => setIsDeleteHovered(true)}
                      onMouseLeave={() => setIsDeleteHovered(false)}
                      onClick={() => {
                        if (selected.length > 0) {
                          openDeleteModal();
                        }
                      }}
                    >
                      {selected.length > 0 ? (
                        isDeleteHovered === false ? (
                          <>
                            <DeleteIcon color="enabled" />
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#2D2D2D",
                              }}
                            >
                              Delete
                            </Typography>
                          </>
                        ) : (
                          <>
                            <DeleteIcon color="hovered" />
                            <Typography
                              sx={{
                                background:
                                  "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                backgroundOrigin: "border-box",
                                backgroundClip: "text",
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "transparent",
                              }}
                            >
                              Delete
                            </Typography>
                          </>
                        )
                      ) : (
                        <>
                          <DeleteIcon color="disabled" />
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "#B6B6B6",
                            }}
                          >
                            Delete
                          </Typography>
                        </>
                      )}
                    </Box>
                    {showClientList && (
                      <Box
                        position="absolute"
                        zIndex={2}
                        key={2}
                        sx={{
                          right: {
                            xs: "0",
                            lg: "unset",
                          },
                          top: {
                            lg: "30px",
                          },
                          left: {
                            lg: "150px",
                          },
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          width: "430px",
                          background: "#F8F9FA",
                          padding: "16px 28px",
                          borderRadius: "8px",
                          boxShadow:
                            "14px 37px 52.6px 17px rgba(136, 136, 136, 0.12)",
                        }}
                        onMouseLeave={() => {
                          setShowClientList(false);
                        }}
                      >
                        <Box display="flex" flexDirection="column" gap="4px">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              sx={{
                                color: "#160042",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "1.5",
                                letterSpacing: "-0.03",
                              }}
                            >
                              Who is this session being shared with?
                            </Typography>
                            {/* <PersonAddAlt
                              sx={{
                                color: "black",
                                fontSize: "24px",
                                width: "24px",
                                height: "24px",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate('/client-center')
                              }}
                            /> */}
                          </Box>
                          <Box display="flex" alignItems="center" gap="4px">
                            <ErrorOutline
                              sx={{
                                color: "#00B11C",
                                fontSize: "11px",
                                width: "11px",
                                height: "11px",
                              }}
                            />
                            <Typography
                              sx={{
                                color: "#6B7094",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "-0.01",
                              }}
                            >
                              Once confirmed, the recipient will be notified
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            padding: "8px",
                            borderRadius: "8px",
                            background: "white",
                            maxHeight: "180px",
                            overflowY: "auto",
                          }}
                        >
                          {memberData && memberData.length > 0 ? (
                            memberData.map((client, key) =>
                              client.full_name ? (
                                <Box
                                  key={key}
                                  display="flex"
                                  gap="12px"
                                  alignItems="center"
                                  padding="4px 12px"
                                  borderRadius="4px"
                                  sx={{
                                    background:
                                      client.full_name === assignee.name
                                        ? "#F5F5F5"
                                        : "initial",
                                    "&:hover": {
                                      background: "#F5F5F5",
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={() => {
                                    setAssignee({
                                      id: client.user_id,
                                      name: client.full_name,
                                    });
                                  }}
                                >
                                  <Box
                                    sx={{
                                      background:
                                        "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                      padding: "2px",
                                      borderRadius: "50%",
                                      width: "28px",
                                      height: "28px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        background: "white",
                                        padding: "1px",
                                        borderRadius: "50%",
                                        width: "24px",
                                        height: "24px",
                                      }}
                                    >
                                      <Avatar
                                        sx={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                        src={client.avatar || avatar}
                                      />
                                    </Box>
                                  </Box>
                                  <Typography
                                    color="#1B114A"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {client.full_name}
                                  </Typography>
                                </Box>
                              ) : null
                            )
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "12px",
                              }}
                            >
                              <Typography
                                color="#1B114A"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "300",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Add your first client
                              </Typography>
                              <PersonAddAlt
                                sx={{
                                  color: "black",
                                  fontSize: "24px",
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate("/platform/enterprise/admin-portal")
                                }
                              />
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            sx={{
                              paddingX: "20pt",
                              paddingY: "5pt",
                              borderRadius: "53px",
                              background: "#FFFFFF",
                              color: "#C10404",
                              border: "1px solid #BE0808",
                              fontWeight: "bold",
                              "&:hover": {
                                background: "rgba(190, 8, 8, 0.4)",
                                border: "1px solid rgba(190, 8, 8, 0.4)",
                                color: "white",
                              },
                            }}
                            onClick={() => setShowClientList(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{
                              paddingX: "20pt",
                              paddingY: "5pt",
                              borderRadius: "52px",
                              color: "white",
                              background:
                                "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                              fontWeight: "bold",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              "&:disabled": {
                                backgroundColor: "#DEDEDE",
                                color: "#B7B7B7",
                              },
                              "&[disabled]": {
                                // Change to "&[disabled='true']" if necessary
                                backgroundColor: "#DEDEDE !important",
                                color: "#B7B7B7 !important",
                              },
                            }}
                            disabled={!assignee.id}
                            onClick={openAssignModal}
                          >
                            Confirm
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ) : null}
              </Box>
              <_CallDataGrid
                height={`${gridHeight + 52 > 200 ? gridHeight + 70 : 200}px`}
                maxHeight="628px"
                rows={filteredUserData}
                columns={
                  userDataInfo?.companyInformation
                  ? columns 
                  :  clientColumns
                }
                loading={loading}
                isRowSelectable={isRowSelectable}
                apiRef={apiRef}
                localeText={
                  filteredUserData?.length ? "" : "No available calls yet."
                }
                checkboxSelection={true}
                onSelectionModelChange={(ids) => {
                  const data = [];
                  const selectedRow = [];
                  let disabledStatus = true;
                  let callActionDisabledStatus = true;
                  filteredUserData.filter((row) => {
                    if (ids.includes(row.id)) {
                      data.push({ id: row.call_id });
                      selectedRow.push(row);
                      if (row.status === "ReadyToPublish") {
                        disabledStatus = false;
                      }
                      if (row.status === "incomplete") {
                        callActionDisabledStatus = false;
                      }
                      if (row.status === "processing") {
                        disabledStatus = true;
                      }
                    }
                  });
                  setDisabled(disabledStatus);
                  setSelectedIds(data);
                  setSelected(selectedRow);
                  setCallIds(ids);
                }}
                selectionModel={callIds}
                componentsProps={{
                  columnMenu: {
                    style: {
                      color: "#93989A", // Change this to your desired color
                    },
                  },
                }}
              />
            </Box>
          </Box>

          {/* Mobile */}
          <Box
            display={{
              xs: "block",
              lg: "none",
            }}
            sx={{
              background: "white",
              marginTop: "8px",
            }}
            width={"100%"}
            padding={"48px 30px"}
            borderRadius={"40px"}
          >
            <Box
              width={"100%"}
              justifyContent={"space-between"}
              alignItems={"center"}
              position="relative"
              marginTop="-25px"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                borderBottom: 1,
                borderColor: "divider",
                color: "black",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "24px",
                  lineHeight: "1.2",
                }}
              >
                Sessions List
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  gap: "12px",
                }}
              >
                {}
                <Checkbox
                  sx={{
                    color: "#E4E6F2",
                    "&.Mui-checked": {
                      color: "black",
                    },
                  }}
                  checked={selected.length > 0 && selected.length === userData.filter((row) => row.client_id !== user?.sub).length && selected}
                  onChange={handleSelectAllClick}
                />
                <BootstrapTooltip title={"Refresh"} placement="top-start">
                  <Box sx={iconStyle} onClick={reFetchUserData}>
                    <RefreshIcon color="enabled" />
                  </Box>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Trash"} placement="top-start">
                  <Box
                    sx={iconStyle}
                    onClick={() => {
                      if (selected.length > 0) {
                        openDeleteModal();
                      }
                    }}
                  >
                    {selected.length > 0 ? (
                      <DeleteIcon color="enabled" />
                    ) : (
                      <DeleteIcon color="disabled" />
                    )}
                  </Box>
                </BootstrapTooltip>
                <BootstrapTooltip
                  title={"Generate Insights"}
                  placement="top-start"
                >
                  <Box
                    sx={iconStyle}
                    onClick={() => {
                      if (
                        selected.length > 0 &&
                        selected.filter((row) => row.status === "incomplete")
                          .length === selected.length
                      ) {
                        openProcessModal();
                      }
                    }}
                  >
                    {selected.length > 0 &&
                    selected.filter((row) => row.status === "incomplete")
                      .length === selected.length ? (
                      <AssignIcon color="enabled" />
                    ) : (
                      <AssignIcon color="disabled" />
                    )}
                  </Box>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Share"} placement="top-start">
                  <Box
                    sx={iconStyle}
                    onClick={() => {
                      if (
                        selected.length > 0 &&
                        selected.filter(
                          (row) => row.status === "ReadyToPublish"
                        ).length === selected.length
                      ) {
                        setShowClientList(!showClientList);
                      }
                    }}
                  >
                    {selected.length > 0 &&
                    selected.filter((row) => row.status === "ReadyToPublish")
                      .length === selected.length ? (
                      <ShareIcon color="enabled" />
                    ) : (
                      <ShareIcon color="disabled" />
                    )}
                  </Box>
                </BootstrapTooltip>
                {showClientList && (
                  <Box
                    position="absolute"
                    zIndex={2}
                    key={2}
                    sx={{
                      right: {
                        xs: "-10px",
                        lg: "unset",
                      },
                      top: {
                        xs: "30px",
                        lg: "unset",
                      },
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      width: "430px",
                      background: "#F8F9FA",
                      padding: "16px 28px",
                      borderRadius: "8px",
                      boxShadow:
                        "14px 37px 52.6px 17px rgba(136, 136, 136, 0.12)",
                    }}
                    onMouseLeave={() => {
                      setShowClientList(false);
                    }}
                  >
                    <Box display="flex" flexDirection="column" gap="4px">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          sx={{
                            color: "#160042",
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            letterSpacing: "-0.03",
                          }}
                        >
                          Who is this session being shared with?
                        </Typography>
                        {/* <PersonAddAlt
                          sx={{
                            color: "black",
                            fontSize: "24px",
                            width: "24px",
                            height: "24px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowClientList(false)} 
                        /> */}
                      </Box>
                      <Box display="flex" alignItems="center" gap="4px">
                        <ErrorOutline
                          sx={{
                            color: "#00B11C",
                            fontSize: "11px",
                            width: "11px",
                            height: "11px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#6B7094",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.01",
                          }}
                        >
                          Once confirmed, the recipient will be notified
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "8px",
                        borderRadius: "8px",
                        background: "white",
                        maxHeight: "180px",
                        overflowY: "auto",
                      }}
                    >
                      {memberData && memberData.length > 0 ? (
                        memberData.map((client, key) =>
                          client.full_name ? (
                            <Box
                              key={key}
                              display="flex"
                              gap="12px"
                              alignItems="center"
                              padding="4px 12px"
                              borderRadius="4px"
                              sx={{
                                background:
                                  client.full_name === assignee.name
                                    ? "#F5F5F5"
                                    : "initial",
                                "&:hover": {
                                  background: "#F5F5F5",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => {
                                setAssignee({
                                  id: client.user_id,
                                  name: client.full_name,
                                });
                              }}
                            >
                              <Box
                                sx={{
                                  background:
                                    "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                  padding: "2px",
                                  borderRadius: "50%",
                                  width: "28px",
                                  height: "28px",
                                }}
                              >
                                <Box
                                  sx={{
                                    background: "white",
                                    padding: "1px",
                                    borderRadius: "50%",
                                    width: "24px",
                                    height: "24px",
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                    src={client.avatar || avatar}
                                  />
                                </Box>
                              </Box>
                              <Typography
                                color="#1B114A"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "300",
                                  lineHeight: "24px",
                                }}
                              >
                                {client.full_name}
                              </Typography>
                            </Box>
                          ) : null
                        )
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "12px",
                          }}
                        >
                          <Typography
                            color="#1B114A"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "300",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Add your first client
                          </Typography>
                          <PersonAddAlt
                            sx={{
                              color: "black",
                              fontSize: "24px",
                              width: "24px",
                              height: "24px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigate("/platform/enterprise/admin-portal")
                            }
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "24px",
                      }}
                    >
                      <Button
                        sx={{
                          width: "150px",
                          borderRadius: "8px",
                          color: "white",
                          background: "#160042",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px",
                          "&:hover": {
                            background: "#10B02A",
                          },
                        }}
                        disabled={!assignee.id}
                        onClick={openAssignModal}
                      >
                        Confirm
                      </Button>
                      <Button
                        sx={{
                          width: "150px",
                          borderRadius: "8px",
                          background: "transparent",
                          color: "#7D7D7D",
                          border: "1px solid #E4E6F1",
                          fontWeight: "bold",
                          "&:hover": {
                            borderColor: "#B52100",
                            color: "#C51919",
                          },
                        }}
                        onClick={() => setShowClientList(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
              
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              {userData.map((session, index) => (
                <Box
                  key={index}
                  paddingTop={"8px"}
                  paddingBottom={"8px"}
                  borderBottom={"1px solid #F3F3F3"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "flex-start",
                  }}
                >
                  {(session.client_id !== user?.sub) && (
                  <Checkbox
                    sx={{
                      color: "#E4E6F2",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                    checked={selected.map((row) => row.id).includes(session.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      if (e.target.checked) {
                        setSelected([...selected, session]);
                        setCallIds([...callIds, session.id]);
                      } else {
                        setSelected(
                          selected.filter((row) => row.id !== session.id)
                        );
                        setCallIds(callIds.filter((id) => id !== session.id));
                      }
                    }}
                  />
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Status
                    </Typography>
                    {session.status === "ReadyToPublish" ? (
                      <Box position="relative">
                        <Typography
                          sx={{
                            background: "white",
                            fontFamily: "Lexend",
                            color: "#B6B6B6",
                            fontSize: "14px",
                            fontWeight: 300,
                            textAlign: "center",
                          }}
                        >
                          View Insights
                        </Typography>
                        {processedClient === session.id && (
                          <Box
                            position="absolute"
                            zIndex={9999}
                            key={2}
                            sx={{
                              right: {
                                xs: "0",
                                lg: "unset",
                              },
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                              width: "430px",
                              background: "#F8F9FA",
                              padding: "16px 28px",
                              borderRadius: "8px",
                              boxShadow:
                                "14px 37px 52.6px 17px rgba(136, 136, 136, 0.12)",
                            }}
                            onMouseLeave={() => {
                              setProcessedClient("");
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="4px"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  sx={{
                                    color: "#160042",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "1.5",
                                    letterSpacing: "-0.03",
                                  }}
                                >
                                  Who is this session being shared with?
                                </Typography>
                                {/* <PersonAddAlt
                                  sx={{
                                    color: "black",
                                    fontSize: "24px",
                                    width: "24px",
                                    height: "24px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setProcessedClient("")}
                                /> */}
                              </Box>
                              <Box display="flex" alignItems="center" gap="4px">
                                <ErrorOutline
                                  sx={{
                                    color: "#00B11C",
                                    fontSize: "11px",
                                    width: "11px",
                                    height: "11px",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    color: "#6B7094",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "-0.01",
                                  }}
                                >
                                  Once confirmed, the recipient will be notified
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                padding: "8px",
                                borderRadius: "8px",
                                background: "white",
                                maxHeight: "180px",
                                overflowY: "auto",
                              }}
                            >
                              {memberData && memberData.length > 0 ? (
                                memberData.map((client, key) =>
                                  client.full_name ? (
                                    <Box
                                      key={key}
                                      display="flex"
                                      gap="12px"
                                      alignItems="center"
                                      padding="4px 12px"
                                      borderRadius="4px"
                                      sx={{
                                        background:
                                          client.full_name === assignee.name
                                            ? "#F5F5F5"
                                            : "initial",
                                        "&:hover": {
                                          background: "#F5F5F5",
                                          cursor: "pointer",
                                        },
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setAssignee({
                                          id: client.user_id,
                                          name: client.full_name,
                                        });
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          background:
                                            "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                          padding: "2px",
                                          borderRadius: "50%",
                                          width: "28px",
                                          height: "28px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            background: "white",
                                            padding: "1px",
                                            borderRadius: "50%",
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        >
                                          <Avatar
                                            sx={{
                                              width: "22px",
                                              height: "22px",
                                            }}
                                            src={client.avatar || avatar}
                                          />
                                        </Box>
                                      </Box>
                                      <Typography
                                        color="#1B114A"
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "300",
                                          lineHeight: "24px",
                                        }}
                                      >
                                        {client.full_name}
                                      </Typography>
                                    </Box>
                                  ) : null
                                )
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "12px",
                                  }}
                                >
                                  <Typography
                                    color="#1B114A"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Add your first client
                                  </Typography>
                                  <PersonAddAlt
                                    sx={{
                                      color: "black",
                                      fontSize: "24px",
                                      width: "24px",
                                      height: "24px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      navigate("/platform/enterprise/admin-portal")
                                    }
                                  />
                                </Box>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "24px",
                              }}
                            >
                              <Button
                                sx={{
                                  width: "150px",
                                  borderRadius: "8px",
                                  color: "white",
                                  background: "#160042",
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "8px",
                                  zIndex: 9999,
                                  "&:hover": {
                                    background: "#10B02A",
                                  },
                                }}
                                disabled={!assignee.id}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setProcessedClient("");
                                  openAssignModal();
                                }}
                              >
                                Confirm
                              </Button>
                              <Button
                                sx={{
                                  width: "150px",
                                  borderRadius: "8px",
                                  background: "transparent",
                                  color: "#7D7D7D",
                                  border: "1px solid #E4E6F1",
                                  fontWeight: "bold",
                                  "&:hover": {
                                    borderColor: "#B52100",
                                    color: "#C51919",
                                  },
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setProcessedClient("");
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ) : session.status === "processing" ? (
                      <Typography
                        sx={{
                          background: "white",
                          fontFamily: "Lexend",
                          color: "#B6B6B6",
                          fontSize: "14px",
                          fontWeight: 300,
                          textAlign: "center",
                        }}
                      >
                        Loading
                      </Typography>
                    ) : session.status === "incomplete" ? (
                      <Typography
                        sx={{
                          background: "white",
                          fontFamily: "Lexend",
                          color: "#B6B6B6",
                          fontSize: "14px",
                          fontWeight: 300,
                          textAlign: "center",
                        }}
                      >
                        Load Insights
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          background: "white",
                          fontFamily: "Lexend",
                          color: "#B6B6B6",
                          fontSize: "14px",
                          fontWeight: 300,
                          textAlign: "center",
                        }}
                      >
                        Shared
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Participant
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      {session.client_name !== null &&
                      session.client_name !== "null" &&
                      session.client_name !== "" ? (
                        <>
                          <Box
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 300,
                              lineHeight: "24px",
                              color: "#1B114A",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {session.client_name}
                          </Box>
                        </>
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            fontWeight: 300,
                            lineHeight: "24px",
                            color: "#B6B6B6",
                          }}
                        >
                          -
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Client
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      {session.client !== null &&
                      session.client !== "null" &&
                      session.client !== "" ? (
                        <>
                          <Box
                            sx={{
                              background:
                                "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                              padding: "2px",
                              borderRadius: "50%",
                              width: "28px",
                              height: "28px",
                            }}
                          >
                            <Box
                              sx={{
                                background: "white",
                                padding: "1px",
                                borderRadius: "50%",
                                width: "24px",
                                height: "24px",
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: "22px",
                                  height: "22px",
                                }}
                                src={session.picture || avatar}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 300,
                              lineHeight: "24px",
                              color: "#1B114A",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {session.client}
                          </Box>
                        </>
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            fontWeight: 300,
                            lineHeight: "24px",
                            color: "#B6B6B6",
                          }}
                        >
                          -
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Call Subject
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        fontWeight: 300,
                        lineHeight: "24px",
                        color: "#B6B6B6",
                      }}
                    >
                      {session.call_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Date
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        fontWeight: 300,
                        lineHeight: "24px",
                        color: "#B6B6B6",
                      }}
                    >
                      {session.zoom_date !== null &&
                        session.zoom_date !== "" &&
                        dayjs(session.zoom_date).utc().format('MM/DD/YY')}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Duration
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        fontWeight: 300,
                        lineHeight: "24px",
                        color: "#B6B6B6",
                      }}
                    >
                      {session.duration_minutes} mins
                    </Typography>
                  </Box>
                </Box>
              ))}
            </CustomTabPanel>
            {/* <CustomTabPanel value={tabValue} index={1}>
              {unprocessedData.map((user, index) => (
                <Box
                  key={"userData" + index}
                  paddingTop={"20px"}
                  paddingBottom={"20px"}
                  borderBottom={"1px solid #F3F3F3"}
                  borderTop={"1px solid #F3F3F3"}
                >
                  <Typography
                    sx={{
                      color: "#202226",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {user.client_name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#93989A",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {user.call_name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#93989A",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {user.zoom_date.toLocaleString()}
                  </Typography>
                </Box>
              ))}
            </CustomTabPanel> */}
          </Box>
          {zoomProcessModal()}
          {deleteModalContent()}
          {assignModalContent()}
          {assignedModalContent()}
          {processModalContent()}
          {unprocessModalContent()}
          {unassignModalContent()}
        </>
      )}
      {accessTo === 'client_portal' && (
        <>
          <Box
            display={{
              xs: "none",
              lg: "block",
            }}
            sx={{
              padding: "24px",
              background: "white",
              borderRadius: "30px",
              marginTop: "8px",
              "& .MuiTabs-root": {
                width: "100%",
              },

              "& .MuiFormControl-root": {
                width: "160px",
              },
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "24px",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "24px",
                  },
                  lineHeight: "1.2",
                  fontWeight: 300,
                  color: "#202226",
                  minWidth: "160px",
                  marginRight: {
                    lg: "24px",
                    xl: "48px",
                  },
                }}
              >
                Sessions List
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "812px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    width: "100%",
                    "& > :nth-of-type(n)": {
                      flexBasis: {
                        xs: "100%",
                        md: "50%",
                        lg: "25%",
                      },
                    },
                    justifyContent: "flex-end",
                  }}
                >
                  <_CallListFilterSelect
                    value={filterCoach}
                    items={filterCoachItems}
                    handleChange={(e) => setFilterCoach(e)}
                  />
                  <_CallListFilterDateRange
                    startDate={filterStartDate}
                    endDate={filterEndDate}
                    handleStartDateChange={(date) => {
                      setFilterStartDate(date);
                    }}
                    handleEndDateChange={(date) => {
                      setFilterEndDate(date);
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      color: "#FF5E00",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      borderRadius: "45px",
                      border: "1px solid black",
                      paddingY: "4px",
                      justifyContent: "center",
                      textAlign: "center",
                      letterSpacing: "-0.32px",
                      lineHeight: "24px",
                      "&:hover": {
                        cursor: "pointer",
                        background: "#F7F7F7",
                      },
                    }}
                    data-cy="session-list-reset"
                    onClick={handleResetFilters}
                  >
                    Reset Filters
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="10px"
              alignItems={"center"}
              marginTop="16px"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  transition: "all 0.3s",
                  cursor: "pointer",
                  padding: "8px", 
                  borderRadius: "8px", 
                  backgroundColor: isRefreshHovered ? "#F5F7FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#F5F7FF", // Hover background color
                  }
                }}
                onMouseEnter={() => setIsRefreshHovered(true)}
                onMouseLeave={() => setIsRefreshHovered(false)}
                onClick={() => {
                  reFetchUserData();
                }}
              >
                {isRefreshHovered === false ? (
                  <>
                    <RefreshIcon color="enabled" />
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "#2D2D2D",
                      }}
                    >
                      Refresh
                    </Typography>
                  </>
                ) : (
                  <>
                    <RefreshIcon color="hovered" />
                    <Typography
                      sx={{
                        background:
                          "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                        backgroundOrigin: "border-box",
                        backgroundClip: "text",
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "transparent",
                      }}
                    >
                      Refresh
                    </Typography>
                  </>
                )}
              </Box>
              {accessTo === 'platform_professional' && (
                <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    transition: "all 0.3s",
                    padding: "8px", // Add padding for better hover effect
                    borderRadius: "8px", // Add border-radius for a rounded effect
                    "&:hover": {
                      backgroundColor: "#F5F7FF", // Hover background color
                    },
                    cursor:
                      selected.length > 0 &&
                      selected.filter((row) => row.status === "incomplete")
                        .length === selected.length
                        ? "pointer"
                        : "not-allowed",
                  }}
                  disabled={isLoadingProcessing}
                  onMouseEnter={() => setIsAssignHovered(true)}
                  onMouseLeave={() => setIsAssignHovered(false)}
                  onClick={() => {
                    if (
                      selected.length > 0 &&
                      selected.filter((row) => row.status === "incomplete")
                        .length === selected.length
                    ) {
                      openProcessModal();
                    }
                  }}
                >
                  {isLoadingProcessing ? (
                    <CircularProgress size={15} />
                  ) : (
                    <>
                      {selected.length > 0 &&
                      selected.filter((row) => row.status === "incomplete")
                        .length === selected.length ? (
                        !isAssignHovered ? (
                          <>
                            <AssignIcon color="enabled" />
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#2D2D2D",
                              }}
                            >
                              Generate Insights
                            </Typography>
                          </>
                        ) : (
                          <>
                            <AssignIcon color="hovered" />
                            <Typography
                              sx={{
                                background:
                                  "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                backgroundOrigin: "border-box",
                                backgroundClip: "text",
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "transparent",
                              }}
                            >
                              Generate Insights
                            </Typography>
                          </>
                        )
                      ) : (
                        <>
                          <AssignIcon color="disabled" />
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "#B6B6B6",
                            }}
                          >
                            Generate Insights
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </Box>
                <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        transition: "all 0.3s",
                        padding: "8px", // Add padding for better hover effect
                        borderRadius: "8px", // Add border-radius for a rounded effect
                        "&:hover": {
                          backgroundColor: "#F5F7FF", // Hover background color
                        },
                        cursor: selected.length > 0 ? "pointer" : "not-allowed",
                      }}
                      onMouseEnter={() => setIsDeleteHovered(true)}
                      onMouseLeave={() => setIsDeleteHovered(false)}
                      onClick={() => {
                        if (selected.length > 0) {
                          openDeleteModal();
                        }
                      }}
                    >
                      {selected.length > 0 ? (
                        isDeleteHovered === false ? (
                          <>
                            <DeleteIcon color="enabled" />
                            <Typography
                              sx={{
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#2D2D2D",
                              }}
                            >
                              Delete
                            </Typography>
                          </>
                        ) : (
                          <>
                            <DeleteIcon color="hovered" />
                            <Typography
                              sx={{
                                background:
                                  "linear-gradient(345deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                                backgroundOrigin: "border-box",
                                backgroundClip: "text",
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "transparent",
                              }}
                            >
                              Delete
                            </Typography>
                          </>
                        )
                      ) : (
                        <>
                          <DeleteIcon color="disabled" />
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "12px",
                              lineHeight: "20px",
                              color: "#B6B6B6",
                            }}
                          >
                            Delete
                          </Typography>
                        </>
                      )}
                    </Box>
                </>
              )}
            </Box>
            <Box
              display={{
                xs: "none",
                lg: "block",
              }}
            >
              <_CallDataGrid
                height={`${gridHeight + 52 > 200 ? gridHeight + 70 : 200}px`}
                maxHeight="628px"
                rows={filteredUserData}
                columns={clientColumns}
                loading={loading}
                isRowSelectable={isRowSelectable}
                apiRef={apiRef}
                localeText={
                  assignedData?.length ? "" : "No available calls yet."
                }
                checkboxSelection={accessTo === 'platform_professional' ? true : false}
                onSelectionModelChange={(ids) => {
                  const data = [];
                  const selectedRow = [];
                  let disabledStatus = true;
                  let callActionDisabledStatus = true;
                  filteredUserData.filter((row) => {
                    if (ids.includes(row.id)) {
                      data.push({ id: row.call_id });
                      selectedRow.push(row);
                      if (row.status === "ReadyToPublish") {
                        disabledStatus = false;
                      }
                      if (row.status === "incomplete") {
                        callActionDisabledStatus = false;
                      }
                    }
                  });
                  setDisabled(disabledStatus);
                  setSelectedIds(data);
                  setSelected(selectedRow);
                  setCallIds(ids);
                }}
                selectionModel={callIds}
                componentsProps={{
                  columnMenu: {
                    style: {
                      color: "#93989A", // Change this to your desired color
                    },
                  },
                }}
              />
            </Box>
          </Box>

          {/* Mobile */}
          <Box
            display={{
              xs: "block",
              lg: "none",
            }}
            sx={{
              background: "white",
              marginTop: "8px",
            }}
            width={"100%"}
            padding={"48px 30px"}
            borderRadius={"40px"}
          >
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent={"space-between"}
              alignItems={"center"}
              position="relative"
              paddingBottom="10px"
              sx={{ borderBottom: 1, borderColor: "divider", color: "black" }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "#202226",
                  fontSize: "22px",
                  lineHeight: "1.2",
                  fontWeight: "500",
                }}
              >
                Your Calls
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <BootstrapTooltip title={"Refresh"} placement="top-start">
                  <Box sx={iconStyle} onClick={reFetchUserData}>
                    <RefreshIcon color="enabled" />
                  </Box>
                </BootstrapTooltip>
              </Box>
              {/* <Box display="flex" alignItems="center">
                <Box sx={iconStyle} onClick={undoActions}>
                  <Undo />
                </Box>
                <Box sx={iconStyle} onClick={reFetchUserData}>
                  <Replay />
                </Box>
                <Box sx={iconStyle} onClick={handleProcessCall}>
                  <Sync />
                </Box>
                <Box sx={iconStyle} onClick={openDeleteModal}>
                  <Delete />
                </Box>
                <Button
                  sx={{
                    width: "100px",
                    height: "40px",
                    marginRight: "20px",
                    borderRadius: "8px",
                    color: "white",
                    textTransform: "none",
                    minWidth: "auto!important",
                    textWrap: "nowrap!important",
                    position: "relative",
                    background:
                      "linear-gradient(302deg, #242F8C 13.07%, #03093A 43.61%, #D8659B 89.22%)",
                  }}
                  onClick={() => setShowClientList(!showClientList)}
                  endIcon={<KeyboardArrowDown />}
                >
                  Assign
                </Button>
                {showClientList && (
                  <Box
                    position="absolute"
                    width="100px"
                    zIndex={2}
                    border="1px solid black"
                    borderRadius="10px"
                    padding="10px"
                    top="65px"
                    right="35px"
                    sx={{
                      background: "white",
                    }}
                  >
                    {clientData.map((client, key) => (
                      <Box
                        key={key}
                        padding="5px"
                        borderRadius="5px"
                        sx={{
                          "&:hover": {
                            background: "grey",
                          },
                        }}
                        onClick={() => {
                          assignCall(client.client_name);
                        }}
                      >
                        <Typography>{client.client_name}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box> */}
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              {assignedData.map((user, index) => (
                <Box
                  key={index}
                  paddingTop={"8px"}
                  paddingBottom={"8px"}
                  borderBottom={"1px solid #F3F3F3"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Call Subject
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        fontWeight: 300,
                        lineHeight: "24px",
                        color: "#B6B6B6",
                      }}
                    >
                      {user.call_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Coach
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      {user.coach_name !== null &&
                      user.coach_name !== "null" &&
                      user.coach_name !== "" ? (
                        <>
                          <Box
                            sx={{
                              background:
                                "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                              padding: "2px",
                              borderRadius: "50%",
                              width: "28px",
                              height: "28px",
                            }}
                          >
                            <Box
                              sx={{
                                background: "white",
                                padding: "1px",
                                borderRadius: "50%",
                                width: "24px",
                                height: "24px",
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: "22px",
                                  height: "22px",
                                }}
                                src={client.picture || avatar}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 300,
                              lineHeight: "24px",
                              color: "#1B114A",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {user.coach_name}
                          </Box>
                        </>
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            fontWeight: 300,
                            lineHeight: "24px",
                            color: "#B6B6B6",
                          }}
                        >
                          -
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Date
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        fontWeight: 300,
                        lineHeight: "24px",
                        color: "#B6B6B6",
                      }}
                    >
                      {new Date(user.zoom_date).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                      })}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontsize: "12px",
                        fontWeight: "500",
                        lineHeight: "1.67",
                        color: "#B6B6B6",
                      }}
                    >
                      Duration
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        fontWeight: 300,
                        lineHeight: "24px",
                        color: "#B6B6B6",
                      }}
                    >
                      {user.duration_minutes} mins
                    </Typography>
                  </Box>
                </Box>
              ))}
            </CustomTabPanel>
            {/* <CustomTabPanel value={tabValue} index={1}>
              {unprocessedData.map((user, index) => (
                <Box
                  key={"userData" + index}
                  paddingTop={"20px"}
                  paddingBottom={"20px"}
                  borderBottom={"1px solid #F3F3F3"}
                  borderTop={"1px solid #F3F3F3"}
                >
                  <Typography
                    sx={{
                      color: "#202226",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {user.client_name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#93989A",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {user.call_name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#93989A",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {user.zoom_date.toLocaleString()}
                  </Typography>
                </Box>
              ))}
            </CustomTabPanel> */}
          </Box>
          {processModalContent()}
          {zoomProcessModal()}
          {deleteModalContent()}
        </>
      )}
    </>
  );
};

const iconStyle = {
  width: "40px",
  height: "40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    background: "#F3F3F3",
    borderRadius: "100%",
    cursor: "pointer",
  },

  "& svg": {
    color: "#A6A6A6",
  },
};

export default Team;
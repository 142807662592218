import { Box, Tooltip, ListItemText, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Menu, MenuItem } from "@mui/material";
import { setCollapsed } from "slices/LayoutSlice";
import Logo from "./logo";
import avatar from "assets/avatar.png";
import { useSignOut } from "hooks/useSignOut";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getUrl } from "aws-amplify/storage";

const Topbar = ({ user, pictureChanged }) => {
  const [picture, setPicture] = useState(null);
  const [loading, setLoading] = useState(true);
  const collapsed = useSelector((state) => state.layout.collapsed);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSignOut = useSignOut();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to ensure proper encoding for spaces and avoid double encoding
  const encodeFilePath = (path) => {
    // Only encode the file name part, not the entire path
    const fileName = path.split('/').pop();  // Get the file name
    return encodeURIComponent(fileName).replace(/%20/g, ' '); // Replace %20 with space if necessary
  };

  const fetchUserProfilePicture = async () => {
    console.log("Step 1: Starting to fetch the profile picture...");

    console.log("Step 2: User profile picture:", user.picture);

    if (!user || !user.picture || user.picture === "") {
      console.log(
        "Step 3: No profile picture found for user, using fallback avatar."
      );
      setPicture(avatar);
      setLoading(false);
      return;
    }

    try {
      // Handle the file path by encoding the file name part properly
      const encodedFileName = encodeFilePath(user.picture);
      const filePath = `public/avatar/${encodedFileName}`;
      console.log("Step 4: Constructed file path with proper encoding:", filePath);

      // Fetch signed URL from Amplify Storage
      console.log("Step 5: Fetching signed URL for file...");
      const { url, expiresAt } = await getUrl({
        path: filePath,
        expires: 900, // URL valid for 15 minutes we can increase according to our requirement
        validateObjectExistence: true, // Validate if the object exists in S3
      });

      console.log("Step 6: Received signed URL:", url);
      console.log("Step 7: Signed URL expires at:", expiresAt);

      if (url) {
        setPicture(url.href); // Set the profile picture to the signed URL
        console.log("Step 8: Profile picture set to:", url.href);
      } else {
        console.warn("Step 9: Failed to retrieve signed URL.");
        setPicture(avatar); // Fallback to default avatar
      }
    } catch (error) {
      console.error(
        "Error in try block while fetching profile picture:",
        error
      );
      setPicture(avatar); // Fallback to default avatar
    } finally {
      setLoading(false);
      console.log("Step 10: Loading state set to false.");
    }
  };

  useEffect(() => {
    console.log("Component mounted or pictureChanged triggered.");
    fetchUserProfilePicture();
  }, [user, pictureChanged]);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: { xs: "none", sm: "none", lg: "flex" },
          padding: "0px 30px",
          justifyContent: "flex-end",
          alignItems: "center",
          zIndex: 900,
          background: "transparent",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "24px 40px",
            justifyContent: "space-between",
            alignItems: "center",
            background: "transparent",
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Tooltip title="Click here to sign out and adjust settings">
              <Box
                sx={{
                  background:
                    "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  marginLeft: "-90px",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <Box
                  sx={{
                    background: "white",
                    padding: "4px",
                    borderRadius: "50%",
                    width: "54px",
                    height: "54px",
                  }}
                >
                  <LazyLoadImage
                    alt="Profile Picture"
                    src={picture || avatar} // Display signed URL or fallback
                    effect="blur"
                    width="46px"
                    height="46px"
                    style={{ borderRadius: "50%" }}
                    onError={() => {
                      console.error("Error loading profile picture, using fallback avatar.");
                      setPicture(avatar);
                    }} // Fallback to avatar on error
                  />
                </Box>
              </Box>
            </Tooltip>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            sx: {
              marginLeft: { lg: "-15px", md: "0px", sm: "6px" },
              background: "#fff",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
              borderRadius: "12pt",
            },
          }}
        >
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
            onClick={() => {
              handleClose();
              navigate("/settings");
            }}
          >
            <ListItemText primary="Settings" sx={{ color: "black" }} />
          </MenuItem>
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
            onClick={async () => {
              try {
                handleClose();
                handleSignOut();
              } catch (error) {
                console.error("Error signing out: ", error);
              }
            }}
          >
            <ListItemText primary="Sign Out" sx={{ color: "black" }} />
          </MenuItem>
        </Menu>
      </Box>
      <Box
        className="topbar"
        sx={{
          display: { xs: "flex", lg: "none" },
          padding: "0px 30px",
          width: "100vw",
          justifyContent: "space-between",
          alignItems: "center",
          background: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(10px)",
          height: "82px",
          position: "relative",
          borderBottom: "1px solid #F5F5F5",
        }}
      >
        <MenuOutlinedIcon
          sx={{
            width: "24px",
            height: "24px",
            color: "#0F0934",
            padding: "10px",
            border: "1px solid #F5F5F5",
            borderRadius: "50%",
            boxSizing: "content-box",
          }}
          onClick={() => dispatch(setCollapsed(!collapsed))}
        />
        <Box
          width="155px"
          sx={{
            transform: "translateX(-25%)",
          }}
        >
          <Logo />
        </Box>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Box
            sx={{
              background:
                "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
              padding: "3px",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              marginLeft: "-90px",
            }}
            onClick={handleClick}
          >
            <Box
              sx={{
                background: "white",
                padding: "4px",
                borderRadius: "50%",
                width: "54px",
                height: "54px",
              }}
            >
              <LazyLoadImage
                alt="Profile Picture"
                src={picture || avatar}
                effect="blur"
                width="46px"
                height="46px"
                style={{ borderRadius: "50%" }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Topbar;

import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { _ActivityFeedFilter } from "./ActivityFeedFilter";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const ActivityFeed = ({ activityData, avatar, clientPicture, filterEventItems, filterClientItems, filterParticipantItems, loading }) => {
  const [filterEvent, setFilterEvent] = useState("none");
  const [filterClient, setFilterClient] = useState("none");
  const [filterParticipant, setFilterParticipant] = useState("none")
  const [filteredActivityData, setFilteredActivityData] = useState(activityData);

  const eventTypeMapping = {
    processed_call: "generated insights",
    assigned_call: "shared session",
    unprocessed_call: "new session"
  };


  useEffect(() => {
    let filteredData = activityData;

    if (filterEvent !== "none") {
      filteredData = filteredData.filter(item => item.event_type === filterEvent);
    }

    if (filterClient !== "none") {
      filteredData = filteredData.filter(item => item.coachee === filterClient || item.coachee_name === filterClient);
    }    

    if (filterParticipant !== "none") {
      filteredData = filteredData.filter(item => item.participant_name === filterParticipant);
    }

    setFilteredActivityData(filteredData);
  }, [filterEvent, filterClient, filterParticipant, activityData]);

  const handleResetFilters = () => {
    setFilterEvent("none");
    setFilterClient("none");
    setFilterParticipant("none")
  };

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          lg: "40%",
        },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        position: {
          lg: "sticky",
        },
        top: {
          lg: "12px",
        },
        maxHeight: {
          md: "calc(100vh - 130px)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          paddingX: {
            xs: "16px",
            md: "20px",
          },
          paddingY: {
            xs: "16px",
            md: "20px",
          },
          borderRadius: "40px",
          background: "white",
          overflow: {
            lg: "hidden",
          },
          maxHeight: {
            lg: "calc(100vh - 158px)",
            xl: "unset",
          },
          minHeight: {lg: "calc(100vh - 158px)"}
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: 28,
              color: "black",
            }}
          >
            Activity Feed
          </Typography>
          <Typography
            sx={{
              fontFamily: "Lexend",
              color: "#FF5E00",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              borderRadius: "45px",
              border: "1px solid black",
              paddingY: "4px",
              paddingX: "20px",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              letterSpacing: "-0.32px",
              lineHeight: "24px",
              "&:hover": {
                cursor: "pointer",
                background: "#F7F7F7"
              },
            }}
            onClick={handleResetFilters}
          >
            Reset Filters
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px"
          }}
        >
          <_ActivityFeedFilter
            value={filterEvent}
            items={filterEventItems}
            handleChange={(e) => setFilterEvent(e)}
          />
          <_ActivityFeedFilter
            value={filterParticipant}
            items={filterParticipantItems}
            handleChange={(e) => setFilterParticipant(e)}
          />
           <_ActivityFeedFilter
            value={filterClient}
            items={filterClientItems}
            handleChange={(e) => setFilterClient(e)}
          />
        </Box>
        <Box
          sx={{
            overflowY: {
              lg: "auto",
            },
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: '100%',
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{color: "black"}}/>
            </Box>
          ) : (
            filteredActivityData.length > 0 &&
            filteredActivityData.map((row, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: {
                    xs: "16px",
                    xl: "32px",
                  },
                  paddingY: "12px",
                  borderBottom: "1px solid #D4D4D4",
                }}
              >
                <Box
                  sx={{
                    background: "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                    padding: "3px",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                  }}
                >
                  <Box
                    sx={{
                      background: "white",
                      padding: "4px",
                      borderRadius: "50%",
                      width: "54px",
                      height: "54px",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: "46px",
                        height: "46px",
                        display: {
                          md: "block",
                        },
                      }}
                      src={row.avatar || avatar}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "black",
                      lineHeight: "1.5",
                      letterSpacing: "0.3px",
                    }}
                  >
                    {row.event_type === "assigned_call" ? (
                      <>
                        <b>{row.coachee ?? row.coachee_name}</b>
                        {` was assigned a call titled ${row.call_name} with ${row.action_count} ${row.action_count === 1 ? 'action' : 'actions'}`}
                      </>
                    ) : row.event_type === "processed_call" ? (
                      <>
                        <b>{row.participant_name}</b>
                        {` participated in a coaching session on ${ dayjs(row.zoom_date).utc().format('MM/DD/YY')} titled ${row.call_name.replace(/_/g, ' ')}`}
                      </>
                    ) : row.event_type === "unprocessed_call" ? (
                      <>
                        <b>{row.participant_name}</b>
                        {` participated in a coaching session on ${dayjs(row.zoom_date).utc().format('MM/DD/YY')} titled ${row.call_name.replace(/_/g, ' ')}`}
                      </>
                    ) : row.event_type === "action_status" ? (
                      <>
                        <b>An action's status</b>
                        {` for ${row.coachee ?? row.coachee_name} was updated on ${dayjs(row.date_added).utc().format('MM/DD/YY')}`}
                      </>
                    ) : row.event_type === "assigned_action" ? (
                      <>
                      <b>An action</b>
                      {` was assigned to ${row.coachee ?? row.coachee_name} on ${dayjs(row.date_added).utc().format('MM/DD/YY')}`}
                      </>
                    ) : row.event_type === "action_due_date" ? (
                      <>
                      <b>An action's due date</b>
                      {` was changed to ${dayjs(row.due_date).utc().format('MM/DD/YY')} for ${row.coachee ?? row.coachee_name} on ${dayjs(row.date_added).utc().format('MM/DD/YY')}`}
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "14px",
                      color: "#BCBCBC",
                      lineHeight: "1.35",
                    }}
                  >
                    {row.event_type === "processed_call" ? (
                      "Status: Insights Generated - Processed Call"
                    ) : row.event_type === "unprocessed_call" ? (
                      "Status:  New Session - Unprocessed Call"
                    ) : row.event_type === "assigned_call" ? (
                      `Status: Shared - Assigned Call`
                    ) : row.event_type === "action_status" ? (
                      `Status: ${row.action_status}`
                    ): row.event_type === "assigned_action" ? (
                      `Status: ${row.action_status}`
                    ): row.event_type === "action_due_date" ? (
                      `Status: ${row.action_status}`
                    ): (
                      <></>
                    )}
                  </Typography>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityFeed;

import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import ToolBar from "./ToolBar/ToolBar";
import axios from "axios";
import UsersTable from "./Table/UsersTable";
import OwnerAddMemberModal from "./AddMembers/OwnerAddMemberModal";
import OwnerAddSuperAdminModal from "./AddMembers/OwnerAddSuperAdminModal";
import AddPrivateUserModal from "./AddMembers/AddPrivateUserModal";
import CreateTeamModal from "./CreateTeams/CreateTeamModal";
import DeleteMembersModal from "./DeleteMembers/DeleteMembersModal";
import AdminPortalActionIcon from "../Icons/AdminPortalAction.svg";
import ActionOnUserModal from "./UserActions/ActionOnUserModal";
import { toast } from "react-toastify";
import { generateClient } from "aws-amplify/data";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });


const Owner = ({ user, userData }) => {
  const [selectedView, setSelectedView] = useState("list");
  const [selected, setSelected] = useState([]);
  const [companyMembers, setCompanyMembers] = useState([]); // Store company members here
  const [companyInformation, setCompanyInformation] = useState([]);
  const [teamsInformation, setTeamsInformation] = useState([]);
  const [loading, setLoading] = useState(false); // For loading state
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false); // Modal state
  const [openAddSuperAdminModal, setOpenAddSuperAdminModal] = useState(false); // Modal state
  const [openCreateTeamModal, setOpenCreateTeamModal] = useState(false);
  const [openActionOnUserModal, setOpenActionOnUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [availableSeats, setAvailableSeats] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openAddPrivateUserModal, setOpenAddPrivateUserModal] = useState(false);



  // Function to fetch company members
  const fetchCompanyData = async() => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`, {
        params: {
          userId: user?.sub,
          userGroup: user?.role,
        }
      });
      setCompanyMembers(response.allMembers);
      setCompanyInformation(response.companyInformation);
      setTeamsInformation(response.teams);

      // Update selectedUser with the latest data if it's set
      if (selectedUser && selectedUser.user_id) {
        const updatedUser = response.members.find(member => member.user_id === selectedUser.user_id);
        if (updatedUser) {
          setSelectedUser({
            ...updatedUser,
            id: selectedUser.id, // Preserve the id if needed
            title: updatedUser.company_role_name
          });
        } else {
          // If the user is no longer in the members list (e.g., deleted), close the modal
          setSelectedUser(null);
          setOpenActionOnUserModal(false);
        }
      }      

      const {data: responseSeats, errors} = await resourceClient.queries.checkCoacheeInviteAllowance({
        userID: user?.sub
      })

      setAvailableSeats(responseSeats.coachee_seat_tier_allowance - responseSeats.coachee_seat_count)
      setLoading(false);
    } catch (error) {
      console.log("Error fetching company members: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const handleDelete = async () => {
    try {
      setLoadingDelete(true);
  
      // Call the API to delete users
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-company-members`, {
        userIds: selected, 
        companyId: userData?.companyInformation?.company_id,
        user_id: user?.sub
      });
  
      const { deletedCount } = response.data; // API should return the count of deleted users
  
      // Decrement the seat count based on the number of deleted users
      for (let i = 0; i < deletedCount; i++) {
        await resourceClient.mutations.decrementCoacheeSeatCount({
          user_id: user?.sub
        });
      }
  
      toast.success(`${deletedCount} user${deletedCount > 1 ? 's' : ''} deleted successfully!`);
      setOpenDeleteModal(false);
      fetchCompanyData(); // Refresh company data after deletion
    } catch (error) {
      console.error('Error deleting users:', error);
      toast.error('Failed to delete users. Please try again.');
    } finally {
      setLoadingDelete(false);
    }
  };  

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true); // Open the delete modal
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false); // Close the delete modal
  };


  //define the columns
  const columns = [
    {
      field: 'full_name',
      headerName: 'Full Name',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: '#1B114A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '300', wordWrap: 'break-word' }}
        >
          {params.value}
        </Typography>
      ),
    },
    // {
    //   field: 'email',
    //   headerName: 'Email',
    //   width: 230,
    //   renderCell: (params) => {
    //     let displayedEmail = params.value;
    
    //     // Replace specific email addresses with predefined ones
    //     if (params.value === 'r@altercall.com') {
    //       displayedEmail = 'coach@example.com';
    //     } else if (params.value === 'd@pompaprogram.com') {
    //       displayedEmail = 'client@example.com';
    //     }
    
    //     return (
    //       <Typography
    //         sx={{
    //           color: '#93989A',
    //           fontSize: 14,
    //           fontFamily: 'Lexend',
    //           fontWeight: '400',
    //           wordWrap: 'break-word'
    //         }}
    //       >
    //         {displayedEmail}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: 'team_name',
      headerName: 'Team',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            color: 'black',
            fontSize: 14,
            fontFamily: 'Lexend',
            fontWeight: '400',
            whiteSpace: 'nowrap',        // Prevents text from wrapping to a new line
            overflow: 'hidden',          // Hides the overflow content
            textOverflow: 'ellipsis',    // Adds ellipsis for text overflow
            maxWidth: 150,            // Ensures it takes up available space
          }}
        >
          {params.value.length > 0 ? params.value.join(', ') : ''}
        </Typography>
      ),
    },
    { 
      field: 'role',
      headerName: 'Role',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: '#93989A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
        >
          {params.value || 'No role'}
        </Typography>
      )
    },
    { 
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: '#93989A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
        >
          {params.value || 'No Title'}
        </Typography>
      )
    },
    {
      field: 'invite_status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: params.value === 'accepted' ? '#00F218' : 'orange', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
        field: 'team_leader',
        headerName: 'Team Leader',
        flex: 1,
        renderCell: (params) => {
            // Ensure params.value is defined and is an array
            const teamLeaders = Array.isArray(params.value) ? params.value : [];
        
            // Join the team leader names with a comma, or use a placeholder text
            const leaderNames = teamLeaders.length > 0 ? teamLeaders.join(', ') : 'No Team Leader';
        
            return (
            <Typography
                sx={{
                color: '#1B114A',
                fontSize: 14,
                fontFamily: 'Lexend',
                fontWeight: '300',
                wordWrap: 'break-word',
                }}
            >
                {leaderNames}
            </Typography>
            );
        },
    },    
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box 
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "4px",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer"
                },
              }}
              onClick={() => handleActionOnUser(params.row)}
            >
              <AdminPortalActionIcon />
              <Typography
                sx={{ color: '#93989A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
              >
                Actions
              </Typography>
            </Box>
          </Box>
        );
      }
    }
  ];
  

  const rows = companyMembers.map((member, index) => {
    const teams = member.teams.map(team => team.team_name);
  
    // Create a Set to keep track of unique admin IDs to avoid duplicates
    const uniqueAdminNames = new Set();
  
    // Iterate over each team to collect the admin names
    member.teams.forEach(team => {
      if (team.team_admins) {
        team.team_admins.forEach(admin => {
          uniqueAdminNames.add(admin.full_name);
        });
      }
    });
  
    // Convert the Set to an array to list all unique admin names
    const adminFullNames = Array.from(uniqueAdminNames);
  
    return {
      id: index,
      user_id: member.user_id,
      full_name: member.full_name,
      email: member.email,
      team_name: teams.length > 0 ? teams : ['No Team'],
      teams: member.teams,
      role: member.role || "No Role",
      title: member.company_role_name || "No Title",
      invite_status: member.invite_status || "",
      team_leader: adminFullNames,
    };
  });
  
  

  // Function to handle switching team
  const handleActionOnUser = (member) => {
    setSelectedUser(member);
    handleOpenActionOnUserModal();
  };

  // Function to open/close the Add Member modal
  const handleOpenAddMemberModal = () => setOpenAddMemberModal(true);
  const handleOpenAddSuperAdminModal = () => setOpenAddSuperAdminModal(true);
  const handleOpenCreateTeamModal = () => setOpenCreateTeamModal(true);
  const handleOpenActionOnUserModal = () => setOpenActionOnUserModal(true);
  const handleopenAddPrivateUserModal = () => setOpenAddPrivateUserModal(true)

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "30px",
        paddingX: { xs: "30px", md: "50px" },
        paddingY: { xs: "30px", md: "30px" },
        maxHeight: { md: "calc(100vh - 100px)" },
        height: "100%",
        overflow: "hidden",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", height: "100%" }}>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ color: 'black', fontSize: 28, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word' }}>
            Teams & Members
          </Typography>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "15px" }}>
            <Typography sx={{ color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word', borderRadius: 50, padding: "12px", background: "#F1F1F1" }}>
              {`${availableSeats} seats are open`}
            </Typography>
            <Button onClick={handleOpenAddSuperAdminModal} sx={{ color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word', borderRadius: 50, border: '2px black solid' }}>
              Add a Super Admin
            </Button>
            <Button onClick={handleOpenAddMemberModal} sx={{ color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word', borderRadius: 50, border: '2px black solid' }}>
              Add Members
            </Button>
            <Button onClick={handleopenAddPrivateUserModal} sx={{ color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word', borderRadius: 50, border: '2px black solid' }}>
              Add Private Users
            </Button>
            <Button onClick={handleOpenCreateTeamModal} sx={{ color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word', borderRadius: 50, border: '2px black solid' }}>
              Create Team
            </Button>
          </Box>
        </Box>
        {/* Styled DataGrid Component */}
        <Box sx={{ flexGrow: 1, width: '100%', display: "flex", flexDirection: "column", gap: "8px" }}>
          <ToolBar user={user} selected={selected} fetchCompanyData={fetchCompanyData} openDeleteModal={() => setOpenDeleteModal(true)}/>
          <UsersTable
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            loading={loading}
            onSelectionModelChange={(newSelection) => {
              const selectedUserIds = newSelection.map(selectionId => {
                const selectedUser = rows.find(row => row.id === selectionId);
                return selectedUser.user_id; // Return the user_id instead of the index id
              });
              setSelected(selectedUserIds); // Update state with selected user_ids
            }}
            disableColumnFilter
            disableSelectionOnClick
            isRowSelectable={(params) => params.row.user_id !== user?.sub}
            getRowClassName={(params) => 
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            localeText={{
                noRowsLabel: 'No data available',
            }}
            sx={{
                "& .MuiDataGrid-columnHeaderTitleContainerContent, .MuiDataGrid-cellCheckbox":
                {
                    span: {
                        display: "flex",
                        color: "#E4E6F2",
                        "&:hover": {
                        color: "#B6B9CE",
                        backgroundColor: "transparent",
                        },
                        "&.Mui-checked": {
                        color: "black",
                        backgroundColor: "transparent",
                        },
                    },
                },
            }}
          />
        </Box>
      </Box>

      {/* Add Super Admin Modal */}
      <OwnerAddSuperAdminModal
        user={user}
        userData={userData}
        openAddSuperAdminModal={openAddSuperAdminModal} 
        setOpenAddSuperAdminModal={setOpenAddSuperAdminModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
      />

      {/* Add Member Modal */}
      <OwnerAddMemberModal
        user={user}
        userData={userData}
        openAddMemberModal={openAddMemberModal} 
        setOpenAddMemberModal={setOpenAddMemberModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
      />

      <AddPrivateUserModal 
        user={user}
        userData={userData}
        openAddPrivateUserModal={openAddPrivateUserModal} 
        setOpenAddPrivateUserModal={setOpenAddPrivateUserModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
      />

      <CreateTeamModal 
        user={user}
        userData={userData}
        openCreateTeamModal={openCreateTeamModal}
        setOpenCreateTeamModal={setOpenCreateTeamModal}
        companyMembers={companyMembers}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        fetchCompanyData={fetchCompanyData}
      />

      <ActionOnUserModal
        user={user}
        userData={userData}
        openActionOnUserModal={openActionOnUserModal}
        setOpenActionOnUserModal={setOpenActionOnUserModal}
        companyMembers={companyMembers}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        fetchCompanyData={fetchCompanyData}
        loadingCompanyData={loading}
      />

        <DeleteMembersModal
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          handleDelete={handleDelete}
          selectedCount={selected.length}
          loading={loadingDelete}
        />
    </Box>
  );
};

export default Owner;

import {
  Check,
  Edit,
  TaskOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  Modal,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Select,
} from "@mui/material";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _CallDataGrid from "components/CallDataGrid";
import _ from "lodash";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import avatar from "assets/avatar.png";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  _ActionListFilterSelect,
  _ActionListFilterDateRange,
} from "components/ActionListFilter";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import PositiveActionButton from "components/PositiveActionButton";
import NegativeActionButton from "components/NegativeActionButton";
import ConfirmButton from "components/ConfirmButton";
import DeclineButton from "components/DeclineButton";
import { formatDistanceToNow } from 'date-fns';
import ClientLimitReached from "components/ClientLimitReachedModal";
import ClientHasInactiveCoachModal from "components/ClientHasInactiveCoach/ClientHasInactiveCoachModal";
import ClientAddActionButton from "components/Actions/ClientAddAction/ClientAddActionButton";
import ClientGoalCard from "components/ClientDashboardCards/ClientGoalCard";
import DuoIcon from '@mui/icons-material/Duo';
import ActionsMetrics from "components/ActionsPage/ActionsMetrics";
import FilterComponents from "components/ActionsPage/FilterComponents";
import ToDoActionToolBar from "components/ActionsPage/ToDoActionToolBar";
import CompletedActionToolBar from "components/ActionsPage/CompletedActionToolBar";
import axios from "axios";
import CheckboxIcon from "components/Icons/Checkbox.svg";
import EditActionIcon from "components/Icons/EditAction.svg";
import CompletedIcon from "components/Icons/Completed.svg";


const tabs = ["To-Do", "Completed"];


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{
        position: "relative",
      }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "24px",
    backgroundColor: '#F0F0F0',
    color: '#202226',
    maxWidth: 330,
    fontSize: "12px",
    borderRadius: "25px",
  },
}));

const Actions = ({ user, clientHasInactiveCoach, userData }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const dispatch = useDispatch();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [lastActionCount, setLastActionCount] = useState(0);
  const [lastFileName, setLastFileName] = useState("");
  const [completedActionsSum, setCompletedActionsSum] = useState(0);
  const [todoActionsSum, setTodoActionsSum] = useState(0);
  const [overdueActionsSum, setOverdueActionsSum] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedCompleted, setSelectedCompleted] = useState([]);
  const [selectedCompletedIds, setSelectedCompletedIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const [actionIds, setActionIds] = useState([]);
  const [openCompleteActionModal, setOpenCompleteActionModal] = useState(false);
  const [openUndoActionModal, setOpenUndoActionModal] = useState(false);
  const [openEditActionModal, setOpenEditActionModal] = useState(false);
  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false);
  const [openAddActionsToGoalModal, setOpenAddActionsToGoalModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDueDateSetModal, setOpenDueDateSetModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [filterClient, setFilterClient] = useState("none");
  const [filterClientItems, setFilterClientItems] = useState([{ "key": "Coachee", "value": "none" }]);
  const [filterCoach, setFilterCoach] = useState("none");
  const [filterCoachItems, setFilterCoachItems] = useState([{ "key": "Coach", "value": "none" }]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filteredActionData, setFilteredActionData] = useState([]);
  const [todoGridHeight, setTodoGridHeight] = useState(0);
  const [completedGridHeight, setCompletedGridHeight] = useState(0);
  const [updatedAction, setUpdatedAction] = useState({});
  const [initialAction, setInitialAction] = useState({});
  const [activeTab, setActiveTab] = useState("editText");
  const [goals, setGoals] = useState([]);
  const [selectedGoalForActions, setSelectedGoalForActions] = useState(null);
  const [loadingAssignActionsToGoal, setLoadingAssignActionsToGoal] = useState(false);

  const CustomIcon = (props) => (
    <KeyboardArrowDown {...props} style={{ color: 'black' }} />
  );



  const headerHeight = 56;
  const rowHeight = 52;
  const navigate = useNavigate();

  const handleCloseUndoAction = () => {
    setOpenUndoActionModal(false)
  }
  const handleCloseCompleteAction = () => {
    setOpenCompleteActionModal(false)
  }
  const handleCloseEditAction = () => {
    setOpenEditActionModal(false);
    setInitialAction({});
    setActiveTab("editText");
  }
  const handleCloseSetDueDate = () => {
    setOpenDueDateSetModal(false)
  }
  const handleCloseDeleteAction = () => {
    setOpenDeleteActionModal(false)
  }
  const handleCloseAddActionsToGoal = () => {
    setSelectedGoalForActions(null)
    setOpenAddActionsToGoalModal(false);
  }

  const newActions = useMemo(
    () => {
      const today = new Date();
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());
      const formattedToday = today.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const formattedStartOfWeek = startOfWeek.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const filteredActions = actions.filter((row) => row.status === "incomplete");
      const result = filteredActions.filter(
        (row) => {
          const callDate = new Date(row.date).toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          return callDate >= formattedStartOfWeek && callDate <= formattedToday;
        }
      );
      return result;
    }, [actions]
  );

  const overdueActions = useMemo(
    () => {
      const today = new Date();
      const formattedToday = today.toLocaleDateString("en-us");
      const filteredActions = actions.filter((row) => row.status === "incomplete");
      const result = filteredActions.filter((row) => {
        const dueDate = new Date(row.due_date).toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return dueDate <= formattedToday;
      });
      return result;
    }, [actions]
  );

  const todoActions = useMemo(
    () => {
      const today = new Date();
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());
      const formattedToday = today.toLocaleDateString("en-us");
      const formattedStartOfWeek = startOfWeek.toLocaleDateString("en-us");
      const filteredActions = actions
        .filter((row) => row.status === "incomplete")
        .filter((row) => {
          const callDate = new Date(row.date).toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          return callDate < formattedStartOfWeek;
        })
        .filter((row) => {
          const dueDate = new Date(row.due_date).toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          return dueDate > formattedToday
        });
      return filteredActions;
    }, [actions]
  );

  const incompleteActions = useMemo(() => {
    const data = filteredActionData.filter((row) => row.status === "incomplete");
    const todoHeight = data.length > 25 ? headerHeight + 25 * rowHeight : headerHeight + data.length * rowHeight;
    setTodoGridHeight(todoHeight);
    return data;
  }, [filteredActionData]);

  const completedActions = useMemo(() => {
    const data = filteredActionData.filter((row) => row.status === "complete");
    const completedHeight = data.length > 25 ? headerHeight + 25 * rowHeight : headerHeight + data.length * rowHeight;
    setCompletedGridHeight(completedHeight);
    return data;
  }, [filteredActionData]);


  const editActionModal = () => {
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
    let originalAction = "";
    let sortedEdits = [];
    if (initialAction && initialAction.edits && initialAction.edits[0]) {
      sortedEdits = initialAction.edits
        ? [...initialAction.edits].sort((a, b) => new Date(a.edit_timestamp) - new Date(b.edit_timestamp))
        : [];
      originalAction = sortedEdits[0].previous_value;
    }
    return (
      <Modal
        open={openEditActionModal}
        onClose={handleCloseEditAction}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                lg: "32px",
              }
            }}>
            <Box display="flex" gap="10px">
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom: activeTab === "editText" ? "1px solid black" : "none",
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer"
                  },
                }}
                onClick={() => handleTabChange("editText")}
              >
                Edit Action
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom: activeTab === "editHistory" ? "1px solid black" : "none",
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer"
                  },
                }}
                onClick={() => handleTabChange("editHistory")}
              >
                View History
              </Typography>
            </Box>
            {activeTab === "editHistory" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  height: "220px",
                  overflowY: "auto"
                }}
              >
                {sortedEdits &&
                  sortedEdits
                    .slice()
                    .reverse()
                    .map((edit, index) => (
                      <Box key={index} sx={{ gap: "2px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "black"
                          }}
                        >
                          {`${edit.new_value}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#2D2D2D",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginBottom: "6px"
                          }}
                        >
                          edited by {
                            user?.sub === edit.edited_by_userid
                              ? "me"
                              : user?.["custom:is_coachee"] === "true"
                                ? edit.coach_name ?? "Coach"
                                : (edit.client_name ?? edit.participant_name) || "Client"
                          } {formatDistanceToNow(new Date(edit.edit_timestamp))} ago
                        </Typography>
                        <Divider color="#d3d3d3" />
                      </Box>
                    ))}
                {initialAction.edits && originalAction && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      {originalAction}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2D2D",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      orginal action
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {activeTab === "editText" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "150%",
                      letterSpacing: "2px",
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold"
                    }}
                  >
                    Action
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    autoFocus
                    multiline
                    rows={4}
                    value={updatedAction.action}
                    InputProps={{
                      inputProps: {
                        style: {
                          color: "black",
                        },
                      },
                      style: {
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        backgroundColor: "transparent",
                      },
                    }}
                    onChange={(event) => {
                      setUpdatedAction((prev) => ({
                        ...prev,
                        action: event.target.value,
                      }))
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" gap="15px">
                  <NegativeActionButton
                    onClick={handleCloseEditAction}
                    label={"Cancel"}
                  />
                  <PositiveActionButton
                    onClick={saveEditAction}
                    label={"Save Action"}
                    disabled={updatedAction.action === initialAction.action}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  const addActionsToGoalModal = () => {
    return (
      <Modal open={openAddActionsToGoalModal} onClose={handleCloseAddActionsToGoal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography sx={{
              color: "black",
              fontWeight: 500,
              fontFamily: "Lexend",
              fontSize: { xs: "24px", md: "36px" }
            }}>
              {`Link Action${selected.length > 1 ? 's' : ''} to Goal`}
            </Typography>
          </Box>
          <Box>
            <Typography color="#828282" fontWeight={500} fontFamily={"Lexend"}>
              {selected.length > 0 ? (`Select a goal for ${selected.length} action${selected.length > 1 ? 's' : ''}`)
                : selectedCompleted.length > 0 ? `Select a goal for ${selectedCompleted.length} action${selectedCompleted.length > 1 ? 's' : ''}`
                  : "No actions selected"
              }
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%"
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontSize: "12px",
                lineHeight: "1.5",
                letterSpacing: "2px",
                fontWeight: "bold",
                color: "black",
                textTransform: "uppercase"
              }}
            >
              Select Goal
            </Typography>
            <Select
              fullWidth
              required
              value={selectedGoalForActions?.goal_id || ""}
              onChange={(event) => {
                const selectedGoal = goals.find(goal => goal.goal_id === event.target.value);
                setSelectedGoalForActions(selectedGoal || null);
              }}
              IconComponent={CustomIcon}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: 'white',
                    maxHeight: 300, // Adjust this value as needed
                    overflow: 'auto',
                    maxWidth: '20%',
                    width: '20%',
                  },
                },
              }}
              sx={{
                color: "black",
                background: "white",
                borderRadius: "16px",
                border: "1px solid #C4CAD4",
                "&:before, &:after": {
                  display: "none",
                },
                "& fieldset": {
                  border: "none !important",
                },
              }}
            >
              {goals.length > 0 && goals.map((goal, index) => (
                <MenuItem key={goal.goal_id} value={goal.goal_id}
                  sx={{
                    background: "white",
                    color: "black",
                    "&:hover": {
                      background: "#F7F7F7",
                      cursor: "pointer"
                    },
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%"
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "12px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word"
                      }}
                    >
                      {goal.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#202226",
                        fontSize: "10px",
                        paddingLeft: "10px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word"
                      }}
                    >
                      {goal.description.length > 100 ? `${goal.description.substring(0, 100)}...` : goal.description}
                    </Typography>
                  </Box>

                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" }
            }}
          >
            <DeclineButton
              onClick={handleCloseAddActionsToGoal}
              label={"Cancel"}
            />
            <ConfirmButton
              onClick={addActionsToGoal}
              disabled={(selected.length == 0 && selectedCompleted.length == 0) || !selectedGoalForActions || loadingAssignActionsToGoal}
              label={"Confirm"}
            />
          </Box>
        </Box>
      </Modal>
    )
  };

  const deleteActionModal = () => {
    return (
      <Modal open={openDeleteActionModal} onClose={handleCloseDeleteAction}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to delete selected actions?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" }
            }}
          >
            <DeclineButton
              onClick={handleCloseDeleteAction}
              label={"No"}
            />
            <ConfirmButton
              onClick={deleteActions}
              label={"Yes"}
            />
          </Box>
        </Box>
      </Modal>
    );
  }

  const completeActionModal = () => {
    return (
      <Modal
        open={openCompleteActionModal}
        onClose={() => setOpenCompleteActionModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to mark selected actions as completed?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" }
            }}
          >
            <DeclineButton
              onClick={handleCloseCompleteAction}
              label={"No"}
            />
            <ConfirmButton
              onClick={completeActions}
              label={"Yes"}
            />
          </Box>
        </Box>
      </Modal>
    );
  };

  const undoActionModal = () => {
    return (
      <Modal
        open={openUndoActionModal}
        onClose={() => setOpenUndoActionModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to mark selected actions as to-do?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" }
            }}
          >
            <DeclineButton
              onClick={handleCloseUndoAction}
              label={"No"}
            />
            <ConfirmButton
              onClick={undoActions}
              label={"Yes"}
            />
          </Box>
        </Box>
      </Modal>
    );
  };

  const setDueDate = useCallback(async (combinedIds) => {
    try {
      setOpenDueDateSetModal(false);
      await Promise.all(
        combinedIds.map(async (row) => {
          const { message } = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action-due-date`, {
            "action_id": row.action_id,
            "due_date": selectedDate,
          });

          if (message === "Updated successfully") {
            toast(`Updated due date successfully`, {
              hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
              icon: () => <Check color="success" />,
            });
            setActions((prev) =>
              prev.map((prevRow) =>
                prevRow.action_id === row.action_id
                  ? { ...prevRow, due_date: selectedDate }
                  : prevRow
              )
            );
          }
        })
      );
    } catch (error) {
      console.error("Error setting due date:", error);
    } finally {
      // Clear the states using functional updates
      setSelected((prev) => []);
      setSelectedIds((prev) => []);
      setSelectedCompleted((prev) => []);
      setSelectedCompletedIds((prev) => []);
      await fetchUserData();
    }
  }, [selectedDate, setActions, setOpenDueDateSetModal]);


  const dueDateSetModal = () => {
    const handleSetDueDate = async () => {
      await setDueDate([...selectedIds, ...selectedCompletedIds]);
      // Clear the selections after setting the due date
      setSelected([]);
      setSelectedIds([]);
      setSelectedCompleted([]);
      setSelectedCompletedIds([]);
    };

    return (
      <Modal open={openDueDateSetModal} onClose={() => setOpenDueDateSetModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to set due date to ${selectedDate}?`}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" }
            }}
          >
            <DeclineButton
              onClick={handleCloseSetDueDate}
              label={"No"}
            />
            <ConfirmButton
              onClick={handleSetDueDate}
              label={"Yes"}
            />
          </Box>
        </Box>
      </Modal>
    );
  }



  const handleResetFilters = () => {
    setFilterClient("none");
    setFilterStartDate("");
    setFilterEndDate("");
  }

  useEffect(() => {
    let filteredData = actions;

    if (filterClient !== "none") {
      filteredData = filteredData.filter((row) => row.client_name === filterClient);
    }

    if (filterCoach !== "none") {
      filteredData = filteredData.filter((row) => row.coach_name === filterCoach)
    }

    if (filterStartDate !== "") {
      filteredData = filteredData.filter((row) => new Date(row.date).toISOString().split('T')[0] >= filterStartDate);
    }

    if (filterEndDate !== "") {
      filteredData = filteredData.filter((row) => new Date(row.due_date).toISOString().split('T')[0] <= filterEndDate);
    }

    setFilteredActionData(filteredData);
  }, [actions, filterClient, filterCoach, filterStartDate, filterEndDate]);


  const completeActions = async () => {
    const completed = "incomplete";
    selectedIds.map((row) => {
      handleCheckboxChange(row.action_id, completed);
    });
    setOpenCompleteActionModal(false);
    fetchActionsCount();
    setSelectedIds([]);
    setSelected([]);
  };

  const undoActions = async () => {
    const completed = "complete";

    selectedCompletedIds.map((row) => {
      handleCheckboxChange(row.action_id, completed);
    });

    // Close the undo action modal
    setOpenUndoActionModal(false);

    // Fetch updated action counts or any other necessary updates
    fetchActionsCount();

    setSelectedCompletedIds([]);
    setSelectedCompleted([]);
  };

  const deleteActions = async () => {
    try {
      setOpenDeleteActionModal(false);
      const selectedIdsToDelete = [...selectedIds, ...selectedCompletedIds]; // Combine selectedIds and selectedCompletedIds
      await Promise.all(
        selectedIdsToDelete.map(async (row) => {
          const { status } = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-action`, {
            "action_id": row.action_id,
          });

          if (status === "success") {
            toast(`Deleted action successfully`, {
              hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
              icon: () => <Check color="success" />,
            });
            setActions((prev) =>
              prev.filter((prevRow) => prevRow.action_id !== row.action_id)
            );
          }
        })
      );
      setSelectedIds([]);
      setSelected([]);
      setSelectedCompleted([]);
      setSelectedCompletedIds([]);
    } catch (error) {
      console.error("Error updating action:", error);
    }
  };

  const saveEditAction = async () => {
    try {
      setOpenEditActionModal(false);
      const { status } = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action`, {
        action_id: updatedAction.action_id,
        action: updatedAction.action,
        initial_action: initialAction.action,
        edited_by_userID: user?.sub
      });
      if (status === "success") {
        toast(`Updated action successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        setActions((prev) =>
          prev.map((prevRow) =>
            prevRow.action_id === updatedAction.action_id
              ? { ...prevRow, action: updatedAction.action }
              : prevRow
          )
        );
        await fetchUserData();
        await fetchGoalData();
        setUpdatedAction("");
        setSelectedIds([]);
        setSelected([]);
        setSelectedCompletedIds([]);
        setSelectedCompleted([]);
      }
    } catch (error) {
      console.error("Error updating action:", error);
    }
  };



  const fetchUserData = useCallback(async () => {
    try {
        setLoading(true);
        const userID = user?.sub;
        let response = [];

        response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-actions-by-user-id`, {
          params: { user_id: userID }
        });

        response = response?.data?.actions;

        console.log("data: ", response);

        if (!response || response.length === 0) {
            console.warn("No actions found.");
            setLoading(false);
            return;
        }

        let items = [];
        let clientNames = [];
        let calldata = [];

        // Process and filter the response data
        response.forEach((item) => {
            if (!clientNames.includes(item.client_name)) {
                clientNames.push(item.client_name);
                calldata.push(item);
            }

            items.push({
                id: _.uniqueId("actions page"),
                action_id: item.action_id,
                call_id: item.call_id,
                coach_name: item.coach_name,
                client_id: item.client_id,
                coach_id: item.coach_id,
                action: item.action,
                title: item.title,
                date: item.call_date,
                due_date: item.due_date,
                status: item.status,
                file_name: item.file_name,
                participant_name: item.participant_name,
                client_name: item.client_name,
                client_avatar: item.client_avatar ? `https://d7joen7tb0xwx.cloudfront.net/public/${item.client_avatar}` : null,
                coach_avatar: item.coach_avatar ? `https://d7joen7tb0xwx.cloudfront.net/public/${item.coach_avatar}` : null,
                edits: item.edits
            });
        });

        // Sort the items by date in descending order
        items.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Extract unique client and coach names for filtering
        const clientItems = response.length === 0 ? [] : response.map(row => row.client_name);
        const uniqueClientNames = [...new Set(clientItems)];
        setFilterClientItems([{ key: "Coachee", value: "none" }, ...uniqueClientNames.map(row => ({ key: row, value: row }))]);

        const coachItems = response.length === 0 ? [] : response.map(row => row.coach_name);
        const uniqueCoachNames = [...new Set(coachItems)];
        setFilterCoachItems([{ key: "Coach", value: "none" }, ...uniqueCoachNames.map(row => ({ key: row, value: row }))]);

        console.log("call data in actions page: ", calldata);
        console.log("items in actions page: ", items);

        setClientList(calldata);
        setActions(items);
        setFilteredActionData(items);

    } catch (error) {
        console.error("Error fetching data:", error);
    } finally {
        setLoading(false);
    }
}, [user, userData]);




  const fetchGoalData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-goals`, {
        params: {
          client_id: user?.sub,
        },
      });
      if (!response.data.goals_data) {
        return;
      }
      console.log("response for client goals: ", response)

      const data = response.data.goals_data;
      setGoals(data);

    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  }, [user, dispatch]);

  const addActionsToGoal = useCallback(async () => {
    try {
      setLoadingAssignActionsToGoal(true);

      const selectedToAssignToGoal = [...selected, ...selectedCompleted]; // Combine selectedIds and selectedCompletedIds

      let successCount = 0;
      let duplicateCount = 0;

      await Promise.all(
        selectedToAssignToGoal.map(async (row) => {
          const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/assign-actions-to-goal`, {
            action_id: row.action_id,
            goal_id: selectedGoalForActions.goal_id,
          });

          if (response.status === "success") {
            successCount++;
          } else if (response.status === "duplicate") {
            duplicateCount++;
          }
        })
      );

      if (successCount > 0) {
        toast(`Actions assigned to goal successfully: ${successCount}${duplicateCount > 0 ? `, Actions already assigned to the goal: ${duplicateCount}` : ''}`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
      } else {
        toast.error(`The selected action(s) are already assigned to the goal`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
        });
      }

      // Fetch the updated goals after actions are assigned
      await fetchGoalData();
    } catch (error) {
      toast.error("Error assigning actions to goal");
      console.log("error", error);
    } finally {
      handleCloseAddActionsToGoal();
      setLoadingAssignActionsToGoal(false);
    }
  }, [selected, selectedCompleted, selectedGoalForActions, fetchGoalData]);


  const handleCheckboxChange = useCallback(
    async (action_id, completed) => {
      const body = { action_id, completed };
      const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/setActionStatus`, body);

      if (response.status === "success") {
        toast(`Action marked as ${completed === "incomplete" ? "completed" : "to-do"}`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });

        setActions((prev) =>
          prev.map((row) =>
            row.action_id === action_id
              ? { ...row, status: completed === "incomplete" ? "complete" : "incomplete" }
              : row
          )
        );
        await fetchGoalData();
      }
    },
    [setActions]
  );


  const handleMenuOpen = (field, event) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [field]: event.currentTarget,
    }));
  };

  const handleMenuClose = (field) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [field]: null,
    }));
  };

  const handleMenuItemClick = (item, direction) => {
    if (!Array.isArray(actions)) {
      console.error('actions is not an array:', actions);
      return;
    }
    const currentActionData = [...actions];

    let sortedData;
    if (item === "date") {
      sortedData = currentActionData.sort((a, b) => {
        if (direction === "asc") {
          return new Date(a.date) - new Date(b.date);
        }
        return new Date(b.date) - new Date(a.date);
      });
    } else if (item === "due_date") {
      sortedData = currentActionData.sort((a, b) => {
        if (direction === "asc") {
          return new Date(a.due_date) - new Date(b.due_date);
        }
        return new Date(b.due_date) - new Date(a.due_date);
      });
    } else if (item === "client") {
      sortedData = currentActionData.sort((a, b) => {
        if (a.client_name === null) return 1;
        if (b.client_name === null) return -1;

        if (direction === "asc") {
          return a.client_name.localeCompare(b.client_name);
        }
        return b.client_name.localeCompare(a.client_name);
      });
    } else if (item === "coach") {
      sortedData = currentActionData.sort((a, b) => {
        if (a.coach_name === null) return 1;
        if (b.coach_name === null) return -1;

        if (direction === "asc") {
          return a.client_name.localeCompare(b.coach_name);
        }
        return b.client_name.localeCompare(a.coach_name);
      });
    } else {
      sortedData = currentActionData.sort((a, b) => {
        if (direction === "asc") {
          return a[item].localeCompare(b[item]);
        }
        return b[item].localeCompare(a[item]);
      });
    }
    setActions(sortedData);
    handleMenuClose(item);
  };

  const handleHeaderCellRender = (params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>{params.colDef.headerName}</div>
        <IconButton
          aria-label="more"
          aria-controls="header-menu"
          aria-haspopup="true"
          onClick={(event) => handleMenuOpen(params.field, event)}
        >
          <KeyboardArrowDown
            sx={{
              fill: "#93989A"
            }}
          />
        </IconButton>
        <Menu
          id={`header-menu-${params.field}`}
          anchorEl={anchorEl[params.field]}
          open={Boolean(anchorEl[params.field])}
          onClose={() => handleMenuClose(params.field)}
          sx={{
            "& .MuiPaper-root": {
              background: "white",
              color: "#202226",
              borderRadius: "10px",
              "& .MuiList-root .MuiMenuItem-root": {
                fontFamily: "Lexend",
                fontSize: "12px",
                lineHeight: "20px",
                fontWeight: "500",
                color: "#202226",
                "&:hover": {
                  background: "#F5F5F5",
                }
              }
            }
          }}
        >
          {params.field === "due_date" || params.field === "date" ? (
            <Box>
              <MenuItem onClick={() => handleMenuItemClick(params.field, "desc")}>
                Newest
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(params.field, "asc")}>
                Oldest
              </MenuItem>
            </Box>
          ) : (
            <Box>
              <MenuItem onClick={() => handleMenuItemClick(params.field, "asc")}>
                (A-Z)
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(params.field, "desc")}>
                (Z-A)
              </MenuItem>
            </Box>
          )}

        </Menu>
      </div>
    );
  };

  const toDoColumns = [
    {
      field: "title",
      headerName: "Action Item",
      flex: 0.2,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      minWidth: 200,
      sortable: false,
      renderCell: ({ row: { title, action } }) => (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            whiteSpace: "pre-wrap",
            textOverflow: "break-word",
          }}
        >
          <Box
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
              whiteSpace: "pre-wrap",
              textOverflow: "break-word",
            }}
          >
            {action}
          </Box>
        </Box>
      ),
    },
    {
      field: "coach",
      headerName: "Coach",
      flex: 0.2,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      hide: (userData.isClient !== true),
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row: { coach_name, coach_avatar } }) => (
        coach_name !== null && coach_name !== "" ?
          (<Box
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
              gap: "12px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Box
              sx={{
                background: "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                padding: "2px",
                borderRadius: "50%",
                width: "28px",
                height: "28px",
              }}
            >
              <Box
                sx={{
                  background: "white",
                  padding: "1px",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                }}
              >
                <Avatar
                  sx={{
                    width: "22px",
                    height: "22px",
                  }}
                  src={coach_avatar ?? avatar}
                />
              </Box>
            </Box>
            {coach_name}
          </Box>)
          : ""
      ),
    },
    {
      field: "coachee",
      headerName: "Coachee",
      flex: 0.2,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      hide: userData.accessTo !== "platform",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row: { client_name, client_avatar, fetched_avatar } }) => (
        client_name !== null && client_name !== "" ?
          (<Box
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
              gap: "12px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Box
              sx={{
                background: "linear-gradient(300deg, #242F8C 0%, #03093A 40%, #D8659B 100%)",
                padding: "2px",
                borderRadius: "50%",
                width: "28px",
                height: "28px",
              }}
            >
              <Box
                sx={{
                  background: "white",
                  padding: "1px",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                }}
              >
                <Avatar
                  sx={{
                    width: "22px",
                    height: "22px",
                  }}
                  src={client_avatar ?? avatar}
                />
              </Box>
            </Box>
            {client_name}
          </Box>)
          : ""
      ),
    },
    {
      field: "date",
      headerName: "Date Agreed",
      flex: 0.2,
      minWidth: 100,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row: { date } }) => (
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            marginLeft: "4px"
          }}
        >
          {date !== null &&
            date !== "" &&
            dayjs(date).utc().format('MM/DD/YY')}
        </Box>
      ),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 0.2,
      minWidth: 100,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderHeader: (params) => handleHeaderCellRender(params),
      renderCell: ({ row: { due_date } }) => (
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
          }}
        >
          {due_date !== null &&
            due_date !== "" &&
            dayjs(due_date).utc().format('MM/DD/YY')}
        </Box>
      ),
    },
    {
      field: "call_link",
      headerName: "Session",
      flex: 0.1,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      sortable: false,
      renderCell: (params) => (
        <>
          {params.row.file_name !== "" && params.row.file_name !== null && (

            <DuoIcon
              sx={{
                color: "#007bff",
                "&:hover": {
                  color: "#0000FF",
                  boxShadow: "none",
                  outline: "none",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (params.row.file_name !== "" && params.row.file_name !== null)
                  if (user?.sub === params.row.client_id) {
                    navigate("/platform/coachee/session/" + params.row.file_name);
                  }
                  else {
                    navigate("/platform/coach/session/" + params.row.file_name);
                  }
              }}
            />

          )}
        </>
      ),
    },
  ];

  const fetchActionsCount = async () => {
    try {
      let response;
      
       response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-actions-sum-by-user-id`, {
        params: {
          user_id: user?.sub,
        },
      });
  
      response = response.data;
      console.log("action data: ", response);
  
      // Safely set values by checking response exists and contains the necessary properties
      setLastActionCount(response?.data?.last_action_count || 0);
      setLastFileName(response?.data?.last_file_name || "");
      setCompletedActionsSum(response?.data?.completed_actions_sum || 0);
      setTodoActionsSum(response?.data?.todo_actions_sum || 0);
      setOverdueActionsSum(response?.data?.overdue_actions_sum || 0);
    } catch (error) {
      console.error("Error fetching sum data:", error);
    }
  };  


  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    fetchGoalData();
  }, [fetchGoalData]);

  useEffect(() => {
    fetchActionsCount();
  }, [deleteActions, completeActions, undoActions, setDueDate, saveEditAction]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setSelected([]);
    setSelectedIds([]);
    setSelectedCompleted([]);
    setSelectedCompletedIds([]);
  };

  const tabProps = {
    iconPosition: "start",
    sx: {
      flexDirection: "row",
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },
  };

  return (
    <GlobalPageComponent user={user} accessTo={userData.accessTo}>
      <ClientLimitReached user={user} userData={userData} />
      <ClientHasInactiveCoachModal clientHasInactiveCoach={clientHasInactiveCoach} isLocked={true} />
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          padding: {
            xs: "40px 16px",
            lg: "32px 40px",
          },
          width: "100%",
          height: "100vh",
          overflowY: "auto",
          display: {
            xs: !collapsed ? "none" : "block",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: "36px",
              fontFamily: "Poppins",
              fontWeight: 300,
              paddingBottom: "24px",
            }}
          >
            Goals & Actions
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "8px",
            width: "100%",
            height: "calc(100% - 70px)"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: { xs: "100%", md: "calc((100% / 4) * 3)" },
              height: "100%"
            }}
          >
            <ActionsMetrics
              loading={loading}
              lastActionCount={lastActionCount}
              todoActionsSum={todoActionsSum}
              completedActionsSum={completedActionsSum}
              overdueActionsCount={overdueActionsSum}
            />
            <Box
              sx={{
                background: "white",
                borderRadius: "40px",
                padding: {
                  xs: "24px 30px",
                  lg: "24px",
                },
                display: "flex", // Added to make sure the child can expand
                flexDirection: "column", // Ensure the flex direction is column
                flexGrow: { md: 1 }
              }}
            >
              {openDatePicker && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "100px",
                      left: {
                        xs: "50%",
                        lg: "200px",
                      },
                      transform: {
                        xs: "translateX(-50%)",
                        lg: "unset",
                      },
                      zIndex: 100,
                      background: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDatePicker
                        defaultValue={dayjs(selectedDate)}
                        onChange={(newValue) => {
                          setSelectedDate(dayjs(newValue).format("YYYY-MM-DD"));
                          setOpenDatePicker(false);
                          setOpenDueDateSetModal(true);
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    sx={{
                      position: "fixed",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      right: 0,
                      botom: 0,
                      zIndex: 99,
                    }}
                    onClick={() => setOpenDatePicker(false)}
                  />
                </>
              )}
              <Box
                sx={{
                  color: "black",
                  margin: "0px 12px",
                  display: "flex",
                  justifyContent: { md: "space-between" },
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="client-coach-tabs"
                  TabIndicatorProps={{ style: { backgroundColor: "black" } }}
                  sx={{
                    overflow: "visible",
                    "& .MuiTab-textColorPrimary": {
                      color: "black",
                      fontFamily: "Lexend",
                    },
                    "& .MuiTab-root.MuiButtonBase-root": {
                      fontSize: "12px",
                      fontWeight: 700,
                      paddingBottom: "4px"
                    },
                    "& .MuiTabs-scroller": {
                      overflowX: {
                        xs: "auto !important",
                        lg: "visible !important",
                      },
                      overflowY: {
                        xs: "visible !important",
                      },
                    },
                    padding: "0px"
                  }}
                >
                  <Tab label={tabs[0]} {...tabProps} icon={<EditActionIcon />} />
                  <Tab {...tabProps} label={tabs[1]} icon={<CompletedIcon />} />
                </Tabs>
                <FilterComponents
                  user={user}
                  userData={userData}
                  filterClient={filterClient}
                  filterClientItems={filterClientItems}
                  setFilterClient={setFilterClient}
                  filterCoach={filterCoach}
                  filterCoachItems={filterCoachItems}
                  setFilterCoach={setFilterCoach}
                  filterStartDate={filterStartDate}
                  setFilterStartDate={setFilterStartDate}
                  filterEndDate={filterEndDate}
                  setFilterEndDate={setFilterEndDate}
                  handleResetFilters={handleResetFilters}
                />
              </Box>

              <CustomTabPanel value={tabValue} index={0}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    paddingTop: "24px",
                    width: "100%",
                    maxHeight: { xs: "300px", md: "20%" },
                    overflow: "auto"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <ToDoActionToolBar
                      user={user}
                      selected={selected}
                      selectedIds={selectedIds}
                      setUpdatedAction={setUpdatedAction}
                      setInitialAction={setInitialAction}
                      setOpenEditActionModal={setOpenEditActionModal}
                      setOpenAddActionsToGoalModal={setOpenAddActionsToGoalModal}
                      setOpenCompleteActionModal={setOpenCompleteActionModal}
                      setOpenDatePicker={setOpenDatePicker}
                      setOpenDeleteActionModal={setOpenDeleteActionModal}
                    />
                    {userData?.companyInformation && (
                      <ClientAddActionButton
                        user={user}
                        userData={userData}
                        loading={loading}
                        setLoading={setLoading}
                        fetchUserData={fetchUserData}
                      />
                    )}
                  </Box>

                  <_CallDataGrid
                    height={`${todoGridHeight + 52 > 200 ? todoGridHeight + 75 : 200}px`}
                    rows={incompleteActions}
                    loading={loading}
                    columns={toDoColumns}
                    localeText={incompleteActions?.length ? "" : "No actions yet."}
                    checkboxSelection={true}
                    onRowClick={(row, event) => {
                      event.stopPropagation();
                      setModalContent(row.row.action);
                      setOpenDetailModal(true);
                    }}
                    onSelectionModelChange={(ids) => {
                      const data = [];
                      const selectedRow = [];
                      incompleteActions.filter((row) => {
                        if (ids.includes(row.id)) {
                          data.push(row);
                          selectedRow.push(row);
                        }
                      });

                      setSelectedIds(data);
                      setSelected(selectedRow);
                      setActionIds(ids);
                    }}
                    sx={{
                      height: "100%", // Ensure the grid takes the full height of the container
                      '& .MuiDataGrid-footerContainer': {
                        position: 'sticky', // Keep the footer fixed
                        bottom: 0,
                        zIndex: 1,
                      },
                    }}
                  />
                  <Box
                    display={{
                      xs: "block",
                      md: "none",
                    }}
                  >
                    {todoActions.length === 0 && newActions.length === 0 && overdueActions.length === 0 && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "black",
                        }}
                      >
                        No actions yet.
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                      }}
                    >
                      {newActions.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                            overflow: "auto",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#535353",
                              lineHeight: "1.5",
                              letterSpacing: "2px",
                              textTransform: "uppercase"
                            }}
                          >
                            New actions
                          </Typography>
                          {newActions.map((row) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Checkbox
                                  sx={{
                                    color: "#E4E6F2",
                                    padding: 0,
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                  }}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    if (e.target.checked) {
                                      setSelected([...selected, row]);
                                      setSelectedIds([...selectedIds, row]);
                                    } else {
                                      setSelected(
                                        selected.filter(
                                          (action) =>
                                            action.action_id !== row.action_id
                                        )
                                      );
                                      setSelectedIds(
                                        selectedIds.filter(
                                          (action) =>
                                            action.action_id !== row.action_id
                                        )
                                      );
                                    }
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <ArrowRightIcon
                                  sx={{
                                    fill: "black"
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "10px",
                                    fontWeight: 700,
                                    lineHeight: "1.5",
                                    color: "black",
                                    letterSpacing: "2px",
                                  }}
                                >
                                  Due {row.due_date === null || row.due_date === "" ?
                                    ""
                                    :
                                    new Date(row.due_date)
                                      .toLocaleDateString('en-US', {
                                        month: "2-digit",
                                        day: '2-digit',
                                        year: '2-digit',
                                      })}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    color: "#202628",
                                  }}
                                >
                                  {row.action}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "#202628",
                                  }}
                                >
                                  Agreed on {new Date(row.date).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "2-digit",
                                  })}
                                </Typography>
                              </Box>
                              <Button
                                sx={{
                                  padding: "10px 0",
                                  width: "100%",
                                  border: "2px solid #4E9F5B",
                                  borderRadius: "6px",
                                  fontFamily: "Lexend",
                                  fontWeight: "700",
                                  lineHeight: "1.5",
                                  letterSpacing: "2px",
                                  color: "#79AC75",
                                  textTransform: "uppercase"
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelected([row]);
                                  setSelectedIds([row]);
                                  setOpenCompleteActionModal(true);
                                }}
                              >
                                Mark as completed
                              </Button>
                              <Button
                                sx={{
                                  padding: "10px 0",
                                  width: "100%",
                                  border: "2px solid #6932DE",
                                  borderRadius: "6px",
                                  fontFamily: "Lexend",
                                  fontWeight: "700",
                                  lineHeight: "1.5",
                                  letterSpacing: "2px",
                                  color: "#9F8CAE",
                                  textTransform: "uppercase"
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (row.file_name !== "" && row.file_name !== null)
                                    navigate("/session/" + row.file_name);
                                }}
                              >
                                Recap from session
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      )}
                      {(todoActions.length > 0 || overdueActions.length > 0) && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#535353",
                              lineHeight: "1.5",
                              letterSpacing: "2px",
                              textTransform: "uppercase",
                            }}
                          >
                            All actions
                          </Typography>
                          {overdueActions.length > 0 && overdueActions.map((row) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Checkbox
                                  sx={{
                                    color: "#E4E6F2",
                                    padding: 0,
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                  }}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    if (e.target.checked) {
                                      setSelected([...selected, row]);
                                      setSelectedIds([...selectedIds, row]);
                                    } else {
                                      setSelected(
                                        selected.filter(
                                          (action) =>
                                            action.action_id !== row.action_id
                                        )
                                      );
                                      setSelectedIds(
                                        selectedIds.filter(
                                          (action) =>
                                            action.action_id !== row.action_id
                                        )
                                      );
                                    }
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <ArrowRightIcon
                                  sx={{
                                    fill: "#EB7575"
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "10px",
                                    fontWeight: 700,
                                    lineHeight: "1.5",
                                    color: "#EB7575",
                                    letterSpacing: "2px",
                                  }}
                                >
                                  Due {row.due_date === null || row.due_date === "" ?
                                    ""
                                    :
                                    new Date(row.due_date)
                                      .toLocaleDateString('en-US', {
                                        month: "2-digit",
                                        day: '2-digit',
                                        year: '2-digit',
                                      })}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    color: "#202628",
                                  }}
                                >
                                  {row.action}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "#202628",
                                  }}
                                >
                                  Agreed on {new Date(row.date).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "2-digit",
                                  })}
                                </Typography>
                              </Box>
                              <Button
                                sx={{
                                  padding: "10px 0",
                                  width: "100%",
                                  border: "2px solid #4E9F5B",
                                  borderRadius: "6px",
                                  fontFamily: "Lexend",
                                  fontWeight: "700",
                                  lineHeight: "1.5",
                                  letterSpacing: "2px",
                                  color: "#79AC75",
                                  textTransform: "uppercase"
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelected([row]);
                                  setSelectedIds([row]);
                                  setOpenCompleteActionModal(true);
                                }}
                              >
                                Mark as complete
                              </Button>
                              <Button
                                sx={{
                                  padding: "10px 0",
                                  width: "100%",
                                  border: "2px solid #6932DE",
                                  borderRadius: "6px",
                                  fontFamily: "Lexend",
                                  fontWeight: "700",
                                  lineHeight: "1.5",
                                  letterSpacing: "2px",
                                  color: "#9F8CAE",
                                  textTransform: "uppercase"
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (row.file_name !== "" && row.file_name !== null)
                                    navigate("/session/" + row.file_name);
                                }}
                              >
                                Recap from session
                              </Button>
                            </Box>
                          ))}
                          {todoActions.length > 0 && todoActions.map((row) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Checkbox
                                  sx={{
                                    color: "#E4E6F2",
                                    padding: 0,
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                  }}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    if (e.target.checked) {
                                      setSelected([...selected, row]);
                                      setSelectedIds([...selectedIds, row]);
                                    } else {
                                      setSelected(
                                        selected.filter(
                                          (action) =>
                                            action.action_id !== row.action_id
                                        )
                                      );
                                      setSelectedIds(
                                        selectedIds.filter(
                                          (action) =>
                                            action.action_id !== row.action_id
                                        )
                                      );
                                    }
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <ArrowRightIcon
                                  sx={{
                                    fill: "black"
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "10px",
                                    fontWeight: 700,
                                    lineHeight: "1.5",
                                    color: "black",
                                    letterSpacing: "2px",
                                  }}
                                >
                                  Due {row.due_date === null || row.due_date === "" ?
                                    ""
                                    :
                                    new Date(row.due_date)
                                      .toLocaleDateString('en-US', {
                                        month: "2-digit",
                                        day: '2-digit',
                                        year: '2-digit',
                                      })}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    color: "#202628",
                                  }}
                                >
                                  {row.action}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "#202628",
                                  }}
                                >
                                  Agreed on {new Date(row.date).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "2-digit",
                                  })}
                                </Typography>
                              </Box>
                              <Button
                                sx={{
                                  padding: "10px 0",
                                  width: "100%",
                                  border: "2px solid #4E9F5B",
                                  borderRadius: "6px",
                                  fontFamily: "Lexend",
                                  fontWeight: "700",
                                  lineHeight: "1.5",
                                  letterSpacing: "2px",
                                  color: "#79AC75",
                                  textTransform: "uppercase"
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelected([row]);
                                  setSelectedIds([row]);
                                  setOpenCompleteActionModal(true);
                                }}
                              >
                                Mark as complete
                              </Button>
                              <Button
                                sx={{
                                  padding: "10px 0",
                                  width: "100%",
                                  border: "2px solid #6932DE",
                                  borderRadius: "6px",
                                  fontFamily: "Lexend",
                                  fontWeight: "700",
                                  lineHeight: "1.5",
                                  letterSpacing: "2px",
                                  color: "#9F8CAE",
                                  textTransform: "uppercase"
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (row.file_name !== "" && row.file_name !== null)
                                    navigate("/session/" + row.file_name);
                                }}
                              >
                                Recap from session
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    paddingTop: "24px",
                    width: "100%",
                    maxHeight: { xs: "300px", md: "20%" },
                    overflow: "auto"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <CompletedActionToolBar
                      user={user}
                      selectedCompleted={selectedCompleted}
                      selectedCompletedIds={selectedCompletedIds}
                      setUpdatedAction={setUpdatedAction}
                      setInitialAction={setInitialAction}
                      setOpenEditActionModal={setOpenEditActionModal}
                      setOpenAddActionsToGoalModal={setOpenAddActionsToGoalModal}
                      setOpenUndoActionModal={setOpenUndoActionModal}
                      setOpenDatePicker={setOpenDatePicker}
                      setOpenDeleteActionModal={setOpenDeleteActionModal}
                    />
                    {userData?.companyInformation && (
                      <Box>
                        <ClientAddActionButton
                          user={user}
                          loading={loading}
                          setLoading={setLoading}
                          fetchUserData={fetchUserData}
                        />
                      </Box>
                    )}
                  </Box>
                  <_CallDataGrid
                    height={`${completedGridHeight + 52 > 200 ? completedGridHeight + 75 : 200}px`}
                    maxHeight="628px"
                    rows={completedActions}
                    loading={loading}
                    columns={toDoColumns}
                    checkboxSelection={true}
                    onRowClick={(row, event) => {
                      event.stopPropagation();
                      setModalContent(row.row.action);
                      setOpenDetailModal(true);
                    }}
                    onSelectionModelChange={(ids) => {
                      const data = [];
                      const selectedRow = [];
                      completedActions.filter((row) => {
                        if (ids.includes(row.id)) {
                          data.push(row);
                          selectedRow.push(row);
                        }
                      });
                      setSelectedCompletedIds(data);
                      setSelectedCompleted(selectedRow);
                      setActionIds(ids);
                    }}
                    localeText={completedActions?.length ? "" : "No completed actions yet."}
                  />
                </Box>
                <Box
                  display={{
                    xs: "block",
                    md: "none",
                  }}
                >
                  <Box
                    display={{
                      xs: "block",
                      md: "none",
                    }}
                  >
                    {completedActions.length === 0 && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "black",
                        }}
                      >
                        No actions yet.
                      </Typography>
                    )}
                    {completedActions.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                          paddingTop: "24px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#535353",
                            lineHeight: "1.5",
                            letterSpacing: "2px",
                            textTransform: "uppercase"
                          }}
                        >
                          Completed actions
                        </Typography>
                        {newActions.map((row) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <ArrowRightIcon
                                sx={{
                                  fill: "#499A0A"
                                }}
                              />
                              <Typography
                                sx={{
                                  fontFamily: "Lexend",
                                  fontSize: "10px",
                                  fontWeight: 700,
                                  lineHeight: "1.5",
                                  color: "#499A0A",
                                  letterSpacing: "2px",
                                }}
                              >
                                Due {row.due_date === null || row.due_date === "" ?
                                  ""
                                  :
                                  new Date(row.due_date)
                                    .toLocaleDateString('en-US', {
                                      month: "2-digit",
                                      day: '2-digit',
                                      year: '2-digit',
                                    })}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Lexend",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  color: "#202628",
                                }}
                              >
                                {row.action}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Lexend",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#202628",
                                }}
                              >
                                Agreed on {new Date(row.date).toLocaleDateString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "2-digit",
                                })}
                              </Typography>
                            </Box>
                            <Button
                              sx={{
                                padding: "10px 0",
                                width: "100%",
                                border: "2px solid #4E9F5B",
                                borderRadius: "6px",
                                fontFamily: "Lexend",
                                fontWeight: "700",
                                lineHeight: "1.5",
                                letterSpacing: "2px",
                                color: "#79AC75",
                                textTransform: "uppercase"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              Completed
                            </Button>
                            <Button
                              sx={{
                                padding: "10px 0",
                                width: "100%",
                                border: "2px solid #6932DE",
                                borderRadius: "6px",
                                fontFamily: "Lexend",
                                fontWeight: "700",
                                lineHeight: "1.5",
                                letterSpacing: "2px",
                                color: "#9F8CAE",
                                textTransform: "uppercase"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (row.file_name !== "" && row.file_name !== null)
                                  navigate("/session/" + row.file_name);
                              }}
                            >
                              Recap from session
                            </Button>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              </CustomTabPanel>
              <Modal
                open={openDetailModal}
                onClose={() => setOpenDetailModal(false)}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    background: "white",
                    border: "2px solid white",
                    boxShadow: 24,
                    padding: "20px 20px 10px",
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "20px",
                    margin: "0 auto",
                    maxWidth: "calc(100% - 40px)",
                  }}
                >
                  <Box>
                    <Typography color="black" fontWeight="400">
                      {modalContent}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-around">
                    <Button
                      sx={{
                        width: "150px",
                        borderRadius: "8px",
                        background: "transparent",
                        color: "#7D7D7D",
                        border: "1px solid #E4E6F1",
                        fontWeight: "bold",
                        "&:hover": {
                          borderColor: "#B52100",
                          color: "#C51919",
                        },
                      }}
                      onClick={() => setOpenDetailModal(false)}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
              {undoActionModal()}
              {completeActionModal()}
              {dueDateSetModal()}
              {editActionModal()}
              {deleteActionModal()}
              {addActionsToGoalModal()}
            </Box>
          </Box>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              background: "white",
              borderRadius: "40px",
              padding: "20px 8px",
              width: { xs: "100%", md: "calc((100% / 4))" },
            }}
          >
            <ClientGoalCard
              user={user}
              userData={userData}
              fetchClientGoalsData={fetchGoalData}
              goals={goals}
              setGoals={setGoals}
            />
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};

export default Actions;
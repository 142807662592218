import { Modal, Box, Typography, Button } from '@mui/material';
import ConfirmButton from '../../ConfirmButton';
import DeclineButton from '../../DeclineButton'

const DeleteMembersModal = ({ open, handleClose, handleDelete, selectedCount, loading }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-confirmation-modal"
      aria-describedby="modal-to-confirm-user-deletion"
    >
      <Box
        sx={{
            position: "absolute",
            backgroundColor: "white",
            color: "black",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxWidth: "calc(100% - 40px)",
            maxHeight: {
                xs: "calc(100% - 50px)",
                md: "80%",
            },
            overflowY: "hidden",
            boxShadow: 24,
            borderRadius: "30px",
            padding: {
                xs: "24px",
                md: "64px",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography sx={{ color: 'black', fontSize: 36, fontFamily: 'Lexend', fontWeight: '300' }}>
                Delete Members
            </Typography>
            <Typography sx={{ color: '#828282', fontSize: 16, fontFamily: 'Lexend', fontWeight: '500' }}>
                {`Are you sure you want to delete ${selectedCount} user(s)?`}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
                <DeclineButton 
                    label={"Cancel"}
                    onClick={handleClose}
                />
                <ConfirmButton 
                    label={"Delete"} 
                    loading={loading} 
                    onClick={handleDelete}
                />
            </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteMembersModal;

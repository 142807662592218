import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import UsersTable from "./Table/UsersTable";
import AdminAddMemberModal from "./AddMembers/AdminAddMemberModal";
import ToolBar from "./ToolBar/ToolBar";
import AddPrivateUserModal from "./AddMembers/AddPrivateUserModal";
import { generateClient } from "aws-amplify/data";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const Admin = ({ user, userData }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false); // For loading state
  const [companyMembers, setCompanyMembers] = useState([]); // Store company members here
  const [companyInformation, setCompanyInformation] = useState([]);
  const [teamsInformation, setTeamsInformation] = useState([]);
  const [myTeamsInformation, setMyTeamsInformation] = useState([]);
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false); // Modal state
  const [availableSeats, setAvailableSeats] = useState('');
  const [openAddPrivateUserModal, setOpenAddPrivateUserModal] = useState(false);


    // Function to fetch company members
  const fetchCompanyData = async() => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/admin-fetch-company-members`, {
        params: {
          userId: user?.sub,
          userGroup: user?.role,
        }
      });
      setCompanyMembers(response.members);
      setCompanyInformation(response.companyInformation);
      setTeamsInformation(response.teams);
      setMyTeamsInformation(response.myTeamsAdmin)

      const {data: responseSeats, errors} = await resourceClient.queries.checkCoacheeInviteAllowance({
        userID: response?.companyInformation?.owner_user_id
      })

      setAvailableSeats(responseSeats.coachee_seat_tier_allowance - responseSeats.coachee_seat_count)
      setLoading(false);
    } catch (error) {
      console.log("Error fetching company members: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const columns = [
    {
      field: 'full_name',
      headerName: 'Full Name',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: '#1B114A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '300', wordWrap: 'break-word' }}
        >
          {params.value}
        </Typography>
      ),
    },
    // {
    //   field: 'email',
    //   headerName: 'Email',
    //   width: 230,
    //   renderCell: (params) => {
    //     let displayedEmail = params.value;
    
    //     // Replace specific email addresses with predefined ones
    //     if (params.value === 'r@altercall.com') {
    //       displayedEmail = 'coach@example.com';
    //     } else if (params.value === 'd@pompaprogram.com') {
    //       displayedEmail = 'client@example.com';
    //     }
    
    //     return (
    //       <Typography
    //         sx={{
    //           color: '#93989A',
    //           fontSize: 14,
    //           fontFamily: 'Lexend',
    //           fontWeight: '400',
    //           wordWrap: 'break-word'
    //         }}
    //       >
    //         {displayedEmail}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: 'team_name',
      headerName: 'Team',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: 'black', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
        >
          {Array.isArray(params.value) ? params.value.join(', ') : params.value || 'No Team'}
        </Typography>
      ),
    },
    { 
      field: 'role',
      headerName: 'Role',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: '#93989A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
        >
          {params.value || 'No role'}
        </Typography>
      )
    },
    { 
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: '#93989A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
        >
          {params.value || ''}
        </Typography>
      )
    },
    {
      field: 'invite_status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ color: params.value === 'accepted' ? '#00F218' : 'orange', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'team_leader',
      headerName: 'Team Leader',
      flex: 1,
      renderCell: (params) => {
        // Ensure params.value is defined and is an array
        const teamLeaders = Array.isArray(params.value) ? params.value : [];
    
        // Join the team leader names with a comma, or use a placeholder text
        const leaderNames = teamLeaders.length > 0 ? teamLeaders.join(', ') : 'No Team Leader';
    
        return (
          <Typography
            sx={{
              color: '#1B114A',
              fontSize: 14,
              fontFamily: 'Lexend',
              fontWeight: '300',
              wordWrap: 'break-word',
            }}
          >
            {leaderNames}
          </Typography>
        );
      },
    }
  ];

  const rows = companyMembers.map((member, index) => {
    // Handle potential null/undefined for teams and map over it
    const teams = Array.isArray(member.teams) 
      ? member.teams.map(team => team.team_name || '').join(', ') 
      : 'No Team';  // Default to 'No Team' if teams array is not present or empty
  
    // Create a Set to keep track of unique admin IDs to avoid duplicates
    const uniqueAdminNames = new Set();
  
    // Iterate over each team to collect the admin names
    member.teams.forEach(team => {
      if (team.team_admins) {
        team.team_admins.forEach(admin => {
          uniqueAdminNames.add(admin.full_name);
        });
      }
    });
    // Convert the Set to an array to list all unique admin names
    const adminFullNames = Array.from(uniqueAdminNames);

    return {
      id: index,
      user_id: member.user_id || '',  // Handle potential null/undefined user_id
      full_name: member.full_name || '',  // Handle potential null/undefined full_name
      email: member.email || '',  // Handle potential null/undefined email
      team_name: teams,  // Handle multiple teams and join them with commas
      role: member.role || 'No role',  // Handle potential null/undefined role
      title: member.company_role_name || "No Title",  // Handle multiple titles and join them with commas
      invite_status: member.invite_status || '',  // Handle potential null/undefined invite_status
      team_leader: adminFullNames,
    };
  });
  

  const handleOpenAddMemberModal = () => setOpenAddMemberModal(true);
  const handleopenAddPrivateUserModal = () => setOpenAddPrivateUserModal(true)


    return(
        <Box
            sx={{
            background: "white",
            borderRadius: "30px",
            paddingX: { xs: "30px", md: "50px" },
            paddingY: { xs: "30px", md: "30px" },
            maxHeight: { md: "calc(100vh - 100px)" },
            height: "100%",
            overflow: "hidden",
            width: "100%",
            boxSizing: "border-box",
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", height: "100%" }}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <Typography sx={{ color: 'black', fontSize: 28, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word' }}>
                    Teams & Members
                </Typography>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "15px" }}>
                    <Typography sx={{ color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word', borderRadius: 50, padding: "12px", background: "#F1F1F1" }}>
                        {`${availableSeats} seats are open`}
                    </Typography>
                    <Button onClick={handleOpenAddMemberModal} sx={{ color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word', borderRadius: 50, border: '2px black solid' }}>
                    Add Members
                    </Button>
                    <Button onClick={handleopenAddPrivateUserModal} sx={{ color: 'black', fontSize: 10, fontFamily: 'Lexend', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 2, wordWrap: 'break-word', borderRadius: 50, border: '2px black solid' }}>
                      Add Private Users
                    </Button>
                </Box>
                </Box>
                {/* Styled DataGrid Component */}
                <Box sx={{ flexGrow: 1, width: '100%', display: "flex", flexDirection: "column", gap: "8px" }}>
                <ToolBar user={user} selected={selected} fetchCompanyData={fetchCompanyData}/>
                <UsersTable
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 20]}
                    checkboxSelection={false}
                    loading={loading}
                    onSelectionModelChange={(newSelection) => setSelected(newSelection)}
                    disableColumnFilter
                    disableSelectionOnClick
                    getRowClassName={(params) => 
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    localeText={{
                        noRowsLabel: 'No data available',
                    }}
                    sx={{
                        "& .MuiDataGrid-columnHeaderTitleContainerContent, .MuiDataGrid-cellCheckbox":
                        {
                            span: {
                                display: "flex",
                                color: "#E4E6F2",
                                "&:hover": {
                                color: "#B6B9CE",
                                backgroundColor: "transparent",
                                },
                                "&.Mui-checked": {
                                color: "black",
                                backgroundColor: "transparent",
                                },
                            },
                        },
                    }}
                />
                </Box>
            </Box>
            <AdminAddMemberModal
                user={user}
                userData={userData}
                openAddMemberModal={openAddMemberModal} 
                setOpenAddMemberModal={setOpenAddMemberModal}
                companyInformation={companyInformation}
                teamsInformation={teamsInformation}
                companyMembers={companyMembers}
                myTeamsInformation={myTeamsInformation}
                fetchCompanyData={fetchCompanyData}
            />

            <AddPrivateUserModal 
              user={user}
              userData={userData}
              openAddPrivateUserModal={openAddPrivateUserModal} 
              setOpenAddPrivateUserModal={setOpenAddPrivateUserModal}
              companyInformation={companyInformation}
              teamsInformation={teamsInformation}
              companyMembers={companyMembers}
              fetchCompanyData={fetchCompanyData}
            />
        </Box>
    )
}

export default Admin;